import { download } from '@/utils/doRequest';
import FetchFn from '@/types/FetchFn';
import getDateStringForFileName from '@/utils/getDateStringForFileName';

export interface DownloadPromoMaterialPayload {
  fetch: FetchFn;
  articleId: string;
  articleName: string;
}
const downloadPromoMaterial = async (
  { commit, dispatch },
  payload: DownloadPromoMaterialPayload,
) => {
  return new Promise<any>((resolve) => {
    const { fetch, articleName, articleId } = payload;

    let downloadLink;
    downloadLink = `/article/${articleId}/file`;

    // Generate file name
    const fileNameSlug = 'promo-' + articleName.toLowerCase().replace(/ /g, '-');
    const fileName = `${fileNameSlug}-${getDateStringForFileName(new Date())}.zip`;

    download({ commit, dispatch }, fetch, downloadLink, fileName, true)
      .then(resolve);
  });
};

export default downloadPromoMaterial;
