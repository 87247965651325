import { download } from '@/utils/doRequest';
import FetchFn from '@/types/FetchFn';
import getDateStringForFileName from '@/utils/getDateStringForFileName';

export interface DownloadCampaignCodesPayload {
  fetch: FetchFn;
  codeLink: string;
  campaignName: string;
}

const downloadCampaignCodes = async (
  { commit, dispatch },
  payload: DownloadCampaignCodesPayload,
) => {
  return new Promise<void>((resolve) => {
    const { fetch, codeLink, campaignName } = payload;

    // Generate file name
    const fileNameSlug = campaignName.toLowerCase().replace(/ /g, '-');
    const fileName = `${fileNameSlug}-${getDateStringForFileName(new Date())}.csv`;

    download({ commit, dispatch }, fetch, codeLink, fileName, true)
      .then(resolve);
  });
};

export default downloadCampaignCodes;
