import Domain from '@/types/Domain';
import ArticleImage from '../article/ArticleImage';
import Money from '@/domains/common/Money';
import sortByProp from '@/utils/sortByProp';

interface Props {
  id: string;
  image: ArticleImage;
  name: string;
  manufacturerName: string;
  categoryShortcut: string;
  articlePrice: Money;
  unitFee: Money;
}

class MinimalPortfolioArticle extends Domain implements Props {
  public static createAll(campaigns: any[]): MinimalPortfolioArticle[] {
    return campaigns.map(MinimalPortfolioArticle.create);
  }

  public static create(data: any): MinimalPortfolioArticle {
    return new MinimalPortfolioArticle(
      data.id,
      ArticleImage.create((data.images && data.images.length > 0) ? sortByProp(data.images, 'order')[0] : {}),
      data.name,
      data.manufacturerName,
      data.categoryShortcut || data.categorySpecificPortfolioShortcut,
      Money.create(data.articlePrice),
      Money.create(data.unitFee),
    );
  }

  private constructor(
    public id: string,
    public image: ArticleImage,
    public name: string,
    public manufacturerName: string,
    public categoryShortcut: string,
    public articlePrice: Money,
    public unitFee: Money,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): MinimalPortfolioArticle {
    return MinimalPortfolioArticle.create(this);
  }
}

export default MinimalPortfolioArticle;
