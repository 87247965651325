import Domain from '@/types/Domain';

export enum FieldType {
  TextField,
  NumberField,
  SelectField,
}

export interface TextField {
  path: string;
  idPrefix: string;
  type: FieldType.TextField;
  label: string;
  placeholder: string;
  className?: string;
}

export interface NumberField {
  path: string;
  idPrefix: string;
  type: FieldType.NumberField;
  label: string;
  placeholder: string;
  className?: string;
  maxNumber?: string;
  minNumber?: string;
}

export interface SelectField {
  path: string;
  idPrefix: string;
  type: FieldType.SelectField;
  label: string;
  options: [];
}

export default interface FormPopupPayload {
  domain: Domain;
  fields: (TextField | SelectField | NumberField)[];
  handleConfirm: () => Promise<any>;
}
