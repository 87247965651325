import Domain from '@/types/Domain';


type ErrorEntry = [string, { errors?: string[]; children?: {} }];

const setErrors = <T extends Domain>(
  fieldErrors: { [key: string]: {} },
  domain: T,
) => {
  Object.entries(fieldErrors).forEach(([name, field]: ErrorEntry) => {
    if (field.errors && field.errors.length > 0) {
      domain.addError(name, field.errors);
    } else if (field.children && Object.keys(field.children).length > 0) {
      setErrors(field.children, domain[name]);
    }
  });
};

export default <T extends Domain>(response: any, domain: T) => {
  const errors = response.errors.children;

  setErrors<T>(errors, domain);
};
