import UiAction from '../UiAction';

const closeToastNotification: UiAction = (
  { commit },
  payload: string,
): void => {
  commit('removeToastNotification', payload);
};

export default closeToastNotification;
