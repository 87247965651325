import MinimalMessage from '@/domains/message/MinimalMessage';
import fetchList, {FetchListAction, FetchListPayload} from '@/utils/api-platform/fetchList';

const fetchUserMessageList: FetchListAction<MinimalMessage> = async (
    { commit, dispatch },
    fetchListPayload: FetchListPayload,
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/notifications`,
    domainParser: MinimalMessage.create,
    listMutation: 'setMinimalMessageList',
  });
};

export default fetchUserMessageList;
