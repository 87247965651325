import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { CampaignWizardState } from '../state';
import createCampaign from './createCampaign';

const actions: ActionTree<CampaignWizardState, RootState> = {
  createCampaign,
};

export default actions;
