import OrderAction from '../OrderAction';
import OrderStatus from '@/types/OrderStatus';

const filterStatusList: OrderAction = async (
  { commit },
  status: OrderStatus,
): Promise<any> => {
  commit('setListStatusFilter', status);
  return Promise.resolve();
};

export default filterStatusList;
