import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { PortfolioState } from '../state';
import fetchPortfolioArticleList from '@/store/modules/portfolio/actions/fetchPortfolioArticleList';
import fetchMinimalPortfolioArticleList from '@/store/modules/portfolio/actions/fetchMinimalPortfolioArticleList';
import fetchPortfolioTagList from '@/store/modules/portfolio/actions/fetchPortfolioTagList';

const actions: ActionTree<PortfolioState, RootState> = {
  fetchPortfolioArticleList,
  fetchMinimalPortfolioArticleList,
  fetchPortfolioTagList,
};

export default actions;
