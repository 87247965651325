import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import MinimalCampaign from '@/domains/campaign/MinimalCampaign';

const fetchOrderList: FetchListAction<MinimalCampaign> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let filters = fetchListPayload.filters || [];

  let url;
  if (rootGetters.isMandant) {
    url = `/my/campaign`;
  } else {
    url = `/campaign`;

    filters = filters.filter((f) => f.field !== 'mandantId');
    const simulatedMandantId = rootGetters.getSimulatedMandantId;
    if (simulatedMandantId) {
      filters.push({
        field: 'mandantId',
        query: simulatedMandantId,
      });
    }
  }

  return await fetchList({ commit, dispatch }, {
    ...fetchListPayload,
    filters,
  }, {
    url,
    domainParser: MinimalCampaign.create,
    listMutation: 'setMinimalCampaigns',
    listInfoMutation: 'setCampaignListInfo',
  });
};

export default fetchOrderList;
