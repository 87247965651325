import MinimalArticleVariant from '@/domains/article/MinimalArticleVariant';
import fetchList, { FetchListPayload, FetchListAction } from '@/utils/fetchList';

const fetchCategoryVariantList: FetchListAction<MinimalArticleVariant> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  const mandantId = rootGetters.getSimulatedMandantId;

  let url;
  if (mandantId) {
    url = `/mandant/${mandantId}/variant`;
  } else {
    url = `/variant`;
  }
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: MinimalArticleVariant.create,
    listMutation: 'setListCategoryVariants',
    listInfoMutation: 'setCategoryVariantListInfo',
  });
};

export default fetchCategoryVariantList;
