import Manufacturer from '@/domains/manufacturer/Manufacturer';
import NormalizedData from '@/types/NormalizedData';
import { ListInfo } from '@/types/ListInfo';


export interface ManufacturerState {
  manufacturers: NormalizedData<Manufacturer>;
  listInfo: ListInfo;
}

const state: ManufacturerState = {
  manufacturers: {},
  listInfo: ListInfo.create(),
};

export default state;
