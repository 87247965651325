import Domain from '@/types/Domain';

interface Props {
  id: string;
}

class VerificationDocumentInfo extends Domain implements Props {

  public static createAll(orders: any[]): VerificationDocumentInfo[] {
    return orders.map(VerificationDocumentInfo.create);
  }

  public static create(data: any): VerificationDocumentInfo {
    return new VerificationDocumentInfo(
      data.id,
    );
  }

  public constructor(
    public id: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): VerificationDocumentInfo {
    return VerificationDocumentInfo.create(this);
  }
}

export default VerificationDocumentInfo;
