import Role from '@/types/Role';
import { getData } from '@/utils/persist';
import MandantModule from '@/domains/mandant/MandantModule';


export interface AuthState {
  isUserLoggedIn: boolean;
  roleIds: string[];
  assignableRoleIds: string[];
  notifiableRoleIds: [];
  mandantId: string;
  mandantName: string;
  mandantShopHost: string;
  simulatedMandantId: string;
  modules: MandantModule;
  logoutTimestamp: number | undefined;
  refreshToken: string;
}

const state: AuthState = {
  isUserLoggedIn: !!getData('user-token'),
  roleIds: getData('role-ids') || [Role.GUEST.id],
  assignableRoleIds: getData('assignable-role-ids') || [],
  notifiableRoleIds: getData('notifiable-role-ids') || [],
  mandantId: getData('mandant-id'),
  mandantName: getData('mandant-name') || '',
  mandantShopHost: getData('mandant-shop-host') || '',
  simulatedMandantId: getData('simulated-mandant-id'), // || '00000000-0000-4000-a000-000000000001',
  modules: getData('mandant-modules') || {
    MODULE_ADDITIONAL_CHARGE: false,
    MODULE_SALES: false,
    MODULE_API_SETTINGS: false,
    MODULE_ACCESS: false,
  },
  logoutTimestamp: getData('logout-timestamp') || undefined,
  refreshToken: getData('refresh-token'),
};

export default state;
