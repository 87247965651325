import { MutationTree } from 'vuex';
import { MessageState } from './state';
import MinimalMessage from '@/domains/message/MinimalMessage';
import NormalizedData from '@/types/NormalizedData';
import Message from '@/domains/message/Message';

export const setMinimalMessageList = (
  state: MessageState,
  minimalMessages: NormalizedData<MinimalMessage>,
): void => {
  state.minimalMessages = {
    ...minimalMessages,
  };
};

export const updateMessageListUser = (
  state: MessageState,
  updatetMessage: NormalizedData<MinimalMessage>,
): void => {
  state.minimalMessages = {
    ...state.minimalMessages,
    ...updatetMessage,
  };
};

export const setMessageListAdmin = (
    state: MessageState,
    minimalMessages: NormalizedData<MinimalMessage>,
): void => {
  state.minimalMessagesAdmin = {
    ...minimalMessages,
  };
};

export const setHandleMessage = (
    state: MessageState,
    handleMessage: Message,
): void => {
  state.handleMessage = handleMessage;
};

export const addMessages = (
    state: MessageState,
    minimalMessages: NormalizedData<MinimalMessage>,
): void => {
  state.minimalMessagesAdmin = {
    ...state.minimalMessages,
    ...minimalMessages,
  };
};

export const mutations: MutationTree<MessageState> = {
  setMinimalMessageList,
  updateMessageListUser,
  setMessageListAdmin,
  setHandleMessage,
  addMessages,
};

export default mutations;
