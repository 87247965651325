import Component from 'vue-class-component';
import TsxComponent from '@/typeDefinitions/vue-tsx';
import NewFormGroup from '@/components/formGroup/NewFormGroup';
import mutateDomainProperty from '@/components/form/utils/mutateDomainProperty';
import getDomainProperty from '@/components/form/utils/getDomainProperty';
import SelectOption from '@/types/SelectOption';
import Domain from '@/types/Domain';
import getIdFromPath from '../form/utils/getIdFromPath';
import Select from '../select/Select';

interface Props {
  domain: Domain;
  options: SelectOption[];
  path: string;
  label: string;
  idPrefix?: string;
  hint?: string;
  description?: string;
  customConstraints?: () => {};
}

@Component({
  props: {
    domain: Object,
    options: Array,
    path: String,
    label: String,
    idPrefix: String,
    hint: String,
    description: String,
    customConstraints: Function,
    dataQa: String,
  },
})
class NewSelectField extends TsxComponent<Props> {
  private onChange(e: Event): void {
    const { domain, path } = this.$props;

    const inputValue = (e.target as HTMLSelectElement).value;

    mutateDomainProperty(domain, path, inputValue);

    this.$emit('change', inputValue);
  }

  private render() {
    const {
      domain,
      options,
      path,
      label,
      idPrefix,
      hint,
      description,
      customConstraints,
      dataQa,
    } = this.$props;

    const fullPath = `${idPrefix ? idPrefix + '-' : ''}${path}`;
    let value = getDomainProperty(domain, path);
    if (typeof value === 'number') {
      value = `${value}`;
    }

    return (
      <NewFormGroup
        domain={domain}
        path={path}
        label={label}
        hint={hint}
        customConstraints={customConstraints}
        description={description}
      >
        <Select
          id={getIdFromPath(fullPath)}
          placeholder={label}
          onChange={this.onChange}
          onFocus={() => domain.removeError(path)}
          value={value}
          options={options}
          dataQa={dataQa}
        />
      </NewFormGroup>
    );
  }
}

export default NewSelectField;
