import Domain from '@/types/Domain';
import Gender from '@/types/Gender';

interface Props {
  active: boolean;
  id: string;
  department: string;
  gender: Gender;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: string[];
}

class MinimalUser extends Domain implements Props {
  public static createAll(users: any[]): MinimalUser[] {
    return users.map(MinimalUser.create);
  }

  public static create(data: any): MinimalUser {
    return new MinimalUser(
      isNaN(parseInt(data.active)) ? data.active : !!parseInt(data.active),
      data.id,
      data.department,
      data.gender,
      data.firstName,
      data.lastName,
      data.email,
      data.phone,
      data.roles || [],
    );
  }

  private constructor(
    public active: boolean,
    public id: string,
    public department: string,
    public gender: Gender,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public roles: string[],
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add contraints
    };
  }

  public clone(): MinimalUser {
    return MinimalUser.create(this);
  }
}

export default MinimalUser;
