import Message from '@/domains/message/Message';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';

const editMessage: PushSingleAction<Message> = async (
    { commit, dispatch },
    payload: PushSinglePayload<Message>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'PUT', pushPayload, {
    url: `/notifications/${id}`,
    mutation: undefined,
  }).then((response: any) => {
    commit (
      'setHandleMessage',
      response,
    );
  });
};

export default editMessage;
