import Domain from '@/types/Domain';
import CodeStatus from '@/types/CodeStatus';
import CodeType from '@/types/CodeRedemptionStatus';

interface Props {
  id: string;
  codeStatus: CodeStatus;
  type: CodeType;
  token: string;
  redemptionDate?: string;
  cancellationDate?: string;
  orderId?: string;
  redemptionQuantity?: number;
  quantity?: number;
}

class Code extends Domain implements Props {
  public static createAll(codes: any[]): Code[] {
    return codes.map(Code.create);
  }

  public static create(data: any): Code {
    return new Code(
      data.token,
      typeof data.codeStatus === 'string' ? CodeStatus.getById(data.codeStatus) : data.codeStatus,
      typeof data.type === 'string' ? CodeType.getById(data.type) : data.type,
      data.token,
      data.redemptionDate,
      data.cancellationDate,
      data.orderId,
      data.redemptionQuantity,
      data.quantity,
    );
  }

  private constructor(
    public id: string,
    public codeStatus: CodeStatus,
    public type: CodeType,
    public token: string,
    public redemptionDate: string,
    public cancellationDate: string,
    public orderId: string,
    public redemptionQuantity: number,
    public quantity: number,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add contraints
    };
  }

  public clone(): Code {
    return Code.create({
      ...JSON.parse(JSON.stringify(this)),
      codeStatus: CodeStatus.ALL.filter((status) => status === this.codeStatus)[0],
    });
  }
}

export default Code;
