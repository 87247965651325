export default (array: any[] = [], length: number, filler: any): any[] => {
  const ret = [
    ...array,
  ];
  if (ret.length < length) {
    const amountOfFillers = length - ret.length;
    for (let i = 0; i < amountOfFillers; i++) {
      ret.push(filler);
    }
  }

  return ret;
};
