import scrollToFirstErroneousElement from '@/utils/scrollToFirstErroneousElement';
import Domain from '@/types/Domain';
import setAPIValidationErrorsWithDomain from '@/utils/api-platform/setAPIValidationErrorsWithDomain';

export default async <T extends Domain = Domain>(error: any, errorObject: any, domain: T) => {
  const response = error.response;
  if (response.status === 400) {
    setAPIValidationErrorsWithDomain<T>(errorObject, domain);
    scrollToFirstErroneousElement();
  }
};
