import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import UserGroup from '@/domains/userGroup/UserGroup';

const editUserGroup: PushSingleAction<UserGroup> = async (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<UserGroup>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/user-group/${id}`;
  } else {
    url = `/mandant/${rootGetters.getSimulatedMandantId}/user-group/${id}`;
  }

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url,
    mutation: 'addUserGroups',
  });
};

export default editUserGroup;
