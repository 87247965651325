import createFakeResponse from '../../../test-helper/createFakeResponse';

export const editUser = createFakeResponse({
  id: '294e3df1-0088-426a-bb97-40e76f277cd9',
  email: 'test+2@skon.de',
  firstName: 'First-2',
  lastName: 'Last-2',
  department: 'IT',
  phone: '0178 111 1111',
  role: 'skon_admin',
  active: true,
  mandant: 'Mandant 8',
});

export const editUserUpdated = createFakeResponse({
  id: '294e3df1-0088-426a-bb97-40e76f277cd9',
  email: 'test+2@skon.de',
  firstName: 'First-updated-2',
  lastName: 'Last-updated-2',
  department: 'IT',
  phone: '0178 111 1111',
  role: 'skon_admin',
  active: true,
  mandant: 'Mandant 8',
});
