import { GetterTree } from 'vuex';
import { MessageState } from './state';
import MinimalMessage from '@/domains/message/MinimalMessage';
import Message from '@/domains/message/MinimalMessage';
import NormalizedData from '@/types/NormalizedData';


export const getMinimalMessages = (state: MessageState): NormalizedData<MinimalMessage> => {
  return state.minimalMessages;
};

export const getMinimalMessagesAdmin = (state: MessageState): NormalizedData<MinimalMessage> => {
  return state.minimalMessagesAdmin;
};

export const getHandleMessage = (state: MessageState): Message => {
  return state.handleMessage;
};

const getters: GetterTree<MessageState, any> = {
  getMinimalMessages,
  getMinimalMessagesAdmin,
  getHandleMessage,
};

export default getters;
