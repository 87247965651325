import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import Category from '@/domains/category/Category';

const editCategory: PushSingleAction<Category> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Category>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'PATCH', pushPayload, {
    url: `/category-portfolio/${id}`,
    mutation: 'addCategories',
  });
};

export default editCategory;
