export default (date: Date): number => {
  // hardcopy date
  date = new Date(date.toISOString());

  // set to nearest Thursday: current date + 4 - current day number
  // set sunday to day #7
  date.setUTCDate(date.getUTCDate() + 4 - (date.getUTCDay() || 7));

  const firstDayOfYear = new Date(Date.UTC(date.getUTCFullYear(), 0, 1));
  const weekNumber = Math.ceil((((date.getTime() - firstDayOfYear.getTime()) / 86400000) + 1) / 7);

  return weekNumber;
};
