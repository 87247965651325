import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import MinimalUser from '@/domains/mandant/MinimalUser';

export interface FetchUserGroupUsersListPayload extends FetchListPayload {
  userGroupId: string;
}

const fetchUserGroupUsersList: FetchListAction<MinimalUser> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchUserGroupUsersListPayload,
) => {
  const {
    userGroupId,
  } = fetchListPayload;
  const filters = fetchListPayload.filters || [];

  let url;
  if (rootGetters.isMandant) {
    url = `/my/user-group/${userGroupId}/users`;
  } else {
    url = `/mandant/${rootGetters.getSimulatedMandantId}/user-group/${userGroupId}/users`;
  }

  return await fetchList({ commit, dispatch }, {
    ...fetchListPayload,
    filters,
  }, {
    url,
    domainParser: MinimalUser.create,
    listMutation: 'setUserGroupUsers',
    listInfoMutation: 'setUserGroupUserListInfo',
  });
};

export default fetchUserGroupUsersList;
