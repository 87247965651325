import Domain from '@/types/Domain';

type Currency = 'EUR';

class Money extends Domain {
  public static createAll(data: any[]): Money[] {
    return data.map(Money.create);
  }

  public static create(data: any): Money {
    return new Money(
      parseInt(data.amount, 10) || 0,
      data.currency || 'EUR',
    );
  }

  public amount: number;
  public currency: Currency;

  private constructor(
    amount: number,
    currency: Currency,
  ) {
    super();
    this.amount = amount;
    this.currency = currency;
  }

  public getNormalizedAmount(): number {
    return this.amount / 100;
  }

  public clone(): Money {
    return Money.create(JSON.parse(JSON.stringify(this)));
  }

  public constraints(): {} {
    return {
      amount: {
        presence: {
          message: '^Menge ist ein Pflichtfeld.',
        },
      },
      currency: {
        presence: {
          message: '^Währung ist ein Pflichtfeld.',
        },
      },
    };
  }
}

export default Money;
