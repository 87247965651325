import Maybe from '@/types/Maybe';
import { ListFilter } from '@/types/ListFilter';
import { ListSort } from '@/types/ListSort';
import getUrlParamsForFilters from '@/utils/api-platform/getUrlParamsForFilters';
import getUrlParamsForSorts from '@/utils/getUrlParamsForSorts';

const getRequestListUri = (
  endpoint: string,
  page: number = 1,
  limit: number = 10,
  filters: ListFilter[] = [],
  sorts: ListSort[] = [],
  pagination: boolean,
): string =>
  Maybe.fromValue(endpoint)
    .map((url) => ((pagination !== false) ? `${url}?page=${page}` : `${url}`))
      // params page + limit must not be sent if pagination is to be deactivated
    .map((url) => ((pagination !== false) ? `${url}&limit=${limit}` : `${url}`))
    .map((url) => ((typeof(pagination) !== 'undefined') ?
      (url + ((pagination === false) ? '?' : '&') + 'pagination=' + pagination) :
      `${url}`))
    .map((url) => `${url}${getUrlParamsForFilters(filters, true)}`)
    .map((url) => `${url}${getUrlParamsForSorts(sorts)}`)
    .getOrElse(endpoint);

export default getRequestListUri;
