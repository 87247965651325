import Domain from '@/types/Domain';
import ArticleInfo from './ArticleInfo';
import StatusInfo from './StatusInfo';
import RedemptionRateInfo from './RedemptionRateInfo';
import TimeInterval from '@/types/TimeInterval';

interface Props {
  id: string; // campaignId
  type: string; // campaignType
  articleInfo: ArticleInfo[];
  statusInfo: StatusInfo[];
  redemptionRateInfo: RedemptionRateInfo[];
}

class CampaignReport extends Domain implements Props {
  public static createAll(campaigns: any[]): CampaignReport[] {
    return campaigns.map(CampaignReport.create);
  }

  public static create(data: any): CampaignReport {
    return new CampaignReport(
      data.id || data.campaignId,
      data.campaignType,
      ArticleInfo.createAll(data.articleInfo || []),
      StatusInfo.createAll(data.statusInfo || []),
      RedemptionRateInfo.createAll(data.redemptionRateInfo || []),
    );
  }

  private constructor(
    public id: string,
    public type: string,
    public articleInfo: ArticleInfo[],
    public statusInfo: StatusInfo[],
    public redemptionRateInfo: RedemptionRateInfo[],
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): CampaignReport {
    return CampaignReport.create(JSON.parse(JSON.stringify(this)));
  }

  public getInitialTimeInterval(): TimeInterval {
    const statusInfoList = this.statusInfo;
    if (statusInfoList.length === 0) {
      return 'days';
    }

    const earliestDate = new Date(statusInfoList[0].orderDate);
    const latestDate = new Date(statusInfoList[statusInfoList.length - 1].orderDate);
    const diffTime = Math.abs(latestDate.getTime() - earliestDate.getTime());
    const msPerDay = 1000 * 60 * 60 * 24;
    const diffDays = Math.ceil(diffTime / msPerDay);

    if (diffDays <= 10) {
      return 'days';
    } else if (diffDays <= 70) {
      return 'weeks';
    } else {
      return 'months';
    }
  }

  // trims a report's articleInfo to a specified number + a rest-item
  public getArticleInfo(maxItemAmount: number = 3): ArticleInfo[] {
    const articleInfoList: ArticleInfo[] = [];

    let restItemCount = 0;
    this.articleInfo.forEach((articleInfo, articleInfoIndex) => {
      if (articleInfoIndex < maxItemAmount) {
        // add items up to maxItemAmount
        articleInfoList.push(ArticleInfo.create(articleInfo));
      } else {
        // add all remaining items reduced into 1 nameless rest-item
        restItemCount += articleInfo.count;
      }
    });
    articleInfoList.push(ArticleInfo.create({ count: restItemCount, name: '' }));

    return articleInfoList;
  }
}

export default CampaignReport;
