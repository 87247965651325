import { ListPagination } from '@/types/ListPagination';
import { ListSort } from '@/types/ListSort';
import { ListFilter } from '@/types/ListFilter';

interface Props extends ListPagination {
  sorts: ListSort[];
  filters: ListFilter[];
}

export class ListInfo implements Props {
  public static create(data: any = {}): ListInfo {
    return new ListInfo(
      data.page || 1,
      data.pages || 0,
      data.limit || 10,
      data.total || 0,
      data.sorts || [],
      data.filters || [],
    );
  }

  private constructor(
    public page: number,
    public pages: number,
    public limit: number,
    public total: number,
    public sorts: ListSort[],
    public filters: ListFilter[],
  ) {}

  public getSortOrder(fieldName: string) {
    const foundSort = this.sorts.find((sort: ListSort) => sort.field === fieldName);
    return foundSort ? foundSort.order : undefined;
  }

  public getFilterQuery(fieldName: string) {
    const foundFilter = this.filters.find((filter: ListFilter) => filter.field === fieldName);
    return foundFilter ? foundFilter.query : undefined;
  }
}
