export default (items: any[], property: string, order: 'ASC' | 'DESC' = 'ASC'): any[] => {
  const ret = [
    ...items,
  ];

  if (!property) {
    return;
  }

  const sortFunc = (a: any, b: any) => {
    if (a[property] < b[property]) {
      return order === 'ASC' ? -1 : 1;
    }
    if (a[property] > b[property]) {
      return order === 'ASC' ? 1 : -1;
    }
    return 0;
  };

  ret.sort(sortFunc);

  return ret;
};
