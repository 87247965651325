import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import state, { UserState } from './state';
import actions from './actions';

const userModule: Module<UserState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
export default userModule;
