import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import Tag from '@/domains/tag/Tag';


const editTag: PushSingleAction<Tag> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Tag>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/tag/${id}`,
    mutation: 'addTags',
  });
};

export default editTag;
