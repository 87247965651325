import { ActionTree } from 'vuex';
import { TagState } from '../state';
import { RootState } from '@/store';
import fetchTagList from './fetchTagList';
import fetchTag from './fetchTag';
import createTag from './createTag';
import editTag from './editTag';
import deleteTag from './deleteTag';


const actions: ActionTree<TagState, RootState> = {
  fetchTagList,
  fetchTag,
  createTag,
  editTag,
  deleteTag,
};

export default actions;
