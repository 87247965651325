import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import MinimalPortfolioArticle from '@/domains/portfolio/MinimalPortfolioArticle';

const fetchMinimalPortfolioArticleList: FetchListAction<MinimalPortfolioArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let url;
  if (rootGetters.isMandant) {
    url = `/my/article`;
  } else {
    const simulatedMandantId = rootGetters.getSimulatedMandantId;
    if (simulatedMandantId) {
      url = `/mandant/${simulatedMandantId}/article`;
    } else {
      return;
    }
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: MinimalPortfolioArticle.create,
    listMutation: 'setMinimalPortfolioArticles',
    listInfoMutation: 'setPortfolioArticleListInfo',
  });
};

export default fetchMinimalPortfolioArticleList;
