import Enum from './Enum';

interface Props {
  label: string;
}

export default class CodeStatus extends Enum implements Props {
  public static readonly REDEEMED = new CodeStatus('REDEEMED', 'Eingelöst');
  public static readonly NOT_REDEEMED = new CodeStatus('NOT_REDEEMED', 'Nicht eingelöst');
  public static readonly CANCELED = new CodeStatus('CANCELED', 'Abgelehnt');
  public static readonly DISPATCHED = new CodeStatus('DISPATCHED', 'Versendet');

  public static readonly ALL = [
    CodeStatus.REDEEMED,
    CodeStatus.NOT_REDEEMED,
    CodeStatus.CANCELED,
    CodeStatus.DISPATCHED,
  ];

  public static getById(id: string): CodeStatus {
    return CodeStatus.ALL.filter((status) => status.id === id)[0];
  }

  private constructor(
    public id: string,
    public label: string,
  ) {
    super();
  }
}
