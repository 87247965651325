import Domain, { FieldErrors } from '@/types/Domain';
import getNestedProp from '@/utils/getNestedProp';

const getFieldError = (domain: Domain, path: string): string => {
  const splitPath = path.split('.');
  const errors: FieldErrors = domain.getErrors();

  if (splitPath.length === 0 || !errors) {
    return null;
  }

  const fieldError = getNestedProp(errors, splitPath.join('.children.'));
  if (fieldError && fieldError.errors && fieldError.errors[0]) {
    return fieldError.errors[0];
  }

  const currentPath = splitPath.shift();
  if (domain[currentPath] instanceof Domain) {
    return getFieldError(domain[currentPath], splitPath.join('.'));
  } else {
    return null;
  }
};

export default getFieldError;
