import scrollTo from '@/utils/scrollTo';

function getOffsetTop(elem: any) {
  let offsetTop = 0;

  do {
    elem = elem.offsetParent;

    if (!isNaN(elem.offsetTop)) {
      offsetTop += elem.offsetTop;
    }
    // @ts-ignore
  } while (elem.offsetParent);

  return offsetTop;
}

export default () => {
  setTimeout(() => {
    const navbarHeight = 80;
    const margin = 24;
    const pageContent = document.querySelector('main.page-content');
    const firstErroneousElement = document.querySelector('.input-group--warning *');

    if (!pageContent) {
      return;
    }

    if (!firstErroneousElement) {
      return;
    }

    scrollTo(
      pageContent,
      getOffsetTop(firstErroneousElement) - (navbarHeight + margin),
      true,
    );
  }, 0);
};
