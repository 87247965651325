import { NavigationGuard } from 'vue-router';
import store from '../store';

export const beforeRouteLeaveConfirm: NavigationGuard = (to, from, next) => {
  if (!store.getters.isRequestingDataLossConfirm) {
    // No confirm requested -> allow navigation
    next();
  } else {
    const answer = window.confirm(
      'Achtung, nicht gespeicherte Daten gehen verloren.'
    );
    if (answer) {
      store.commit('setIsRequestingDataLossConfirm', false);
      next();
    } else {
      next(false);
    }
  }
};
