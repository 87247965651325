import validate from 'validate.js';
import unset from 'unset-value';
import set from 'set-value';
import { WithId } from '@/utils/normalizeData';
import flattenProps from '@/utils/flattenProps';
import listContainingItemsByType from '@/utils/listContainingItemsByType';
import getNestedProp from '@/utils/getNestedProp';
import formDataCleanEmptyKeys from '@/utils/formDataCleanEmptyKeys';
import displayDate from '@/utils/displayDate';

interface FieldError {
  errors?: string[];
  children?: FieldErrors;
}

export interface FieldErrors {
  [fieldName: string]: FieldError;
}

export interface ValidateOptions {
  [key: string]: any;
}

const datetimeValidation = {
  parse: (value: string, options): Date => {
    return new Date(value);
  },
  format: (value: Date, options): string => {
    const date = value.toISOString().slice(0, 10);
    const time = options.dateOnly ? value.toLocaleTimeString('de') : undefined;
    return `${date} ${time}`;
  },
};

export default abstract class Domain implements WithId {

  public id: string;

  protected errors: FieldErrors = {};

  public constructor() {
    validate.extend(validate.validators.datetime, {
      parse: (value, parseOptions) => {
        return value instanceof Date ? value : new Date(value);
      },
      format: (value, formatOptions) => {
        const format = formatOptions.dateOnly ? 'ISO' : 'FULL_ISO';
        return displayDate(value, format);
      },
    });
  }

  public validateField(
    field: string,
    customConstraints: () => {} = this.constraints,
  ): boolean {
    if (!this.hasOwnProperty(field)) throw new Error('Field not found in Domain');

    const errors = validate(
      {
        [field]: this[field],
      },
      {
        [field]: customConstraints()[field],
      },
      { format: 'api' },
    ) || {};

    if (errors.hasOwnProperty(field)) {
      this.setErrors({
        ...this.errors,
        ...errors,
      });
    } else {
      this.removeError(field);
    }

    return !errors.hasOwnProperty(field);
  }

  public validateFieldAsync(
    field: string,
    customConstraints: () => {} = this.constraints,
  ): boolean {
    if (!this.hasOwnProperty(field)) throw new Error('Field not found in Domain');

    const result = validate.async(
      {
        [field]: this[field],
      },
      {
        [field]: customConstraints()[field],
      },
      {
        wrapErrors: (err) => {
          return {
            [Object.keys(err)[0]] : {
              errors: [err[Object.keys(err)[0]]],
            },
          };
        },
      },
    ).then(
      () => this.removeError(field),
    ).catch(
      (res) => {
      if (res.hasOwnProperty(field)) {
        this.setErrors({
          ...this.errors,
          ...res,
        });
      }
    });
    return !result.hasOwnProperty(field);
  }

  public validate(
    customConstraints: () => {} = this.constraints,
    options?: ValidateOptions,
    shallow: boolean = false,
  ): boolean {
    validate.extend(validate.validators.datetime, datetimeValidation);
    const errors = validate(
      this,
      customConstraints(),
      { format: 'api' },
    ) || {};
    this.setErrors(errors);

    if(!shallow) {
      this.validateChildren()
    }

    return !this.hasErrors()
  }

  public validateChildren(object = this): any {
    let errors = {};
    Object.keys(object)
      .forEach((key) => {
        const prop = object[key];
        if (prop instanceof Domain) {
          prop.validate();
          errors = {
            ...errors,
            ...prop.getErrors(),
          };
        } else if (
          key !== 'errors' && (
            prop instanceof Object ||
            prop instanceof Array
          )
        ) {
          errors = {
            ...errors,
            ...this.validateChildren(prop),
          };
        }
      })
    ;

    return errors;
  }

  public getErrors(): FieldErrors {
    return this.errors;
  }

  public setErrors(errors: FieldErrors): void {
    this.errors = errors;
  }

  public hasErrors(): boolean {
    if (Object.keys(this.errors).length) {
      return true;
    }

    return this.hasChildrenErrors();
  }

  public hasChildrenErrors(object: {} = this): boolean {
    let hasErrors: boolean = false;
    Object.keys(object).forEach((key) => {
      const prop = object[key];
      if (prop instanceof Domain) {
        if (prop.hasErrors()) {
          hasErrors = true;
        }
      } else if (
        key !== 'errors' && (
          prop instanceof Object ||
          prop instanceof Array
        )
      ) {
        if (this.hasChildrenErrors(prop)) {
          hasErrors = true;
        }
      }
    });

    return hasErrors;
  }

  public removeError(path: string): void {
    const splitPath = path.split('.');

    if (this.errors) {
      const newErrors = JSON.parse(JSON.stringify(this.errors));
      unset(newErrors, splitPath.join('.children.'));
      this.setErrors(newErrors);
    }

    const currentPath = splitPath.shift();
    const prop = getNestedProp(this, currentPath);
    if (prop instanceof Domain) {
      // also remove error in sub domain
      prop.removeError(splitPath.join('.'));
    }
  }

  public addError(path: string, errors: string[]): void {
    this.errors = this.errors || {};

    const newErrors = JSON.parse(JSON.stringify(this.errors));
    const errorPath = path.split('.').join('.children.') + '.errors';
    set(newErrors, errorPath, errors);
    this.setErrors(newErrors);
  }

  public abstract constraints(): {};

  /**
   * This needs to be overwritten in every domain with the return type adjusted.
   * Binary data (e.g. File objects) can't be stringified, so they need to get
   * reassigned manually
   */
  public clone(): Domain {
    return JSON.parse(JSON.stringify(this));
  }

  // remove errors
  public cleanse(hard: boolean = false): void {
    if (hard) {
      /**
       * since the 'errors' prop is just needed for frontend-side validation
       * and would break the expected model in API requests, it needs to be
       * deleted before a request
       */
      delete this.errors;
    } else {
      this.errors = {};
    }

    const childDomains = this.listContainingDomains();
    childDomains.forEach((domain) => {
      domain.cleanse(hard);
    });
  }

  public listContainingDomains(): Domain[] {
    return listContainingItemsByType<Domain>(this, Domain);
  }

  public isCloneOf(domain: Domain): boolean {
    const thisString = JSON.stringify(this);
    const domainString = JSON.stringify(domain);

    return thisString === domainString;
  }

  public toFormData(): FormData {
    this.cleanse();

    const data = new FormData();

    const thisObj: any = {
      ...this,
    };

    const flattenedProps = flattenProps(thisObj);

    Object.keys(flattenedProps).forEach((key) => {
      const prop = flattenedProps[key];
      data.append(key, prop);
    });

    return formDataCleanEmptyKeys(data);
  }
}
