import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import OrderStatusParked from '@/domains/order/OrderStatusParked';

const parkOrder: PushSingleAction<OrderStatusParked> = async (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<OrderStatusParked>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/order/${id}/status/parked`;
  } else {
    url = `/order/${id}/status/parked`;
  }

  return await pushSingle({ commit, dispatch }, 'PATCH', pushPayload, {
    url,
    mutation: undefined,
  });
};

export default parkOrder;
