import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';

const createCodeDispatch: PushSingleAction<CodeDispatch> = async (
  { commit, dispatch },
  payload: PushSinglePayload<CodeDispatch>,
): Promise<CodeDispatch> => {
  const { fetch, domain } = payload;
  const pushPayload = {
    fetch,
    domain,
    suppressClientErrorPopups: true,
  };

  return CodeDispatch.create(await pushSingle({commit, dispatch}, 'POST', pushPayload, {
    url: `/code-dispatches`,
    mutation: '',
  }));
};

export default createCodeDispatch;
