import { GetterTree } from 'vuex';
import { UserGroupState } from './state';
import { ListInfo } from '@/types/ListInfo';
import UserGroup from '@/domains/userGroup/UserGroup';
import NormalizedData from '@/types/NormalizedData';
import MinimalUser from '@/domains/mandant/MinimalUser';

type UserGroupGetter = (id: string) => UserGroup;

const getUserGroup = (state: UserGroupState): UserGroupGetter => (
  id: string,
): UserGroup => {
  return state.userGroups[id];
};

export const getUserGroups = (
  state: UserGroupState,
): NormalizedData<UserGroup> => {
  return state.userGroups;
};

export const getUserGroupListInfo = (state: UserGroupState): ListInfo => {
  return state.listInfo;
};

export const getUserGroupUsers = (
  state: UserGroupState,
): NormalizedData<MinimalUser> => {
  return state.users;
};

export const getUserGroupUserListInfo = (
  state: UserGroupState,
): ListInfo => {
  return state.userListInfo;
};

export const getAssignableOwners = (
  state: UserGroupState,
): NormalizedData<MinimalUser> => {
  return state.assignableOwners;
};

export const getAssignableOwnerListInfo = (
  state: UserGroupState,
): ListInfo => {
  return state.assignableOwnerListInfo;
};

const getters: GetterTree<UserGroupState, any> = {
  getUserGroup,
  getUserGroups,
  getUserGroupListInfo,
  getUserGroupUsers,
  getUserGroupUserListInfo,
  getAssignableOwners,
  getAssignableOwnerListInfo,
};

export default getters;
