import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { CampaignState } from '../state';
import fetchCampaignList from './fetchCampaignList';
import editCampaign from './editCampaign';
import fetchCampaignArticleList from './fetchCampaignArticleList';
import downloadCampaignCodes from './downloadCampaignCodes';
import fetchCampaignPortfolioArticleList from './fetchCampaignPortfolioArticleList';
import fetchCampaign from './fetchCampaign';
import fetchCampaignVerificationDocument from './fetchCampaignVerificationDocument';
import fetchSalesCampaigns from './fetchSalesCampaigns';
import fetchSalesCampaignArticleVariants from './fetchSalesCampaignArticleVariants';


const actions: ActionTree<CampaignState, RootState> = {
  fetchCampaign,
  fetchCampaignArticleList,
  fetchCampaignList,
  fetchSalesCampaigns,
  fetchSalesCampaignArticleVariants,
  editCampaign,
  downloadCampaignCodes,
  fetchCampaignPortfolioArticleList,
  fetchCampaignVerificationDocument,
};

export default actions;
