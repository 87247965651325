import Domain from '@/types/Domain';
import MandantDesignData from './MandantDesignData';
import Address from '../common/Address';
import Money from '../common/Money';
import MandantContactDataForCustomer from './MandantContactDataForCustomer';
import MandantContactDataForSkon from './MandantContactDataForSkon';
import MandantModule from './MandantModule';
import MandantPrivacyContract from './MandantPrivacyContract';


interface Props {
  id: string;
  active?: boolean;
  code: string;
  name: string;
  corporateForm: string;
  address?: Address;
  manager: string;
  phone: string;
  vatIdNumber: string;
  website: string;
  shopHost: string;
  creditLimit?: Money;
  operationFee?: Money;
  ownArticleFee?: Money;
  designData?: MandantDesignData;
  erpCustomerNumber: string;
  contactData?: MandantContactDataForCustomer;
  skonContactData: MandantContactDataForSkon;
  modules?: MandantModule;
  oldContract?: boolean;
  logo?: [File, string];
  shopImage?: [File, string];
  privacyContractType: MandantPrivacyContract;
  termsUrl?: string;
}

class Mandant extends Domain implements Props {
  public static createAll(mandants: any[]): Mandant[] {
    return mandants.map(Mandant.create);
  }

  public static create(data: any): Mandant {
    return new Mandant(
      data.id,
      typeof data.active === 'boolean' ? (data.active = true as boolean) : !!parseInt(data.active),
      data.code,
      data.name,
      data.corporateForm,
      Address.create(data.address || {}),
      data.manager,
      data.phone,
      data.vatIdNumber,
      data.website,
      data.shopHost,
      Money.create(data.creditLimit || {}),
      Money.create(data.operationFee || {}),
      Money.create(data.ownArticleFee || {}),
      MandantDesignData.create(data.designData || {}),
      data.erpCustomerNumber,
      MandantContactDataForCustomer.create(data.contactData || {}),
      MandantContactDataForSkon.create(data.skonContactData || {}),
      MandantModule.create(data.modules || {}),
      data.oldContract || false,
      data.privacyContractType || null,
      data.logo,
      data.shopImage,
      data.termsUrl,
    );
  }

  private constructor(
    public id: string,
    public active: boolean,
    public code: string,
    public name: string,
    public corporateForm: string,
    public address: Address,
    public manager: string,
    public phone: string,
    public vatIdNumber: string,
    public website: string,
    public shopHost: string,
    public creditLimit: Money,
    public operationFee: Money,
    public ownArticleFee: Money,
    public designData: MandantDesignData,
    public erpCustomerNumber: string,
    public contactData: MandantContactDataForCustomer,
    public skonContactData: MandantContactDataForSkon,
    public modules: MandantModule,
    public oldContract: boolean,
    public privacyContractType: MandantPrivacyContract,
    public logo?: [File, string],
    public shopImage?: [File, string],
    public termsUrl?: string,
  ) {
    super();
  }

  public clone(): Mandant {
    return Mandant.create(this);
  }

  public constraints(): {} {
    return {
      phone: {
        presence: false,
        format: {
          pattern: /^0[0-9]{5,26}$/,
          message: '^Muss mit 0 beginnen und darf 6 bis 27 Zeichen enthalten.',
        },
      },
      code: {
        presence: {
          message: '^3 Letter Code ist ein Pflichtfeld',
        },
        format: {
          pattern: /^[A-Z]{3}$/,
          message: '^3 Letter Code muss aus genau 3 Großbuchstaben bestehen',
        },
      },
      name: {
        presence: {
          message: '^Firma ist ein Pflichtfeld.',
        },
      },
      vatIdNumber: {
        presence: {
          message: '^Steuer ID ist ein Pflichtfeld.',
        },
      },
      address: {
        presence: true,
      },
      ['address.street.name']: {
        presence: {
          message: '^Straße ist ein Pflichtfeld.',
        },
      },
      ['address.street.number']: {
        presence: {
          message: '^Nummer ist ein Pflichtfeld.',
        },
      },
      ['address.city']: {
        presence: {
          message: '^Ort ist ein Pflichtfeld.',
        },
      },
      ['address.zipCode']: {
        presence: {
          message: '^Postleitzahl ist ein Pflichtfeld.',
        },
      },
      erpCustomerNumber: {
        presence: {
          message: '^ERP Kundennummer ist ein Pflichtfeld.',
        },
      },
      website: {
        presence: {
          message: '^Die URL Webseite ist ein Pflichfeld.',
        },
      },
      shopHost: {
        presence: {
          message: '^Shop-Hostname ist ein Pflichtfeld.',
        },
      },
      corporateForm: {
        presence: {
          message: '^Firmierung ist ein Pflichtfeld.',
        },
        length: {
          minimum: 2,
          maximum: 35,
          message: '^Der Firmierung muss mindestens 2 und darf maximal 35 Zeichen betragen.',
        },
      },
      privacyContractType: {
        presence: {
          message: '^Datenschutzvertrag ist ein Pflichtfeld.',
        },
      },
      termsUrl: {
        length: {
          maximum: 10000,
          message: '^Der Link zu Ihren AGB darf maximal 10.000 Zeichen betragen.',
        }
      }
    };
  }
}

export default Mandant;
