import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import classNames from 'classnames';
import styles from '../NewPopup.module.scss';
import { PopupProps } from '@/types/PopupPayload';


@Component({
  props: {
    title: String,
    description: String,
    icon: String,
    type: {
      type: String,
      default: 'hint',
    },
    className: String,
  },
})
class FullSizePopup extends TsxComponent<PopupProps> {
  public render() {
    const {
      title,
      color,
      description,
      icon,
      className,
    } = this.$props;

    return (
      <div class={classNames(
        styles['popup__wrapper'],
        className,
        color && color === 'info' && styles['popup__wrapper-info'],
        color && color === 'error' && styles['popup__wrapper-error'],
        color && color === 'warning' && styles['popup__wrapper-warning'],
      )}>
        {icon && (
          <div class={classNames(
            styles['popup__wrapper__circle'],
            color && color === 'info' && styles['popup__wrapper__circle-info'],
            color && color === 'error' && styles['popup__wrapper__circle-error'],
            color && color === 'warning' && styles['popup__wrapper__circle-warning'],
          )}>
            <font-awesome-icon
              class="fa-lg"
              icon={icon}
            />
          </div>
        )}
        <div
          class={classNames(
            styles['popup__wrapper__close-icon-wrapper'],
            'mb-4',
          )}
        >
          <font-awesome-icon
            class={styles['popup__wrapper__close-icon-wrapper__close-icon']}
            onClick={() => this.$emit('close')}
            icon="times"
          />
        </div>
        {title && (
          <h1
            class={classNames(
              styles['popup__wrapper__heading'],
              'headline-size--100',
            )}
          >
            {title}
          </h1>
        )}
        <div class={classNames(
          styles['popup__wrapper__content'],
          'text-center',
          'mt-4',
        )}>
          {description}
          {this.$slots.default}
        </div>
      </div>
    );
  }
}

export default FullSizePopup;
