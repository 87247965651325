import { ModuleTree } from 'vuex';
import { RootState } from '@/store';
import auth from './auth';
import order from './order';
import mandant from './mandant';
import user from './user';
import article from './article';
import manufacturer from './manufacturer';
import message from './message';
import campaign from './campaign';
import campaignWizard from './campaignWizard';
import code from './code';
import highlight from './highlight';
import category from './category';
import report from './report';
import ui from './ui';
import portfolio from './portfolio';
import tag from './tag';
import cockpit from './cockpit';
import userGroup from './userGroup';
import codeDispatch from './codeDispatch';
import mediaObject from './mediaObject';


const modules: ModuleTree<RootState> = {
  auth,
  order,
  mandant,
  user,
  article,
  manufacturer,
  message,
  campaign,
  campaignWizard,
  code,
  highlight,
  category,
  report,
  ui,
  portfolio,
  tag,
  cockpit,
  userGroup,
  codeDispatch,
  mediaObject,
};

export default modules;
