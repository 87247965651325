import MediaObject from '@/domains/mediaObject/MediaObject';
import NormalizedData from '@/types/NormalizedData';

export interface MediaObjectState {
  mediaObject: NormalizedData<MediaObject>;
}

const state: MediaObjectState = {
  mediaObject: {},
};

export default state;
