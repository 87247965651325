import Domain from '@/types/Domain';
import AddressStreet from './AddressStreet';

class Address extends Domain {
  public static createAll(data: any[]): Address[] {
    return data.map(Address.create);
  }

  public static create(data: any): Address {
    return new Address(
      data.street,
      data.city,
      data.zipCode,
      data.state,
      data.country,
      data.additionalAddress,
    );
  }

  public street: AddressStreet;
  public city: string;
  public zipCode: string;
  public state?: string;
  public country?: string;
  public additionalAddress?: string;

  private constructor(
    street: AddressStreet,
    city: string,
    zipCode: string,
    state: string,
    country: string,
    additionalAddress: string,
  ) {
    super();
    this.street = AddressStreet.create(street || {});
    this.city = city;
    this.zipCode = zipCode;
    this.state = state;
    this.country = country;
    this.additionalAddress = additionalAddress;
  }

  public clone(): Address {
    return Address.create(JSON.parse(JSON.stringify(this)));
  }

  public constraints(): {} {
    return {
      city: {
        presence: {
          message: '^Stadt ist ein Pflichtfeld.',
        },
      },
      zipCode: {
        presence: {
          message: '^Postleitzahl ist ein Pflichtfeld.',
        },
      },
      street: {
        presence: true,
      },
    };
  }
}

export default Address;
