const listContainingItemsByType = <T>(data: any, Type, listSelf: boolean = false): T[] => {
  let list: T[] = [];

  if (listSelf && data instanceof Type) {
    list = list.concat(data);
  }

  if (
    data instanceof Object ||
    data instanceof Array
  ) {
    Object.values(data).forEach((prop) => {
      const subList = listContainingItemsByType<T>(prop, Type, true);
      list = list.concat(subList);
    });
  }

  return list;
};

export default listContainingItemsByType;
