import ListResponse from '@/types/ListResponse';

const getListResponseEntries = (
  listResponse: ListResponse | any[],
): any[] => {
  if (listResponse instanceof Array) {
    return listResponse;
  } else if (
    listResponse &&
    listResponse._embedded &&
    listResponse._embedded.items
  ) {
    return listResponse._embedded.items;
  } else {
    return [];
  }
};

export default getListResponseEntries;
