import { ListInfo } from '@/types/ListInfo';
import Code from '@/domains/code/Code';
import NormalizedData from '@/types/NormalizedData';

export interface CodeState {
  codes: NormalizedData<Code>;
  listInfo: ListInfo;
}

const state: CodeState = {
  codes: {},
  listInfo: ListInfo.create(),
};

export default state;
