import Enum from './Enum';

interface Props {
  label: string;
}

export default class CodeType extends Enum implements Props {
  public static readonly SINGLE_USAGE = new CodeType('SINGLE_USAGE', 'Individueller Code');
  public static readonly GENERIC_UNLIMITED = new CodeType('GENERIC_UNLIMITED', 'Generischer Code (unbegrenzt)');
  public static readonly GENERIC_LIMITED = new CodeType('GENERIC_LIMITED', 'Generischer Code (begrenzt)');

  public static readonly ALL = [
    CodeType.SINGLE_USAGE,
    CodeType.GENERIC_UNLIMITED,
    CodeType.GENERIC_LIMITED,
  ];
  public static createDefault(): CodeType {
    return this.getById('SINGLE_USAGE')
  }
  public static getById(id: string): CodeType {
    return CodeType.ALL.filter((status) => status.id === id)[0];
  }

  private constructor(
    public id: string,
    public label: string,
  ) {
    super();
  }
}
