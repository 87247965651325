import store from '../store';


export default (to, from, next) => {
  if (!store.getters.isReloadRequested) {
    // No reload requested -> allow navigation
    next();
  } else {
    // Reload requested -> wait for all requested to be executed -> navigate with reload (real site change)
    let waitingIntervalId;
    let timeoutId;

    const clear = () => {
      clearInterval(waitingIntervalId);
      clearTimeout(timeoutId);
    };

    window.addEventListener('unload', () => {
      clear();
    });

    // -> Add spool active check
    const waitPromise = new Promise((resolve) => {
      const waitFunction = () => {
        if (!store.getters.isReloadRequestSpoolActive) {
          // @ts-ignore
          resolve();
        }
      };
      waitingIntervalId = setInterval(waitFunction, 100);
    });

    // -> Timeout to prevent endless waiting between route changes
    timeoutId = setTimeout(() => {
      clear();
      store.commit('clearSpool');
      next();
    }, 400);

    // -> Navigate with reload (real site change)
    waitPromise.then(() => {
      clear();

      // Using set timeout here to handle the route change browser consistent after all work is done
      setTimeout(() => {
        window.location.href = to.fullPath;
      }, 1);
    });
  }
};
