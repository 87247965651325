import createFakeResponse from '../../../test-helper/createFakeResponse';

const bodyFirstPage = {
  page: 1,
  limit: 10000,
  pages: 1,
  total: 9,
  _embedded: {
    items: [
      {
        shortcut: 'HW',
        name: 'Hardware',
      },
      {
        shortcut: 'ZU',
        name: 'Zubehörartikel',
      },
      {
        shortcut: 'GU',
        name: 'Gutschrift',
      },
      {
        shortcut: 'CB',
        name: 'Cashback',
      },
      // {
      //     shortcut: 'AW',
      //     name: 'Auftraggeberware',
      // },
      {
        shortcut: 'MD',
        name: 'Merchandising',
      },
      {
        shortcut: 'MX',
        name: 'Mix',
      },
      {
        shortcut: 'WW',
        name: 'Weißware',
      },
      {
        shortcut: 'BW',
        name: 'Braunware',
      },
    ],
  },
};

export const categoryList = createFakeResponse(bodyFirstPage);
