import MinimalMandant from '@/domains/mandant/MinimalMandant';
import fetchList, { FetchListPayload, FetchListAction } from '@/utils/api-platform/fetchList';

const fetchMandantList: FetchListAction<MinimalMandant> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload,
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/mandants`,
    domainParser: MinimalMandant.create,
    listMutation: 'setMinimalMandants',
    listInfoMutation: 'setMandantListInfo',
  });
};

export default fetchMandantList;
