import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import MandantCategories from '@/domains/category/MandantCategories';

const deleteMandantCategory: PushSingleAction<MandantCategories> = async (
  { commit, dispatch },
  payload: PushSinglePayload<MandantCategories>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'DELETE', pushPayload, {
    url: `/mandant/portfolio`,
    mutation: undefined,
  });
};

export default deleteMandantCategory;
