import fetchList, {
  FetchListAction,
  FetchListPayload,
} from '@/utils/api-platform/fetchList';
import SimpleMandant from '@/domains/mandant/SimpleMandant';

const fetchSimpleMandantList: FetchListAction<SimpleMandant> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/mandant/simple-list`,
    domainParser: SimpleMandant.create,
    listMutation: 'setSimpleMandants',
  });
};

export default fetchSimpleMandantList;
