import Domain from '@/types/Domain';

export interface Props {
  id: string;
  priority: boolean;
  subject: string;
  startDate: Date;
  markedAsRead: boolean;
  endDate?: Date;
}

class MinimalMessage extends Domain implements Props {
  public static createAll(messages: any[]): MinimalMessage[] {
    return messages.map(MinimalMessage.create);
  }

  public static create(data: any = {}): MinimalMessage {
    return new MinimalMessage(
        data.id || '',
        data.priority || false,
        data.subject || '',
        data.startDate && new Date(data.startDate),
        data.markedAsRead || false,
        data.endDate && new Date(data.endDate),
    );
  }

  public static createDefault(): MinimalMessage {
    return MinimalMessage.create({
      id: '0000-0000-0000-000A',
      priority: true,
      subject: 'Betreff',
    });
  }

  protected constructor(
      public id: string,
      public priority: boolean,
      public subject: string,
      public startDate: Date,
      public markedAsRead: boolean,
      public endDate?: Date,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      priority: {
        presence: {
          message: '^Priorität ist ein Pflichtfeld',
        },
      },
      subject: {
        presence: {
          message: '^Betreff ist ein Pflichtfeld',
        },
      },
    };
  }

  public clone(): MinimalMessage {
    return MinimalMessage.create(JSON.parse(JSON.stringify(this)));
  }
}

export default MinimalMessage;
