import { Module } from 'vuex';
import { RootState } from '@/store';
import mutations from './mutations';
import state, { CategoryState } from './state';
import actions from './actions';
import getters from './getters';

const categoryModule: Module<
  CategoryState,
  RootState
> = {
  state,
  getters,
  mutations,
  actions,
};
export default categoryModule;
