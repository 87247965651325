import pushSingle, { PushSingleAction, PushSingleMandantSpecificPayload } from '@/utils/pushSingle';
import User from '@/domains/mandant/User';

const createUser: PushSingleAction<User> = async (
  { commit, dispatch, rootGetters },
  payload: PushSingleMandantSpecificPayload<User>,
) => {
  const { fetch, mandantId, domain } = payload;
  const pushPayload = { fetch, domain };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/user`;
  } else {
    url = `/mandant/${mandantId}/user`;
  }

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url,
    mutation: 'addUsers',
  });
};

export default createUser;
