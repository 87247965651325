import getWeekOfYear from './getWeekOfYear';

type DisplayDateMode = 'FULL_NUMERIC' | 'WEEK_OF_YEAR' | 'MONTH_SHORT' | 'ISO' | 'FULL_ISO' | 'SHORT_ISO';

export default (date: Date, mode: DisplayDateMode = 'FULL_NUMERIC'): string => {
  if (isNaN(date?.getTime())) { // An invalid date object returns NaN for getTime()
    return '';
  }
  switch (mode) {
    case 'FULL_NUMERIC':
      const options = {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      };

      // @ts-ignore
      return date.toLocaleDateString('de-DE', options);
      break;
    case  'WEEK_OF_YEAR':
      return `KW${getWeekOfYear(date)}`;
      break;
    case  'MONTH_SHORT':
      return `${date.toLocaleString('de-DE', { month: 'short' })}.`;
      break;
    case  'ISO':
      return `${date.toISOString().split('T')[0]}`;
      break;
    case  'FULL_ISO':
      return `${date.toISOString()}`;
      break;
    case  'SHORT_ISO':
      return `${date.toISOString().substr(0, 10)}`;
      break;
  }
};
