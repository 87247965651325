import Domain from '@/types/Domain';
import Tag from '@/domains/tag/Tag';


interface Props {
  tag: Tag;
  articleCount: number;
}

class TagArticleCount extends Domain implements Props {
  public static createAll(data: any[]): TagArticleCount[] {
    return data.map(TagArticleCount.create);
  }

  public static create(data: any): TagArticleCount {
    return new TagArticleCount(
      data.tag.id,
      Tag.create(data.tag),
      data.articleCount,
    );
  }

  private constructor(
    public id: string,
    public tag: Tag,
    public articleCount: number,
  ) {
    super();
  }

  public clone(): TagArticleCount {
    return TagArticleCount.create(this);
  }

  public constraints(): {} {
    return {
      articleCount: {
        presence: {
          message: '^Artikelmenge ist ein Pflichtfeld',
        },
      },
    };
  }
}

export default TagArticleCount;
