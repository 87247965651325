import FetchFn from '@/types/FetchFn';
import { HttpMethod, post, put, patch, del } from '@/utils/doRequest';
import Domain from '@/types/Domain';
import normalizeData from './normalizeData';
import Article from '@/domains/article/Article';
import Mandant from '@/domains/mandant/Mandant';
import scrollToFirstErroneousElement from './scrollToFirstErroneousElement';
import handleAPIValidationsWithDomain from './handleAPIValidationsWithDomain';
import Campaign from '@/domains/campaign/Campaign';
import restify from '@/utils/restify';
import Tag from '@/domains/tag/Tag';
import CampaignEdit from '@/domains/campaign/CampaignEdit';
import RefreshSession from '@/domains/auth/RefreshSession';

export interface PushSinglePayload<T extends Domain> {
  fetch: FetchFn;
  domain: T;
  shouldRequestRefreshToken?: boolean;
  shouldSetAuthorizationHeader?: boolean;
}

export interface PushSingleMandantSpecificPayload<T extends Domain> extends PushSinglePayload<T> {
  mandantId: string;
}

export interface ExtendedPushSinglePayload {
  url: string;
  mutation: string;
}

export type PushSingleAction<T extends Domain> = (
  {
    commit,
    dispatch,
    rootGetters,
  },
  payload: PushSinglePayload<T>,
) => Promise<any>;

const pushSingle = async<T extends Domain> (
  { commit, dispatch },
  method: HttpMethod,
  { fetch,
    domain,
    shouldRequestRefreshToken = true,
    shouldSetAuthorizationHeader = true,
  }: PushSinglePayload<T>,
  {
    url,
    mutation,
  }: ExtendedPushSinglePayload,
): Promise<any> => {
  if (!(domain instanceof Domain)) {
    throw new Error('Payload is not of type Domain');
  }

  // TODO: REMOVE after CampaignArticle refactoring!!!
  if (!(domain instanceof Campaign)) {
    if (!domain.validate()) {
      // console.warn(domain);
      scrollToFirstErroneousElement();
      throw new Error('Domain invalid');
    }
  }

  let body: FormData | T;
  if (
    domain instanceof Article ||
    domain instanceof Mandant ||
    domain instanceof Tag ||
    domain instanceof Campaign ||
    domain instanceof CampaignEdit ||
    domain instanceof RefreshSession
  ) {
    body = domain.toFormData();
  } else {
    body = domain.clone() as T;
    body.cleanse(true);
    delete body.id;
    restify(body);
  }

  let request;
  switch (method) {
    case 'POST':
      request = post(
        { commit, dispatch },
        fetch,
        url,
        body,
        shouldRequestRefreshToken,
        shouldSetAuthorizationHeader,
      );
      break;
    case 'PUT':
      request = put({ commit, dispatch }, fetch, url, body);
      break;
    case 'PATCH':
      request = patch({ commit, dispatch }, fetch, url, body);
      break;
    case 'DELETE':
      request = del({ commit, dispatch }, fetch, url, body);
      break;
    default:
      break;
  }

  let response;
  try {
    response = await request;
  } catch (error) {
    await handleAPIValidationsWithDomain<T>(error, domain);
    console.warn(error);
    throw error;
  }

  if (method === 'POST') {
    /**
     * Normally the request responds with an id for the object just created.
     * But since POST is the only protocol that supports binaries in form data,
     * some edit requests that would usually use PUT or PATCH (e.g. editMandant)
     * need to use POST. In these cases 'response' is undefined and has no id.
     */
    if (response && response.id) {
      domain.id = response.id;
    }
  }

  if (mutation) {
    const normalizedData = normalizeData([domain]);
    commit(mutation, normalizedData);
  }

  return method === 'POST' ? response : domain;
};

export default pushSingle;
