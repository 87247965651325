import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { ArticleState } from '../state';
import fetchArticleList from './fetchArticleList';
import fetchArticle from './fetchArticle';
import createArticle from './createArticle';
import editArticle from './editArticle';
import downloadPromoMaterial from './downloadPromoMaterial';

const actions: ActionTree<ArticleState, RootState> = {
  fetchArticleList,
  fetchArticle,
  createArticle,
  editArticle,
  downloadPromoMaterial,
};
export default actions;
