import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import Tag from '@/domains/tag/Tag';


const createTag: PushSingleAction<Tag> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Tag>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/tag`,
    mutation: 'addTags',
  });
};

export default createTag;
