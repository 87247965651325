import Domain from '@/types/Domain';
import FreeTextField from '@/domains/freeTextField/FreeTextField';


interface Props {
  name: string;
  description: string;
  regExp?: string;
  required?: boolean;
  type?: string;
  caseSensitive?: boolean;
}

class CampaignCustomerField extends Domain implements Props {
  public static createAll(data: any[]): CampaignCustomerField[] {
    return data.map(CampaignCustomerField.create);
  }

  public static create(data: any): CampaignCustomerField {
    return new CampaignCustomerField(
      data.name,
      data.description,
      data.regExp || '',
      data.regExp ? (typeof data.required === 'boolean' ? data.required : !!parseInt(data.required)) : true,
      data.type || 'TEXT',
      data.caseSensitive,
    );
  }

  public static fromFreeTextField(textField: FreeTextField): CampaignCustomerField {
    return this.create({
      name: textField.name,
      description: textField.description,
      regExp: textField.toRegExp(),
      required: textField.required,
      type: textField.type,
      caseSensitive: textField.caseSensitive,
    });
  }

  private constructor(
    public name: string,
    public description: string,
    public regExp: string,
    public required: boolean,
    public type: string,
    public caseSensitive: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      name: {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
        format: {
          pattern: /^.{3,250}$/,
          message: '^Dieses Feld muss zwischen 3 und 250 Zeichen lang sein',
        },
      },
      description: {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
        format: {
          pattern: /^.{3,1000}$/,
          message: '^Dieses Feld muss zwischen 3 und 1000 Zeichen lang sein',
        },
      },
      required: {
        presence: true,
      },
      type: {
        presence: false,
        format: {
          pattern: /^(TEXT)$/,
          message: '^Dieses Feld muss einen der folgenden Werte enthalten: TEXT',
        },
      },
    };
  }

  public clone(): CampaignCustomerField {
    return CampaignCustomerField.create(JSON.parse(JSON.stringify(this)));
  }
}

export default CampaignCustomerField;
