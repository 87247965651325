import createFakeResponse from '../../../test-helper/createFakeResponse';
import Manufacturer from '@/domains/manufacturer/Manufacturer';

export const itemsFirstPage: Manufacturer[] = Manufacturer.createAll([
  {
    id: 'b1fdac14-c7b0-11e8-a8d5-f2801f1b9fd1',
    name: 'Apple',
  },
  {
    id: 'b1fdae94-c7b0-11e8-a8d5-f2801f1b9fd1',
    name: 'Microsoft',
  },
  {
    id: 'b1fdafe8-c7b0-11e8-a8d5-f2801f1b9fd1',
    name: 'Huawei',
  },
  {
    id: 'b1fdb11e-c7b0-11e8-a8d5-f2801f1b9fd1',
    name: 'HTC',
  },
  {
    id: 'b1fdb4b6-c7b0-11e8-a8d5-f2801f1b9fd1',
    name: 'Bosch',
  },
]);

export const bodyFirstPage = {
  page: 1,
  limit: 10000,
  pages: 1,
  total: 5,
  _embedded: {
    items: itemsFirstPage,
  },
};

export const manufacturerList = createFakeResponse(bodyFirstPage);
