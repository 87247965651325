import Component from 'vue-class-component';
import TsxComponent from '@/typeDefinitions/vue-tsx';
import NewFormGroup from '@/components/formGroup/NewFormGroup';
import mutateDomainProperty from '@/components/form/utils/mutateDomainProperty';
import getDomainProperty from '@/components/form/utils/getDomainProperty';
import getIdFromPath from '@/components/form/utils/getIdFromPath';
import Domain from '@/types/Domain';
import Input from '../input/Input';
import displayDate from '@/utils/displayDate';
import './newTextField.scss';

interface Props {
  domain: Domain;
  path: string;
  label: string;
  hint?: string;
  placeholder?: string;
  idPrefix?: string;
  disabled?: boolean;
  type?: string;
  selectOnFocus?: boolean;
  description?: string;
  customConstraints?: () => {};
  minNumber?: string;
  maxNumber?: string;
  min?: Date;
  max?: Date;
  class?: string;
  autofocus?: boolean;
  leftIcon?: string;
  rightIcon?: string;
  useAlternativeStyling?: boolean;
  limit?: number;
  autocomplete?: string;
}

@Component({
  props: {
    domain: Object,
    path: String,
    label: String,
    hint: String,
    placeholder: String,
    idPrefix: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    type: { type: String, default: 'text' },
    selectOnFocus: { type: Boolean, default: false },
    description: String,
    customConstraints: Function,
    minNumber: String,
    maxNumber: String,
    min: Date,
    max: Date,
    autofocus: Boolean,
    leftIcon: String,
    rightIcon: String,
    useAlternativeStyling: Boolean,
    limit: Number,
    autocomplete: String,
    dataQa: String,
  },
})
class NewTextField extends TsxComponent<Props> {
  private charsLeft: number;

  private calculateCharsLeft(value: string): void {
    if (!value) value = '';
    this.charsLeft = this.$props.limit - value.length;
    this.$forceUpdate();
  }

  private onInput(e: Event): void {
    const { domain, path } = this.$props;

    const inputValue = (e.target as HTMLSelectElement).value;
    mutateDomainProperty(domain, path, inputValue);

    if (this.$props.limit) this.calculateCharsLeft(inputValue);

    this.$emit('change', e);
  }

  private onFocus(e: Event): void {
    const { domain, path, selectOnFocus } = this.$props;

    domain.removeError(path);
    this.$forceUpdate();

    if (selectOnFocus) {
      const input = e.target as HTMLInputElement;
      input.setSelectionRange(0, input.value.length);
    }

    this.$emit('focus', e);
  }

  private created() {
    if (this.$props.limit) {
      this.calculateCharsLeft(
        getDomainProperty(this.$props.domain, this.$props.path)
      );
    }
  }

  private render() {
    const {
      domain,
      path,
      label,
      hint,
      placeholder,
      type,
      idPrefix,
      disabled,
      description,
      customConstraints,
      min,
      max,
      minNumber,
      maxNumber,
      autofocus,
      leftIcon,
      rightIcon,
      useAlternativeStyling,
      limit,
      autocomplete,
      dataQa,
    } = this.$props;

    const fullPath = `${idPrefix ? idPrefix + '-' : ''}${path}`;

    return (
      <NewFormGroup
        domain={domain}
        path={path}
        label={label}
        hint={hint}
        customConstraints={customConstraints}
        description={description}
        leftIcon={leftIcon}
        rightIcon={rightIcon}
        useAlternativeStyling={useAlternativeStyling}
        charsLeft={this.charsLeft}
        limit={limit}
      >
        {this.$slots['input-prefix']}
        <Input
          id={getIdFromPath(fullPath)}
          type={type}
          placeholder={placeholder || label}
          value={getDomainProperty(domain, path)}
          disabled={disabled}
          min={
            (min && displayDate(min, 'ISO')) ||
            (minNumber && minNumber.toString())
          }
          max={
            (max && displayDate(max, 'ISO')) ||
            (maxNumber && maxNumber.toString())
          }
          autofocus={autofocus}
          onInput={this.onInput}
          onFocus={this.onFocus}
          onBlur={(event) => this.$emit('blur', event)}
          onPaste={(event) => this.$emit('paste', event)}
          maxlength={limit}
          autocomplete={autocomplete}
          dataQa={dataQa}
        />
        {this.$slots['input-suffix']}
      </NewFormGroup>
    );
  }
}

export default NewTextField;
