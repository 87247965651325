import Component from 'vue-class-component';
import classNames from 'classnames';
import TsxComponent from '@/typeDefinitions/vue-tsx';
import SelectOption from '@/types/SelectOption';

interface Props {
  options: SelectOption[];
  value?: string;
  id?: string;
  qa?: string;
  placeholder?: string;
  idPrefix?: string;
  useAlternativeStyling?: boolean;
  class?: string;
}

@Component({
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    value: String,
    id: String,
    qa: String,
    placeholder: String,
    idPrefix: String,
    useAlternativeStyling: Boolean,
    dataQa: String,
  },
})
class Select extends TsxComponent<Props> {
  public render() {
    const {
      options,
      value,
      id,
      qa,
      placeholder,
      idPrefix,
      useAlternativeStyling,
      dataQa,
    } = this.$props;

    const fullPath = `${idPrefix ? idPrefix + '-' : ''}${id}`;

    return (
      <div
        class={classNames(
          'styled-select',
          useAlternativeStyling && 'styled-select--inverted'
        )}
      >
        <select
          class="form-control"
          id={fullPath !== 'undefined' ? fullPath : null}
          data-qa={dataQa}
          placeholder={placeholder}
          onChange={(event) => this.$emit('change', event)}
          onFocus={(event) => this.$emit('focus', event)}
          value={value}
        >
          {options.map((option) => (
            <option
              key={option.key}
              value={option.value}
              selected={option.value === value}
            >
              {option.label ? option.label : option.value}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

export default Select;
