import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';

const updateCodeDispatch: PushSingleAction<CodeDispatch> = async (
  { commit, dispatch },
  payload: PushSinglePayload<CodeDispatch>,
): Promise<CodeDispatch> => {
  const { fetch, domain } = payload;
  const { id } = domain;
  const pushPayload = { fetch, domain };

  return CodeDispatch.create(await pushSingle({ commit, dispatch }, 'PUT', pushPayload, {
    url: `/code-dispatches/${id}`,
    mutation: '',
  }));
};

export default updateCodeDispatch;
