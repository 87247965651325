import createFakeResponse from '../../../test-helper/createFakeResponse';

export const articleEdit = createFakeResponse({
  id: '8ab919c7-b343-47d5-8d12-deb4788e1fe4',
  active: true,
  name: 'MacBook Pro 15"',
  categoryId: 'HW',
  mandantId: '034869b8-0675-4a7f-b868-48507f674179',
  manufacturerId: 'b1fdac14-c7b0-11e8-a8d5-f2801f1b9fd1',
  weight: '1200',
  weightUnit: 'g',
  endOfLife: '31.12.2019',
  taxRate: '19',
  bulletPoints: ['Cool', 'Fast', 'Awesome'],
  description: 'Awesome laptop',
  images: [
    {
      id: '294e3df1-0088-426a-aa97-XXXXXX',
      url:
        'https://store.storeimages.cdn-apple.com/4667/as-images.apple.com/is' +
        '/image/AppleInc/aos/published/images/m/ac/macbook/select' +
        '/macbook-select-space-gray-201706?wid=904&hei=840&fmt=jpeg&qlt=80' +
        '&op_usm=0.5,0.5&.v=1537307258638',
      description: 'Verfügbare MacBooks',
      size: 66348,
      extension: 'jpg',
      filename: 'macbook-select-space-gray-201706.jpg',
    },
  ],
  variants: [
    {
      id: '294e3df1-0088-426a-aa97-40e76f210cd10',
      active: true,
      name: 'MacBook Pro 15" Space Grau',
      ebootisId: 'ZU102526',
      buyPrice: { amount: 249999, currency: 'EUR' },
      articlePrice: { amount: 249999, currency: 'EUR' },
      advertisingCostsSubsidy: { amount: 2500, currency: 'EUR' },
      unitFee: { amount: 3500, currency: 'EUR' },
      ean: '1929312931923',
      weight: '1200',
      weightUnit: 'g',
      endOfLife: '31.12.2019',
      description: 'Awesome laptop',
      images: [
        {
          id: '294e3df1-0088-426a-aa97-XXXXXX',
          url:
            'https://store.storeimages.cdn-apple.com/4667/as-images.apple.com/is' +
            '/image/AppleInc/aos/published/images/m/ac/macbook/select' +
            '/macbook-select-space-gray-201706?wid=904&hei=840&fmt=jpeg&qlt=80' +
            '&op_usm=0.5,0.5&.v=1537307258638',
          description: 'Verfügbare MacBooks',
          size: 66348,
          extension: 'jpg',
          filename: 'macbook-select-space-gray-201706.jpg',
        },
      ],
    },
  ],
});

export const articleEdited = createFakeResponse({}, 204);
