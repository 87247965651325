import Domain from '@/types/Domain';

interface Props {
  parkedExternalNote?: string;
  parkedInternalNote?: string;
  cancelationNote?: string;
  cancelationReason?: string;
  editor: string;
  updatedAt: string;
  orderStatus: string;
}

class OrderHistoryEntry extends Domain implements Props {
  public static createAll(data: any[]): OrderHistoryEntry[] {
    return data.map(OrderHistoryEntry.create);
  }

  public static create(data: any): OrderHistoryEntry {
    return new OrderHistoryEntry(
      data.parkedExternalNote,
      data.parkedInternalNote,
      data.cancelationNote,
      data.cancelationReason,
      data.editor,
      data.updatedAt,
      data.orderStatus,
    );
  }

  private constructor(
    public parkedExternalNote: string,
    public parkedInternalNote: string,
    public cancelationNote: string,
    public cancelationReason: string,
    public editor: string,
    public updatedAt: string,
    public orderStatus: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): OrderHistoryEntry {
    return OrderHistoryEntry.create(JSON.parse(JSON.stringify(this)));
  }
}

export default OrderHistoryEntry;
