interface Props {
  id: string;
}

export default abstract class Enum implements Props {
  public id: string;

  public equals(obj: this): boolean {
    return obj && this.id === obj.id;
  }
}
