import User from '@/domains/mandant/User';
import fetchSingle, { FetchSinglePayload } from '@/utils/fetchSingle';
import FetchFn from '@/types/FetchFn';
import Domain from '@/types/Domain';

export interface FetchSingleUserPayload {
  fetch: FetchFn;
  id?: string;
}

export type FetchSingleUserAction<T extends Domain> = (
  {
    commit,
    dispatch,
    rootGetters,
  },
  payload: FetchSingleUserPayload,
) => Promise<T>;

const fetchUser: FetchSingleUserAction<User> = (
  { commit, dispatch, rootGetters },
  payload: FetchSingleUserPayload,
) => {
  const {
    fetch,
    id,
  } = payload;
  const fetchSinglePayload: FetchSinglePayload = {
    fetch,
    id,
  };

  let url;
  if (id === undefined) {
    url = '/my/profile';
  } else {
    if (rootGetters.isMandant) {
      url = `/my/user/${id}`;
    } else {
      url = `/user/${id}`;
    }
  }

  return fetchSingle({ commit, dispatch }, fetchSinglePayload, {
    url,
    domainParser: User.create,
    mutation: 'addUsers',
  });
};

export default fetchUser;
