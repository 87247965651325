import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import PortfolioArticle from '@/domains/portfolio/PortfolioArticle';
import MinimalPortfolioArticle from '@/domains/portfolio/MinimalPortfolioArticle';
import TagArticleCount from '@/domains/tag/TagArticleCount';


export interface PortfolioState {
  portfolioArticles: NormalizedData<PortfolioArticle>;
  minimalPortfolioArticles: NormalizedData<MinimalPortfolioArticle>;
  portfolioArticleListInfo: ListInfo;
  tagArticleCounts: NormalizedData<TagArticleCount>;
  tagArticleCountListInfo: ListInfo;
}

const state: PortfolioState = {
  portfolioArticles: {},
  minimalPortfolioArticles: {},
  portfolioArticleListInfo: ListInfo.create({ limit: 12 }),
  tagArticleCounts: {},
  tagArticleCountListInfo: ListInfo.create({ limit: 100 }),
};

export default state;
