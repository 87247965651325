import { ActionTree } from 'vuex';
import { UserState } from '../state';
import { RootState } from '@/store';
import fetchUserList from './fetchUserList';
import fetchMyUser from './fetchMyUser';
import fetchUser from './fetchUser';
import createUser from './createUser';
import editMyUser from './editMyUser';
import editUser from './editUser';

const actions: ActionTree<UserState, RootState> = {
  fetchUserList,
  fetchMyUser,
  fetchUser,
  createUser,
  editMyUser,
  editUser,
};
export default actions;
