import Component from 'vue-class-component';
import TsxComponent from '@/typeDefinitions/vue-tsx';
import classNames from 'classnames';
import styles from './Overlay.module.scss';

interface Props {
  background: 'light' | 'dark';
  fullscreen?: boolean;
}

@Component({
  props: {
    background: String,
    fullscreen: Boolean,
  },
})
export default class Overlay extends TsxComponent<Props> {
  private render() {
    const {
      background,
      fullscreen,
    } = this.$props;

    return (
      <div>
        {this.$slots.default}
        <div class={classNames(
          styles['overlay'],
          fullscreen && styles['overlay--fullscreen'],
          background && styles[`overlay--${background}`],
        )}>
          <div class={styles['overlay__content']}>{this.$slots.content}</div>
        </div>
      </div>
    );
  }
}
