import createFakeResponse from '../../../test-helper/createFakeResponse';
import sortAscending from '@/utils/sortAscending';
import sortDescending from '@/utils/sortDescending';

export const itemsFirstPage = [
  {
    id: '294e3df1-0088-426a-bb97-40e76f277cd9',
    email: 'test+2@skon.de',
    firstName: 'First-2',
    lastName: 'Last-2',
    department: 'IT',
    phone: '0178 111 1111',
    role: 'skon_aftersales',
  },
];
export const bodyFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: itemsFirstPage,
  },
};

const allItems = [...itemsFirstPage];

const bodyFiltered1FirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 0,
  _embedded: {
    items: [] as any[],
  },
};
const bodyFiltered2FirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [...allItems.filter((item) => item.lastName.indexOf('1') !== -1)],
  },
};

const bodySortedDepartmentASCFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [
      ...allItems.sort((a, b) => sortAscending(a.department, b.department)),
    ].slice(0, 10),
  },
};
const bodySortedDepartmentDESCFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [
      ...allItems.sort((a, b) => sortDescending(a.department, b.department)),
    ].slice(0, 10),
  },
};

export const userListFiltered1FirstPage = createFakeResponse(
  bodyFiltered1FirstPage,
);
export const userListFiltered2FirstPage = createFakeResponse(
  bodyFiltered2FirstPage,
);

export const userListSortedDepartmentASCFirstPage = createFakeResponse(
  bodySortedDepartmentASCFirstPage,
);
export const userListSortedDepartmentDESCFirstPage = createFakeResponse(
  bodySortedDepartmentDESCFirstPage,
);

export const userListFirstPage = createFakeResponse(bodyFirstPage);
