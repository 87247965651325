import checkStatus, { UnauthorizedRequestError } from '@/utils/checkStatus';
import parseJSON from '@/utils/parseJSON';
import FetchFn from '@/types/FetchFn';
import { getData, storeData } from './persist';
import PopupPayload from '@/types/PopupPayload';
import displayPopup from '@/store/modules/ui/actions/displayPopup';
import logout from '@/store/modules/auth/actions/logout';
import router from '@/router';
import handleResponseHeaders from './handleResponseHeaders';
import handleRequestIdRemove from './handleRequestIdRemove';


export type HttpMethod =
  | 'GET'
  | 'POST'
  | 'DELETE'
  | 'PATCH'
  | 'PUT'
  | 'HEAD'
  | 'OPTIONS'
  | 'CONNECT'
;

const doRequest = (
  {
    commit,
    dispatch,
  },
  fetchFn: FetchFn,
  url: string,
  method: HttpMethod = 'GET',
  body?: object,
  shouldRequestRefreshToken: boolean = true,
  setAuthorizationHeader: boolean = true,
): Promise<any> => {
  const composedUrl = `${process.env.VUE_APP_API_URL}${url}`;
  commit('addRequestIdToSpool', composedUrl);

  const request = fetchFn(composedUrl, buildOptions(method, body, setAuthorizationHeader))
    .then(handleResponseHeaders)
    .then(checkStatus)
    .then(parseJSON)
  ;

  if (!shouldRequestRefreshToken) {
    return request
      .finally(() => handleRequestIdRemove(composedUrl));
  }

  return handleRequestRefreshToken(
    { commit, dispatch },
    fetchFn,
    url,
    composedUrl,
    request,
    method,
    body,
  );
};

const handleRequestRefreshToken = (
  {
    commit,
    dispatch,
  },
  fetchFn: FetchFn,
  url: string,
  composedUrl: string,
  request: Promise<any>,
  method: HttpMethod = 'GET',
  body?: object,
  fileName?: string,
) => {
  const loginRefreshUrl = '/login/refresh';

  return request
    .catch((error) => {
      if (error instanceof UnauthorizedRequestError) {
        // userToken is invalid -> call refresh to get new one
        if (url === loginRefreshUrl) {
          // @ts-ignore
          logout(
            { commit },
            { router, logoutErrorMessage: 'Aufgrund von Inaktivität wurden Sie ausgeloggt.' },
          );
        } else {
          return doRequest({
            commit,
            dispatch,
          }, fetchFn, loginRefreshUrl, 'POST', {
            refresh_token: getData('refresh-token'),
          }, undefined, false)
            .then((response) => {
              storeData('user-token', response.token);
              storeData('refresh-token', response.refresh_token);
              storeData('logout-timestamp', Date.parse(response.logout_timestamp));
              commit('setRefreshToken', response.refresh_token);
              commit('setLogoutTimestamp', Date.parse(response.logout_timestamp));

              // retry initial request
              if (!!fileName) {
                return download({
                  commit,
                  dispatch,
                },
                  fetchFn,
                  url,
                  fileName,
                )
                  .finally(() => handleRequestIdRemove(composedUrl));
              } else {
                return doRequest({
                  commit,
                  dispatch,
                }, fetchFn, url, method, body)
                  .finally(() => handleRequestIdRemove(composedUrl));
              }
            })
            .finally(() => handleRequestIdRemove(composedUrl));
        }
      } else {
        handleError(error, {
          commit,
          url: composedUrl,
          method,
        });
        handleRequestIdRemove(composedUrl);
        throw error;
      }
    })
    .finally(() => handleRequestIdRemove(composedUrl));
};

export const download = (
  { commit, dispatch },
  fetchFn: FetchFn,
  url: string,
  fileName: string,
  shouldRequestRefreshToken: boolean = false,
): Promise<any> => {
  // Prepare download link backend url (without /api...)
  const apiUrl = process.env.VUE_APP_API_URL;
  const downloadUrlBase = apiUrl.substring(0, apiUrl.length - 4);
  const composedUrl = `${downloadUrlBase}${url}`;

  const request = fetchFn(composedUrl, buildOptions('GET', undefined))
    .then(handleResponseHeaders)
    .then(checkStatus)
    .then((response: Response) => response.blob())
    .then((fileBlob) => {
      // Generate local file url
      const objectUrl = window.URL.createObjectURL(new Blob([fileBlob]));

      // Prepare file download html anchor
      const link = document.createElement('a');
      link.href = objectUrl;
      link.setAttribute('download', fileName);
      link.setAttribute('type', 'hidden');

      // Add anchor to body (needed for Firefox to work)
      document.body.appendChild(link);
      // Download file and clean up
      link.click();
      link.parentNode.removeChild(link);
    });

  if (!shouldRequestRefreshToken) {
    return request
      .finally(() => handleRequestIdRemove(composedUrl));
  }

  return handleRequestRefreshToken(
    { commit, dispatch },
    fetchFn,
    url,
    composedUrl,
    request,
    undefined,
    undefined,
    fileName,
  );

};

export const fetchFile = (
  { commit, dispatch },
  fetchFn: FetchFn,
  url: string,
): Promise <any> => {
  // Prepare download link backend url (without /api...)
  const apiUrl = process.env.VUE_APP_API_URL;
  const downloadUrlBase = apiUrl.substring(0, apiUrl.length - 4);
  const composedUrl = `${downloadUrlBase}${url}`;

  return fetchFn(composedUrl, buildOptions('GET', undefined))
    .then(handleResponseHeaders)
    .then(checkStatus)
    .then((response: Response) => response.blob());
};

export const get = <R>(
  { commit, dispatch },
  fetchFn: FetchFn,
  url: string,
): Promise<R> => {
  return doRequest({ commit, dispatch }, fetchFn, url, 'GET');
};
export const post = <R>(
  { commit, dispatch },
  fetchFn: FetchFn,
  url: string,
  body: object,
  shouldRequestRefreshToken: boolean = true,
  shouldSetAuthorizationHeader: boolean = true,
): Promise<R> => {
  return doRequest(
    { commit, dispatch },
    fetchFn,
    url,
    'POST',
    body,
    shouldRequestRefreshToken,
    shouldSetAuthorizationHeader,
  );
};

export const patch = <R>(
  { commit, dispatch },
  fetchFn: FetchFn,
  url: string,
  body?: object,
): Promise<R> => {
  return doRequest({ commit, dispatch }, fetchFn, url, 'PATCH', body);
};

export const put = <R>(
  { commit, dispatch },
  fetchFn: FetchFn,
  url: string,
  body?: object,
): Promise<R> => {
  return doRequest({ commit, dispatch }, fetchFn, url, 'PUT', body);
};

export const del = <R>(
  { commit, dispatch },
  fetchFn: FetchFn,
  url: string,
  body?: object,
): Promise<R> => {
  return doRequest({ commit, dispatch }, fetchFn, url, 'DELETE', body);
};

const shouldSerialize = (body: any) => {
  return !(body instanceof FormData) && !(body instanceof URLSearchParams);
};

const buildHeaders = (body: any, setAuthorizationHeader: boolean): { [name: string]: string } => {
  const headers: any = getData('user-token') && setAuthorizationHeader ? {
    Authorization: `Bearer ${getData('user-token')}`,
  } : {};

  if (shouldSerialize(body)) {
    headers['Content-Type'] = 'application/json';
  }

  return headers;
};

const buildOptions = (method: HttpMethod, body: any, setAuthorizationHeader: boolean = true): { [k: string]: any } => {
  const options: { [k: string]: any } = {
    method,
    headers: buildHeaders(body, setAuthorizationHeader),
  };

  if (body) {
    options.body = shouldSerialize(body) ? JSON.stringify(body) : body;
  }

  return options;
};

interface ErrorPayload {
  commit;
  url: string;
  method: HttpMethod;
}
interface ErrorPopupPayload {
  icon: string;
  title: string;
  description: string;
}

const handleError = (
  error,
  {
    commit,
    url,
    method,
  }: ErrorPayload,
): void => {
  let errorPopupPayload: ErrorPopupPayload;
  if (
    error &&
    error.response &&
    error.response.status
  ) {
    if (error.response.status === 400) {
      // invalid form data
      throw error;
    } else {
      errorPopupPayload = getErrorInfoByStatusCode({
        commit,
        url,
        method,
      }, error.response.status);
    }
  } else {
    errorPopupPayload = getDefaultErrorInfo({
      commit,
      url,
      method,
    });
  }
  const {
    icon,
    title,
    description,
  } = errorPopupPayload;

  const payload: PopupPayload = {
    type: 'hint',
    icon,
    title,
    description,
  };

  // @ts-ignore
  displayPopup({ commit }, payload);
};

const getErrorInfoByStatusCode = ({ commit, url, method }: ErrorPayload, status: number): ErrorPopupPayload => {
  if (status === 404) {
    return {
      icon: 'power-off',
      title: 'Ressource existiert nicht',
      description: `${url}`,
    };
  } else if (status === 403) {
    return {
      icon: 'power-off',
      title: 'Unzureichende Berechtigungen',
      description: `${url}`,
    };
  } else if (status === 500) {
    return {
      icon: 'power-off',
      title: 'Serverfehler',
      description: `${url}`,
    };
  } else {
    return getDefaultErrorInfo({ commit, url, method });
  }
};

const getDefaultErrorInfo = ({ url, method }: ErrorPayload): ErrorPopupPayload => {
  let title: string;
  let description: string;
  switch (method) {
    case 'GET':
      title = 'Ressource nicht gefunden';
      description = 'Die angefragte Ressource ist nicht erreichbar.';
      break;
    case 'POST':
    case 'DELETE':
    case 'PATCH':
    case 'PUT':
      title = 'Invalide Anfrage';
      description = 'Möglicherweise sind ein oder mehrere Formularfelder falsch befüllt.';
      break;
    default:
      break;
  }

  description = `${description}\n${url}`;

  return {
    icon: 'power-off',
    title,
    description,
  };
};
