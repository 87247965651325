import Domain from '@/types/Domain';

interface Props {
  id: string;
  name: string;
  shopHost: string;
}

class SimpleMandant extends Domain implements Props {
  public static createAll(simpleMandants: any[]): SimpleMandant[] {
    return simpleMandants.map(SimpleMandant.create);
  }

  public static create(data: any): SimpleMandant {
    return new SimpleMandant(
      data.id,
      data.name,
      data.shopHost,
    );
  }

  private constructor(
    public id: string,
    public name: string,
    public shopHost: string,
  ) {
    super();
  }

  public clone(): SimpleMandant {
    return SimpleMandant.create(JSON.parse(JSON.stringify(this)));
  }

  public constraints(): {} {
    return {
      name: {
        presence: {
          message: '^Firma ist ein Pflichtfeld.',
        },
      },
    };
  }
}

export default SimpleMandant;
