import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { AuthState } from '../state';
import loginRequest from './loginRequest';
import refreshSessionRequest from './refreshSessionRequest';
import passwordResetRequest from './passwordResetRequest';
import passwordResetNewPassword from './passwordResetNewPassword';
import logout from './logout';

const actions: ActionTree<AuthState, RootState> = {
  loginRequest,
  refreshSessionRequest,
  passwordResetRequest,
  passwordResetNewPassword,
  logout,
};
export default actions;
