import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { MandantState } from '../state';
import fetchMandantList from './fetchMandantList';
import createMandant from './createMandant';
import editMandant from './editMandant';
import fetchMandant from './fetchMandant';
import fetchSimpleMandantList from './fetchSimpleMandantList';

const actions: ActionTree<MandantState, RootState> = {
  fetchMandantList,
  fetchSimpleMandantList,
  createMandant,
  editMandant,
  fetchMandant,
};
export default actions;
