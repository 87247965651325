import Domain from '@/types/Domain';

const hexCodeRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$|^$/;

class MandantDesignData extends Domain {
  public static createAll(mandants: any[]): MandantDesignData[] {
    return mandants.map(MandantDesignData.create);
  }

  public static create(data: any): MandantDesignData {
    return new MandantDesignData(
      data.navigationBackgroundColor,
      data.iconColor,
      data.buttonBackgroundColor,
      data.buttonColor,
      data.styleElementBackgroundColor,
      data.styleElementColor,
      data.footerBackgroundColor,
      data.footerColor,
      data.errorColor,
      data.errorColorSecondary,
    );
  }

  private constructor(
    public navigationBackgroundColor: string,
    public iconColor: string,
    public buttonBackgroundColor: string,
    public buttonColor: string,
    public styleElementBackgroundColor: string,
    public styleElementColor: string,
    public footerBackgroundColor: string,
    public footerColor: string,
    public errorColor: string,
    public errorColorSecondary: string,
  ) {
    super();
  }

  public clone(): MandantDesignData {
    const clone = JSON.parse(JSON.stringify(this));
    return MandantDesignData.create({
      ...clone,
    });
  }

  public constraints(): {} {
    const constraint = {
      presence: {
        message: '^Dieses Feld wird benötigt.',
      },
      format: {
        pattern: hexCodeRegex,
        message: '^Der Farbcode muss im Hex-Format sein',
      },
    };

    const keys = ['navigationBackgroundColor', 'iconColor', 'buttonBackgroundColor', 'buttonColor', 'styleElementBackgroundColor', 'styleElementColor', 'footerBackgroundColor', 'footerColor', 'errorColor', 'errorColorSecondary'];
    const retVal = {};

    keys.map((key) => {
      retVal[key] = constraint;
    });
    return retVal;
  }
}

export default MandantDesignData;
