import scrollToFirstErroneousElement from '@/utils/scrollToFirstErroneousElement';
import Domain from '@/types/Domain';
import setAPIValidationErrorsWithDomain from './setAPIValidationErrorsWithDomain';

export default async <T extends Domain = Domain>(error: any, domain: T) => {
  const response = error.response;
  if (response.status === 400) {
    const errorResp: any = await response.json();
    setAPIValidationErrorsWithDomain<T>(errorResp, domain);
    scrollToFirstErroneousElement();
  }
};
