import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import HighlightArticle from '@/domains/highlight/HighlightArticle';
import MinimalArticle from '@/domains/article/MinimalArticle';


export interface HighlightState {
  highlights: NormalizedData<HighlightArticle>;
  articles: NormalizedData<MinimalArticle>;
  listInfo: ListInfo;
}

const state: HighlightState = {
  highlights: {},
  articles: {},
  listInfo: ListInfo.create(),
};

export default state;
