import { GetterTree } from 'vuex';
import { ArticleState } from './state';
import { ListInfo } from '@/types/ListInfo';
import Article from '@/domains/article/Article';
import NormalizedData from '@/types/NormalizedData';
import MinimalArticle from '@/domains/article/MinimalArticle';

type ArticleGetter = (id: string) => Article;

const getArticle = (state: ArticleState): ArticleGetter => (
  id: string,
): Article => state.articles[id];

export const getMinimalArticles = (state: ArticleState): NormalizedData<MinimalArticle> => {
  return state.minimalArticles;
};

export const getArticleListInfo = (state: ArticleState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<ArticleState, any> = {
  getArticle,
  getMinimalArticles,
  getArticleListInfo,
};

export default getters;
