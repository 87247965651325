import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/fetchSingle';
import UserGroup from '@/domains/userGroup/UserGroup';


const fetchUserGroup: FetchSingleAction<UserGroup> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload,
) => {
  const {
    id,
  } = payload;

  let url;
  if (rootGetters.isMandant) {
    url = `/my/user-group`;
  } else {
    url = `/mandant/${rootGetters.getSimulatedMandantId}/user-group`;
  }

  return await fetchSingle({ commit, dispatch }, payload, {
    url: `${url}/${id}`,
    domainParser: UserGroup.create,
    mutation: 'addUserGroups',
  });
};

export default fetchUserGroup;
