import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { CategoryState } from '../state';
import createCategory from './createCategory';
import editCategory from './editCategory';
import fetchCategoryList from './fetchCategoryList';
import downloadArticleList from './downloadArticleList';
import fetchCategoryVariantList from '@/store/modules/category/actions/fetchCategoryVariantList';
import attachMandantCategory from '@/store/modules/category/actions/attachMandantCategory';
import deleteMandantCategory from '@/store/modules/category/actions/deleteMandantCategory';

const actions: ActionTree<CategoryState, RootState> = {
  createCategory,
  editCategory,
  fetchCategoryList,
  fetchCategoryVariantList,
  attachMandantCategory,
  deleteMandantCategory,
  downloadArticleList,
};
export default actions;
