import Domain from '@/types/Domain';


interface Props {
  unusedCodesQuantity: number;
}

class RemainingCodes extends Domain implements Props {
  public static createAll(codes: any[]): RemainingCodes[] {
    return codes.map(RemainingCodes.create);
  }

  public static create(data: any): RemainingCodes {
    if (typeof data === 'number') {
      return new RemainingCodes(
        data,
      );
    } else {
      return new RemainingCodes(
        data.unusedCodesQuantity,
      );
    }
  }

  private constructor(
    public unusedCodesQuantity: number,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): RemainingCodes {
    return RemainingCodes.create(JSON.parse(JSON.stringify(this)));
  }
}

export default RemainingCodes;
