import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/fetchSingle';
import RemainingCodes from '@/domains/cockpit/RemainingCodes';


const fetchRemainingCodes: FetchSingleAction<RemainingCodes> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload,
) => {
  const domain = await fetchSingle({ commit, dispatch }, payload, {
    url: '/code',
    domainParser: RemainingCodes.create,
    mutation: undefined,
  });

  commit('setRemainingCodes', domain);

  return domain;
};

export default fetchRemainingCodes;
