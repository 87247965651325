import Domain from '@/types/Domain';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';

interface Props {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  codeProperties: CampaignCodeProperties;
  active: boolean;
  mandantId: string;
  mandantName: string;
  createdAt: Date;
  updatedAt: Date;
  author: string;
  campaignType: string;
  authDataCount: string;
}

class MinimalCampaign extends Domain implements Props {
  public static createAll(campaigns: any[]): MinimalCampaign[] {
    return campaigns.map(MinimalCampaign.create);
  }

  public static create(data: any): MinimalCampaign {
    return new MinimalCampaign(
      data.id,
      data.name,
      data.startDate && new Date(data.startDate),
      data.endDate && new Date(data.endDate),
      CampaignCodeProperties.create(data.codeProperties || {}),
      typeof data.active === 'boolean' ? (data.active as boolean) : !!parseInt(data.active),
      data.mandantId,
      data.mandantName,
      data.createdAt && new Date(data.createdAt),
      data.updatedAt && new Date(data.updatedAt),
      data.author,
      data.campaignType,
      data.authDataCount,
    );
  }

  private constructor(
    public id: string,
    public name: string,
    public startDate: Date,
    public endDate: Date,
    public codeProperties: CampaignCodeProperties,
    public active: boolean,
    public mandantId: string,
    public mandantName: string,
    public createdAt: Date,
    public updatedAt: Date,
    public author: string,
    public campaignType: string,
    public authDataCount: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): MinimalCampaign {
    return MinimalCampaign.create(JSON.parse(JSON.stringify(this)));
  }
}

export default MinimalCampaign;
