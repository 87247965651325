import { MutationTree } from 'vuex';
import { CampaignReportState } from './state';
import { ListInfo } from '@/types/ListInfo';
import CampaignReport from '@/domains/report/CampaignReport';
import MinimalCampaignReport from '@/domains/report/MinimalCampaignReport';
import NormalizedData from '@/types/NormalizedData';

export const addCampaignReports = (
  state: CampaignReportState,
  campaignReports: NormalizedData<CampaignReport>,
): void => {
  state.campaignReports = {
    ...state.campaignReports,
    ...campaignReports,
  };
};

export const setMinimalCampaignReports = (
  state: CampaignReportState,
  minimalCampaignReports: NormalizedData<MinimalCampaignReport>,
): void => {
  state.minimalCampaignReports = {
    ...minimalCampaignReports,
  };
};

export const setMinimalCampaignReportListInfo = (
  state: CampaignReportState,
  info: ListInfo,
): void => {
  state.listInfo = info;
};

const mutations: MutationTree<CampaignReportState> = {
  addCampaignReports,
  setMinimalCampaignReports,
  setMinimalCampaignReportListInfo,
};

export default mutations;
