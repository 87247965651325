import Domain from '@/types/Domain';
import SalesCampaignMessage from '@/domains/campaign/SalesCampaignMessage';

interface Props {
  sms: SalesCampaignMessage;
  email: SalesCampaignMessage;
}

class SalesCampaignMessagingProperties extends Domain implements Props {
  public static createAll(campaigns: any[]): SalesCampaignMessagingProperties[] {
    return campaigns.map(SalesCampaignMessagingProperties.create);
  }

  public static create(data: any): SalesCampaignMessagingProperties {
    return new SalesCampaignMessagingProperties(
      SalesCampaignMessage.create(data?.sms || {}),
      SalesCampaignMessage.create(data?.email || {}),
    );
  }

  private constructor(
    public sms: SalesCampaignMessage,
    public email: SalesCampaignMessage,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): SalesCampaignMessagingProperties {
    return SalesCampaignMessagingProperties.create(JSON.parse(JSON.stringify(this)));
  }
}

export default SalesCampaignMessagingProperties;
