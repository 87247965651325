import fetchList, { FetchUnpaginatedListAction, FetchUnpaginatedListPayload } from '@/utils/api-platform/fetchUnpaginatedList';
import SalesCampaignArticleVariant from '@/domains/campaign/SalesCampaignArticleVariant';

export interface FetchSalesCampaignArticleVariantsPayload extends FetchUnpaginatedListPayload {
  campaignId: string;
}

const fetchSalesCampaignArticleVariants: FetchUnpaginatedListAction<SalesCampaignArticleVariant> = async (
  { commit, dispatch, rootGetters },
  fetchUnpaginatedListPayload: FetchSalesCampaignArticleVariantsPayload,
) => {
  const { campaignId } = fetchUnpaginatedListPayload;
  const url = `/sales/campaigns/articles?campaignId=${campaignId}`;

  return await fetchList({ commit, dispatch }, {
    ...fetchUnpaginatedListPayload,
  }, {
    url,
    domainParser: SalesCampaignArticleVariant.create,
    listMutation: 'setSalesCampaignArticleVariants',
  });
};

export default fetchSalesCampaignArticleVariants;
