import Enum from './Enum';

interface Props {
  salutation: string;
  salutationLong: string;
}

export default class Gender extends Enum implements Props {
  public static readonly MALE = new Gender('MALE', 'Herr', 'Sehr geehrter Herr');
  public static readonly FEMALE = new Gender('FEMALE', 'Frau', 'Sehr geehrte Frau');

  public static readonly ALL = [
    Gender.MALE,
    Gender.FEMALE,
  ];

  public static getById(id: string): Gender {
    return Gender.ALL.filter((status) => status.id === id)[0];
  }

  private constructor(
    public id: string,
    public salutation: string,
    public salutationLong: string,
  ) {
    super();
  }
}
