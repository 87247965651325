import Domain from '@/types/Domain';


interface Props {
  value: string;
}

class RegExpValidation extends Domain implements Props {
  public static createAll(supportRegexes: any[]): RegExpValidation[] {
    return supportRegexes.map(RegExpValidation.create);
  }

  public static create(data: any = {}): RegExpValidation {
    return new RegExpValidation(
      data.value,
    );
  }

  public static fromRegExp(regExp: string): RegExpValidation {
    return this.create({
      value: regExp,
    });
  }

  private constructor(
    public value: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      value: {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
        isValidRegExp: {
          message: '^Der eingegebene reguläre Ausdruck ist nicht gültig.',
        },
      },
    };
  }

  public clone(): Domain {
    return RegExpValidation.create(JSON.parse(JSON.stringify(this)));
  }

  public toRegExp(): string {
    return this.value;
  }
}

export default RegExpValidation;
