import ExtendableError from '@/types/ExtendableError';

export class UnauthorizedRequestError extends ExtendableError {}

export interface Response {
  status: number;
  statusText: string;
}

const errorWithResponseAttached = (
  errorClass: new (message: string) => Error,
  response: Response,
) => {
  const error: any = new errorClass(response.statusText);
  error.response = response;
  throw error;
};

export default (response: Response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }

  if (response.status === 401) {
    throw errorWithResponseAttached(UnauthorizedRequestError, response);
  } else {
    throw errorWithResponseAttached(Error, response);
  }
};
