import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import Category from '@/domains/category/Category';
import MinimalArticleVariant from '@/domains/article/MinimalArticleVariant';

export interface CategoryState {
  categories: NormalizedData<Category>;
  listInfo: ListInfo;
  listVariants: NormalizedData<MinimalArticleVariant>;
  variantListInfo: ListInfo;
}

const state: CategoryState = {
  categories: {},
  listVariants: {},
  listInfo: ListInfo.create(),
  variantListInfo: ListInfo.create(),
};

export default state;
