import { MutationTree } from 'vuex';
import { UiState } from './state';
import PopupPayload from '@/types/PopupPayload';
import ToastNotificationPayload from '@/types/ToastNotificationPayload';

const addPopup = (state: UiState, popup: PopupPayload) => {
  state.popups = [...state.popups, popup];
};

const removeLastPopup = (state: UiState) => {
  state.popups.splice(-1);
};

const addToastNotification = (
  state: UiState,
  toastNotification: ToastNotificationPayload
) => {
  state.toastNotifications = [...state.toastNotifications, toastNotification];
};

const removeToastNotification = (state: UiState, id: string) => {
  state.toastNotifications = state.toastNotifications.filter((notification) => {
    return notification.id !== id;
  });
};

const addRequestIdToSpool = (state: UiState, requestId: string) => {
  state.spoolRequestIds = [...state.spoolRequestIds, requestId];
};

const removeRequestIdFromSpool = (state: UiState, requestId: string) => {
  const index = state.spoolRequestIds.findIndex(
    (savedRequestId) => savedRequestId === requestId
  );
  if (index >= 0) {
    state.spoolRequestIds.splice(index, 1);
  }
};

const clearSpool = (state: UiState) => {
  state.spoolRequestIds = [];
};

const requestReload = (state: UiState) => {
  state.requestedReload = true;
};

const setIsPageContentLoading = (state: UiState, isLoading: boolean) => {
  state.isPageContentLoading = isLoading;
};

const setSidebarOpenItem = (state: UiState, openItemName: string) => {
  state.sidebarOpenItem = openItemName;
};

const setIsRequestingDataLossConfirm = (
  state: UiState,
  isRequestingDataLossConfirm: boolean
) => {
  state.isRequestingDataLossConfirm = isRequestingDataLossConfirm;
};

const mutations: MutationTree<UiState> = {
  addPopup,
  removeLastPopup,
  addRequestIdToSpool,
  removeRequestIdFromSpool,
  clearSpool,
  requestReload,
  setIsPageContentLoading,
  setSidebarOpenItem,
  addToastNotification,
  removeToastNotification,
  setIsRequestingDataLossConfirm,
};

export default mutations;
