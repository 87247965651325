import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { OrderState } from '../state';
import fetchOrderList from './fetchOrderList';
import fetchOrder from './fetchOrder';
import cancelOrder from './cancelOrder';
import verifyOrder from './verifyOrder';
import parkOrder from './parkOrder';
import filterStatusList from './filterStatusList';
import fetchVerificationDocument from './fetchVerificationDocument';

const actions: ActionTree<OrderState, RootState> = {
  fetchOrderList,
  fetchOrder,
  cancelOrder,
  verifyOrder,
  parkOrder,
  filterStatusList,
  fetchVerificationDocument,
};
export default actions;
