import UiAction from '../UiAction';
import ToastNotificationPayload from '@/types/ToastNotificationPayload';
import { v4 as uuid } from 'uuid';

const addToastNotification: UiAction = (
  { commit },
  payload: ToastNotificationPayload,
): string => {
  const id: string = uuid();
  payload.id = id;

  commit('addToastNotification', payload);

  return id;
};

export default addToastNotification;
