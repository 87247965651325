import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import Message from '@/domains/message/Message';
import MinimalMessage from '@/domains/message/MinimalMessage';

export interface MessageState {
  minimalMessages: NormalizedData<MinimalMessage>;
  minimalMessagesAdmin: NormalizedData<MinimalMessage>;
  listInfo: ListInfo;
  handleMessage: Message;
}

const state: MessageState = {
  minimalMessages: {},
  minimalMessagesAdmin: {},
  listInfo: ListInfo.create(),
  handleMessage: undefined,
};

export default state;
