import Domain from '@/types/Domain';

class Category extends Domain {
  public static createAll(
    entries: any[],
  ): Category[] {
    return entries.map(Category.create);
  }

  public static createDefault(): Category {
    return Category.create({
      name: '',
      shortcut: '',
    });
  }

  public static create(
    data: any,
  ): Category {
    return new Category(
      data.id,
      data.name,
      data.shortcut,
      data.isAttached,
    );
  }
  // @ts-ignore
  public id: string;
  public name: string;
  public shortcut: string;
  public isAttached?: boolean;

  private constructor(id: string, name: string, shortcut: string, isAttached?: boolean) {
    super();
    this.id = id;
    this.name = name;
    this.shortcut = shortcut;
    if (isAttached !== undefined) {
      this.isAttached = isAttached;
    }
  }

  public clone(): Category {
    const category = Category.create(this);
    category.errors = JSON.parse(JSON.stringify(this.errors));

    return category;
  }

  public constraints(): {} {
    return {
      name: {
        presence: {
          message: '^Name darf nicht leer sein',
        },
        format: {
          pattern: /^[^#"'&$,;|=]{2,100}$/,
          message: '^Name muss zwischen 2 und 100 Zeichen lang sein. Folgende Zeichen sind NICHT zulässig: #"\'&$,;|=',
        },
      },
      shortcut: {
        presence: {
          message: '^Shortcut darf nicht leer sein',
        },
        format: {
          pattern: /^[A-Z]{2}$/,
          message: '^Shortcut muss aus genau 2 Großbuchstaben bestehen.',
        },
      },
    };
  }

  // TODO: change this to a model prop
  public isMandantSpecific(): boolean {
    return this.shortcut === 'AW';
  }
}

export default Category;
