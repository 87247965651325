import { fetchFile } from '@/utils/doRequest';
import FetchFn from '@/types/FetchFn';

export interface FetchVerificationDocumentPayload {
  fetch: FetchFn;
  orderId: string;
  fileId: string;
}

const fetchVerificationDocument = async (
  { commit, dispatch, rootGetters },
  payload: FetchVerificationDocumentPayload,
) => {
  return new Promise<any>((resolve) => {
    const { fetch, orderId, fileId } = payload;

    let url;
    if (rootGetters.isMandant) {
      url = `/my/file/order/${orderId}/verification/document/${fileId}`;
    } else {
      url = `/file/order/${orderId}/verification/document/${fileId}`;
    }

    fetchFile({ commit, dispatch }, fetch, url)
      .then(resolve);
  });
};

export default fetchVerificationDocument;
