import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree } from 'vuex';
import modules from './modules';

Vue.use(Vuex);

/* tslint:disable-next-line:no-empty-interface */
export interface RootState {}

const state: RootState = {};
const getters: GetterTree<RootState, RootState> = {};
const mutations: MutationTree<RootState> = {};
const actions: ActionTree<RootState, RootState> = {};

export default new Vuex.Store<RootState>({
  state,
  getters,
  mutations,
  actions,
  modules,
});
