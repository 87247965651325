import { GetterTree } from 'vuex';
import { CodeState } from './state';
import { ListInfo } from '@/types/ListInfo';
import Code from '@/domains/code/Code';
import NormalizedData from '@/types/NormalizedData';

type CodeGetter = (id: string) => Code;

export const getCode = (state: CodeState): CodeGetter => (id: string): Code => {
  return state.codes[id];
};

export const getCodeList = (state: CodeState): NormalizedData<Code> => {
  return state.codes;
};

export const getCodeListInfo = (state: CodeState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<CodeState, any> = {
  getCode,
  getCodeList,
  getCodeListInfo,
};

export default getters;
