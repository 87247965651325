import { ListFilter } from '@/types/ListFilter';

export default (filters: ListFilter[], fieldNamesOnly?: boolean): string => {
  let result = '';
  if (filters && filters.length > 0) {
    filters.forEach((filter: ListFilter) => {
      if (filter.query) {
        if (fieldNamesOnly) {
          result += `&${filter.field}=${encodeURIComponent(filter.query)}`;
        } else {
          result += `&filter[${filter.field}]=${encodeURIComponent(filter.query)}`;
        }
      }
    });
  }
  return result;
};
