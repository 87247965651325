import Domain from '@/types/Domain';

interface Props {
  colorName: string;
  colorCode: string;
}

class Color extends Domain implements Props {
  public static createAll(data: any[]): Color[] {
    return data.map(Color.create);
  }

  public static create(data: any): Color {
    return new Color(
      data.colorName,
      data.colorCode,
    );
  }

  private constructor(
    public colorName: string,
    public colorCode: string,
  ) {
    super();
    this.colorName = colorName;
    this.colorCode = colorCode;
  }

  public clone(): Color {
    return Color.create(JSON.parse(JSON.stringify(this)));
  }

  public constraints(): {} {
    return {
      colorName: {
        presence: {
          message: '^Name ist ein Pflichtfeld.',
        },
      },
      colorCode: {
        presence: {
          message: '^Code ist ein Pflichtfeld.',
        },
        format: {
          pattern: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
          message: '^Der Code muss im Hex-Format sein',
        },
      },
    };
  }
}

export default Color;
