import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import OrderStatusVerified from '@/domains/order/OrderStatusVerified';

const verifyOrder: PushSingleAction<OrderStatusVerified> = async (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<OrderStatusVerified>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/order/${id}/status/verified`;
  } else {
    url = `/order/${id}/status/verified`;
  }

  return await pushSingle({ commit, dispatch }, 'PATCH', pushPayload, {
    url,
    mutation: undefined,
  });
};

export default verifyOrder;
