import fetchList, { FetchListPayload, FetchListAction } from '@/utils/api-platform/fetchList';
import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';

const fetchCodeDispatchList: FetchListAction<CodeDispatch> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload,
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/code-dispatches`,
    domainParser: CodeDispatch.create,
    listMutation: 'setCodeDispatches',
    listInfoMutation: 'setCodeDispatchListInfo',
  });
};

export default fetchCodeDispatchList;
