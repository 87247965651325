import Domain from '@/types/Domain';
import Address from '../common/Address';

const notWhitespacesOnlyRegExp = /^(?!\s*$).+/;

class MinimalMandant extends Domain {
  public static createAll(mandants: any[]): MinimalMandant[] {
    return mandants.map(MinimalMandant.create);
  }

  public static create(data: any): MinimalMandant {
    return new MinimalMandant(
      data.id,
      typeof data.active === 'boolean' ? (data.active = true as boolean) : !!parseInt(data.active),
      data.code,
      data.name,
      Address.create({
        country: data.address.country,
        state: data.address.state,
        city: data.address.city,
        zipCode: data.address.zipCode,
        street: {
          name: data.address.street.name,
          number: data.address.street.number,
        },
      }),
    );
  }

  // @ts-ignore
  public id: string;
  public active: boolean;
  public code: string;
  public name: string;
  public address: Address;

  private constructor(
    id: string,
    active: boolean,
    code: string,
    name: string,
    address: Address,
  ) {
    super();
    this.id = id;
    this.active = active;
    this.code = code;
    this.name = name;
    this.address = address;
  }

  public constraints(): {} {
    const addressFields = [
      'address.country',
      'address.state',
      'address.city',
      'address.zipCode',
      'address.street.name',
      'address.street.number',
    ];

    const addressValidations: { [key: string]: {} } = {};
    addressFields.forEach((field) => {
      addressValidations[field] = {
        presence: field === 'address.state' ? false : true,
        format: notWhitespacesOnlyRegExp,
      };
    });

    return {
      id: {
        presence: true,
        format: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
      },
      code: {
        presence: true,
        format: /^[A-Z]{3}$/,
      },
      name: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      ...addressValidations,
    };
  }

  public clone(): Domain {
    return MinimalMandant.create(JSON.parse(JSON.stringify(this)));
  }
}

export default MinimalMandant;
