import Enum from './Enum';

interface Props {
  label: string;
  colorCode: string;
}

export default class CodeRedemptionStatus extends Enum implements Props {
  public static readonly REDEEMED = new CodeRedemptionStatus('APPROVED', 'Freigegeben', '#00c896');
  public static readonly NOT_REDEEMED = new CodeRedemptionStatus('PROCESSING', 'In Bearbeitung', '#77e1c7');
  public static readonly CANCELLED = new CodeRedemptionStatus('REJECTED', 'Abgelehnt', '#e2e3e3');

  public static readonly ALL = [
    CodeRedemptionStatus.REDEEMED,
    CodeRedemptionStatus.NOT_REDEEMED,
    CodeRedemptionStatus.CANCELLED,
  ];

  public static getById(id: string): CodeRedemptionStatus {
    return CodeRedemptionStatus.ALL.filter((status) => status.id === id)[0];
  }

  private constructor(
    public id: string,
    public label: string,
    public colorCode: string,
  ) {
    super();
  }
}
