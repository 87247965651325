import { ActionTree } from 'vuex';
import { HighlightState } from '../state';
import { RootState } from '@/store';
import createHighlights from './createHighlights';
import fetchArticleForHighlightsList from './fetchArticleForHighlightsList';
import fetchHighlightsList from './fetchHighlightsList';


const actions: ActionTree<HighlightState, RootState> = {
  createHighlights,
  fetchArticleForHighlightsList,
  fetchHighlightsList,
};

export default actions;
