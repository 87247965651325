import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import UserGroup from '@/domains/userGroup/UserGroup';
import MinimalUser from '@/domains/mandant/MinimalUser';


export interface UserGroupState {
  userGroups: NormalizedData<UserGroup>;
  listInfo: ListInfo;
  users: NormalizedData<MinimalUser>;
  userListInfo: ListInfo;
  assignableOwners: NormalizedData<MinimalUser>;
  assignableOwnerListInfo: ListInfo;
}

const state: UserGroupState = {
  userGroups: {},
  listInfo: ListInfo.create(),
  users: {},
  userListInfo: ListInfo.create(),
  assignableOwners: {},
  assignableOwnerListInfo: ListInfo.create(),
};

export default state;
