import { MutationTree } from 'vuex';
import { PortfolioState } from './state';
import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import PortfolioArticle from '@/domains/portfolio/PortfolioArticle';
import MinimalPortfolioArticle from '@/domains/portfolio/MinimalPortfolioArticle';
import TagArticleCount from '@/domains/tag/TagArticleCount';

export const setPortfolioArticles = (
  state: PortfolioState,
  portfolioArticles: NormalizedData<PortfolioArticle>,
): void => {
  state.portfolioArticles = {
    ...portfolioArticles,
  };
};

export const setMinimalPortfolioArticles = (
  state: PortfolioState,
  minimalPortfolioArticles: NormalizedData<MinimalPortfolioArticle>,
): void => {
  state.minimalPortfolioArticles = {
    ...minimalPortfolioArticles,
  };
};

export const setPortfolioArticleListInfo = (
  state: PortfolioState,
  info: ListInfo,
): void => {
  state.portfolioArticleListInfo = info;
};

export const setTagArticleCounts = (
  state: PortfolioState,
  tagArticleCounts: NormalizedData<TagArticleCount>,
): void => {
  state.tagArticleCounts = tagArticleCounts;
};

export const setTagArticleCountListInfo = (
  state: PortfolioState,
  info: ListInfo,
): void => {
  state.tagArticleCountListInfo = info;
};

const mutations: MutationTree<PortfolioState> = {
  setPortfolioArticles,
  setMinimalPortfolioArticles,
  setPortfolioArticleListInfo,
  setTagArticleCounts,
  setTagArticleCountListInfo,
};

export default mutations;
