import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/fetchSingle';
import CampaignReport from '@/domains/report/CampaignReport';

const fetchCampaignReport: FetchSingleAction<CampaignReport> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload,
) => {
  const {
    id,
  } = payload;

  let url;
  if (rootGetters.isMandant) {
    url = `/my/reporting/campaign/${id}`;
  } else {
    const simulatedMandantId = rootGetters.getSimulatedMandantId;
    url = `/reporting/${simulatedMandantId}/campaign/${id}`;
  }

  return await fetchSingle({ commit, dispatch }, payload, {
    url,
    domainParser: CampaignReport.create,
    mutation: 'addCampaignReports',
  });
};

export default fetchCampaignReport;
