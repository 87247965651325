import FetchFn from '@/types/FetchFn';
import { get } from '@/utils/api-platform/doRequest';
import ListResponse from '@/types/ListResponse';
import getListResponseEntries from '@/utils/api-platform/getListResponseEntries';
import normalizeData from '@/utils/normalizeData';
import NormalizedData from '@/types/NormalizedData';
import Domain from '@/types/Domain';
import { ListFilter } from '@/types/ListFilter';
import getUrlParamsForFilters from '@/utils/api-platform/getUrlParamsForFilters';

export interface FetchUnpaginatedListPayload {
  fetch: FetchFn;
  filters?: ListFilter[];
}

export interface ExtendedFetchUnpaginatedListPayload<T extends Domain> {
  url: string;
  domainParser: (data: any) => T;
  listMutation?: string;
}

export type FetchUnpaginatedListAction<T extends Domain> = (
  {
    commit,
    dispatch,
    rootGetters,
  },
  payload: FetchUnpaginatedListPayload,
) => Promise<NormalizedData<T>>;

const fetchUnpaginatedList = async<T extends Domain> (
  {
    commit,
    dispatch,
  },
  {
    fetch,
    filters,
  }: FetchUnpaginatedListPayload,
  {
    url,
    domainParser,
    listMutation,
  }: ExtendedFetchUnpaginatedListPayload<T>,
): Promise<NormalizedData<T>> => {
  let response: ListResponse | any[];

  const uri = filters ? `${url}?${getUrlParamsForFilters(filters, true)}` : url;

  try {
    response = await get<ListResponse | any[]>({ commit, dispatch }, fetch, uri);
  } catch (error) {
    console.warn(error);
    throw error;
  }

  const list: T[] = getListResponseEntries(response)
    .map((data) => domainParser(data))
    .filter((domain) => {
      if (!domain.validate()) {
        console.error('Domain is erroneous and won\'t be persisted', domain);
        return false;
      } else {
        return true;
      }
    })
    .map((data) => {
      data.cleanse();
      return data;
    })
  ;

  const normalizedList = normalizeData(list);
  if (listMutation) {
    commit(listMutation, normalizedList);
  }

  // @ts-ignore
  return normalizedList;
};

export default fetchUnpaginatedList;
