import Category from '@/domains/category/Category';
import fetchList, { FetchListPayload, FetchListAction } from '@/utils/fetchList';

const fetchCategoryList: FetchListAction<Category> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let url;
  if (rootGetters.isMandant) {
    url = `/my/category-portfolio`;
  } else {
    const mandantId = rootGetters.getSimulatedMandantId;
    if (mandantId) {
      url = `/mandant/${mandantId}/portfolio`;
    } else {
      url = `/category-portfolio`;
    }
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: Category.create,
    listMutation: 'setCategories',
    listInfoMutation: 'setCategoryListInfo',
  });
};

export default fetchCategoryList;
