import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import User from '@/domains/mandant/User';

const editUser: PushSingleAction<User> = async (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<User>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  let url;
  if (rootGetters.isMandant) {
    url = `/my/user/${id}`;
  } else {
    url = `/user/${id}`;
  }

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'PATCH', pushPayload, {
    url,
    mutation: 'addUsers',
  });
};

export default editUser;
