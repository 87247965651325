import Code from '@/domains/code/Code';
import fetchList, { FetchListPayload, FetchListAction } from '@/utils/fetchList';

export interface FetchCodeListPayload extends FetchListPayload {
  campaignId: string;
}

const fetchCodeList: FetchListAction<Code> = async (
  { commit, dispatch, rootGetters },
  fetchCodeListPayload: FetchCodeListPayload,
) => {
  const {
    fetch,
    page,
    limit,
    filters,
    sorts,
    campaignId,
  } = fetchCodeListPayload;
  const fetchListPayload: FetchListPayload = {
    fetch,
    page,
    limit,
    filters,
    sorts,
  };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/campaign/${campaignId}/code`;
  } else {
    url = `/campaign/${campaignId}/code`;
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: Code.create,
    listMutation: 'setCodes',
    listInfoMutation: 'setCodeListInfo',
  });
};

export default fetchCodeList;
