import Domain from '@/types/Domain';

interface Placeholder {
  name: string;
  varName: string;
  value: string;
  mandatory: boolean;
}

interface Props {
  listOfVars: Placeholder[];
  templateText: string;
}

class TextTemplate extends Domain implements Props {

  public static createAll(campaigns: any[]): TextTemplate[] {
    return campaigns.map(TextTemplate.create);
  }

  public static create(data: {listOfVars, templateText}): TextTemplate {
    return new TextTemplate(
      data.listOfVars,
      data.templateText,
    );
  }

  private constructor(
    public listOfVars: Placeholder[],
    public templateText: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): TextTemplate {
    return TextTemplate.create(JSON.parse(JSON.stringify(this)));
  }

  public getRenderedText(): string {
    const renderedText: string = this.renderTextTemplate();
    return renderedText;
  }

  public getVariables(): Placeholder[] {
    return this.listOfVars;
  }

  public updatePlaceHolder(name: string, options?: any) {
    const nodeToUpdate = this.listOfVars.filter((item: Placeholder) => {
      return item.varName === name;
    });
    for (const [key, value] of Object.entries(options)) {
      if (nodeToUpdate.length) nodeToUpdate[0][key] = value;
    }
  }

  public getMissingVars(): string[] {
    const missingVars = this.listOfVars.map((textVariable) => {
      if (!this.templateText.includes(textVariable.varName)) {
        return textVariable.varName;
      }
    });
    return missingVars.filter((mvar) => {
      return mvar !== undefined;
    });
  }

  private renderTextTemplate(): string {
    let input: string = this.templateText?.slice();
    this.listOfVars?.map((mvar) => {
      const dynRegExp = new RegExp('(\\' + mvar.varName + ')', 'g');
      input = input?.replace(dynRegExp, mvar.value);
    });
    return input;
  }

}

export default TextTemplate;
