import { MutationTree } from 'vuex';
import { ManufacturerState } from './state';
import Manufacturer from '@/domains/manufacturer/Manufacturer';
import NormalizedData from '@/types/NormalizedData';
import { ListInfo } from '@/types/ListInfo';


export const setManufacturers = (
  state: ManufacturerState,
  manufacturers: NormalizedData<Manufacturer>,
): void => {
  state.manufacturers = manufacturers;
};

export const addManufacturers = (
  state: ManufacturerState,
  manufacturers: NormalizedData<Manufacturer>,
): void => {
  state.manufacturers = {
    ...state.manufacturers,
    ...manufacturers,
  };
};

export const setManufacturerListInfo = (state: ManufacturerState, info: ListInfo): void => {
  state.listInfo = info;
};

const mutations: MutationTree<ManufacturerState> = {
  setManufacturers,
  addManufacturers,
  setManufacturerListInfo,
};

export default mutations;
