import Enum from './Enum';

interface Props {
  name: string;
  defaultRoute: string;
  // a list of all accessible route and sidebar item names
  routes: string[];
  // a list of accessible actions without an associated route
  actions?: string[];
}

export default class Role extends Enum implements Props {
  // tslint:disable: member-ordering
  private static readonly LOGGED_IN_ROUTES = [
    'myProfile',
    'imprint',
    'terms-and-conditions',
    'privacy-terms',
    '404',
  ];

  public static readonly SKON_SUPER_ADMIN = new Role(
    'ROLE_SKON_SUPER_ADMIN',
    'S-KON Super-Admin',
    'dashboard',
    [
      'dashboard',
      'order-list',
      'order-edit',
      'mandant',
      'mandant-list',
      'mandant-create',
      'mandant-edit',
      'user',
      'user-list',
      'user-create',
      'user-edit',
      'article',
      'article-list',
      'article-create',
      'article-edit',
      'portfolio',
      'portfolio-create',
      'highlights-edit',
      'portfolio-overview',
      'portfolio-article-list',
      'portfolio-article-detail',
      'marketing',
      'messages',
      'messages-editor-overview',
      'messages-editor-create',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
      'campaign-list',
      'campaign-edit',
      'campaign-verification-edit',
      'campaign-additional-information-edit',
      'campaign-create',
      'campaign-article',
      'code-list',
      'manufacturers',
      'reports',
      'content',
      'system',
      'tags',
      'cockpit',
      'sales',
      'sales-usergroup-create',
      'sales-usergroup-edit',
      'sales-usergroup-list',
      'sales-code-dispatch',
      'sales-code-dispatch-detail',
      'sales-code-dispatch-form-detail',
      'sales-code-dispatch-list',
    ].concat(Role.LOGGED_IN_ROUTES),
    [
      'sales-usergroup-edit-basedata',
      'sales-usergroup-delete',
      'sales-usergroup-edit-users',
      'sales-code-dispatch-update',
      'campaign-edit-active-status',
    ],
  );
  public static readonly SKON_ADMIN = new Role(
    'ROLE_SKON_ADMIN',
    'S-KON Admin',
    'dashboard',
    [
      'dashboard',
      'order-list',
      'order-edit',
      'mandant',
      'mandant-list',
      'mandant-create',
      'mandant-edit',
      'user',
      'user-list',
      'user-create',
      'user-edit',
      'article',
      'article-list',
      'article-create',
      'article-edit',
      'portfolio',
      'portfolio-create',
      'highlights-edit',
      'portfolio-overview',
      'portfolio-article-list',
      'portfolio-article-detail',
      'marketing',
      'messages',
      'messages-editor-overview',
      'messages-editor-create',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
      'campaign-list',
      'campaign-edit',
      'campaign-verification-edit',
      'campaign-additional-information-edit',
      'campaign-create',
      'campaign-article',
      'code-list',
      'manufacturers',
      'reports',
      'content',
      'system',
      'tags',
      'cockpit',
      'sales',
      'sales-usergroup-create',
      'sales-usergroup-edit',
      'sales-usergroup-list',
      'sales-code-dispatch',
      'sales-code-dispatch-detail',
      'sales-code-dispatch-form-detail',
      'sales-code-dispatch-list',
    ].concat(Role.LOGGED_IN_ROUTES),
    [
      'sales-usergroup-edit-basedata',
      'sales-usergroup-delete',
      'sales-usergroup-edit-users',
      'sales-code-dispatch-update',
      'campaign-edit-active-status',
    ],
  );
  public static readonly SKON_PRODUCT_MANAGEMENT = new Role(
    'ROLE_SKON_PRODUCT_MANAGER',
    'S-KON Produktmanagement',
    'dashboard',
    [
      'dashboard',
      'article',
      'article-list',
      'article-create',
      'article-edit',
      'portfolio',
      'portfolio-create',
      'highlights-edit',
      'portfolio-overview',
      'portfolio-article-list',
      'portfolio-article-detail',
      'marketing',
      'messages',
      'messages-editor-overview',
      'messages-editor-create',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
      'campaign-list',
      'campaign-edit',
      'campaign-verification-edit',
      'campaign-additional-information-edit',
      'campaign-create',
      'campaign-article',
      'manufacturers',
      'reports',
      'content',
      'system',
      'tags',
      'cockpit',
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly SKON_AFTER_SALES = new Role(
    'ROLE_SKON_AFTER_SALES',
    'S-KON After Sales',
    'dashboard',
    [
      'dashboard',
      'order-list',
      'order-edit',
      'marketing',
      'campaign-list',
      'campaign-edit',
      'campaign-verification-edit',
      'campaign-additional-information-edit',
      'campaign-article',
      'code-list',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly MANDANT_USER = new Role(
    'ROLE_MANDANT_USER',
    'Benutzerprofil',
    'myProfile',
    [
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly MANDANT_CAMPAIGN_READ = new Role(
    'ROLE_MANDANT_CAMPAIGN_READ',
    'Kampagnen ansehen',
    'dashboard',
    [
      'dashboard',
      'marketing',
      'campaign-list',
      'campaign-edit',
      'campaign-article',
      'code-list',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly MANDANT_CAMPAIGN_WRITE = new Role(
    'ROLE_MANDANT_CAMPAIGN_WRITE',
    'Kampagnen bearbeiten',
    'dashboard',
    [
      'dashboard',
      'marketing',
      'campaign-edit',
      'campaign-create',
      'campaign-verification-edit',
      'campaign-additional-information-edit',
      'campaign-article',
      'portfolio',
      'portfolio-overview',
      'portfolio-article-list',
      'portfolio-article-detail',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ].concat(Role.LOGGED_IN_ROUTES),
    [
      'campaign-edit-active-status',
    ],
  );
  public static readonly MANDANT_ORDER_READ = new Role(
    'ROLE_MANDANT_ORDER_READ',
    'Aufträge ansehen',
    'order-list',
    [
      'order-list',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly MANDANT_ORDER_WRITE = new Role(
    'ROLE_MANDANT_ORDER_WRITE',
    'Aufträge bearbeiten',
    'myProfile',
    [
      'order-edit',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly MANDANT_USER_READ = new Role(
    'ROLE_MANDANT_USER_READ',
    'Benutzer ansehen',
    'user-list',
    [
      'user',
      'user-list',
      'user-edit',
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly MANDANT_USER_WRITE = new Role(
    'ROLE_MANDANT_USER_WRITE',
    'Benutzer bearbeiten',
    'user-create',
    [
      'user',
      'user-create',
      'user-edit',
      'messages',
      'messages-editor-overview',
      'messages-editor-create',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ].concat(Role.LOGGED_IN_ROUTES),
  );
  public static readonly MANDANT_SALES_MANAGER = new Role(
    'ROLE_MANDANT_SALES_MANAGER',
    'Sales-Manager',
    'sales-usergroup-list',
    [
      'user',
      'user-list',
      'user-create',
      'user-edit',
      'sales',
      'sales-usergroup-edit',
      'sales-usergroup-list',
      'sales-code-dispatch',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ],
    [
      'sales-usergroup-edit-users',
    ],
  );
  public static readonly MANDANT_SALES_ADMIN = new Role(
    'ROLE_MANDANT_SALES_ADMIN',
    'Sales-Admin',
    'sales-usergroup-list',
    [
      'user',
      'user-list',
      'user-create',
      'user-edit',
      'sales',
      'sales-usergroup-create',
      'sales-usergroup-edit',
      'sales-usergroup-list',
      'sales-code-dispatch',
      'sales-code-dispatch-detail',
      'sales-code-dispatch-form-detail',
      'sales-code-dispatch-list',
      'messages',
      'messages-editor-overview',
      'messages-editor-create',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ],
    [
      'sales-usergroup-edit-basedata',
      'sales-usergroup-delete',
      'sales-code-dispatch-update',
    ],
  );
  public static readonly MANDANT_SALES_USER = new Role(
    'ROLE_MANDANT_SALES_USER',
    'Sales-Agent',
    'sales-code-dispatch',
    [
      'sales',
      'sales-code-dispatch',
      'sales-code-dispatch-form-detail',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ],
  );
  public static readonly MANDANT_CODE_DISPATCH_READ = new Role(
    'ROLE_MANDANT_CODE_DISPATCH_READ',
    'Versendete Codes ansehen',
    'sales-code-dispatch',
    [
      'sales',
      'sales-code-dispatch',
      'sales-code-dispatch-detail',
      'sales-code-dispatch-form-detail',
      'sales-code-dispatch-list',
      'messages',
      'messages-reader-overview-no-id',
      'messages-reader-overview',
    ],
  );
  public static readonly MANDANT_CODE_DISPATCH_WRITE = new Role(
    'ROLE_MANDANT_CODE_DISPATCH_WRITE',
    'Codes erneut versenden',
    'sales-code-dispatch',
    [
      'sales',
    ],
    [
      'sales-code-dispatch-update',
    ],
  );
  public static readonly GUEST = new Role(
    'IS_AUTHENTICATED_ANONYMOUSLY',
    'Gast',
    'login',
    [
      'login',
      'password-reset',
      'password-reset-requested',
      'password-reset-new-password',
      'password-reset-success',
      'imprint',
      'terms-and-conditions',
      'privacy-terms',
    ],
  );

  public static readonly ALL = [
    Role.SKON_SUPER_ADMIN,
    Role.SKON_ADMIN,
    Role.SKON_PRODUCT_MANAGEMENT,
    Role.SKON_AFTER_SALES,
    Role.MANDANT_USER,
    Role.MANDANT_CAMPAIGN_READ,
    Role.MANDANT_CAMPAIGN_WRITE,
    Role.MANDANT_ORDER_READ,
    Role.MANDANT_ORDER_WRITE,
    Role.MANDANT_USER_READ,
    Role.MANDANT_USER_WRITE,
    Role.MANDANT_SALES_MANAGER,
    Role.MANDANT_SALES_ADMIN,
    Role.MANDANT_SALES_USER,
    Role.MANDANT_CODE_DISPATCH_READ,
    Role.MANDANT_CODE_DISPATCH_WRITE,
    Role.GUEST,
  ];

  public static readonly LOGGED_IN = [
    Role.SKON_SUPER_ADMIN,
    Role.SKON_ADMIN,
    Role.SKON_PRODUCT_MANAGEMENT,
    Role.SKON_AFTER_SALES,
    Role.MANDANT_USER,
    Role.MANDANT_CAMPAIGN_READ,
    Role.MANDANT_CAMPAIGN_WRITE,
    Role.MANDANT_ORDER_READ,
    Role.MANDANT_ORDER_WRITE,
    Role.MANDANT_USER_READ,
    Role.MANDANT_USER_WRITE,
    Role.MANDANT_SALES_MANAGER,
    Role.MANDANT_SALES_ADMIN,
    Role.MANDANT_SALES_USER,
    Role.MANDANT_CODE_DISPATCH_READ,
    Role.MANDANT_CODE_DISPATCH_WRITE,
  ];

  public static readonly IS_SKON_MEMBER = [
    Role.SKON_SUPER_ADMIN,
    Role.SKON_ADMIN,
    Role.SKON_PRODUCT_MANAGEMENT,
    Role.SKON_AFTER_SALES,
  ];

  public static readonly CAN_SIMULATE_MANDANT = [
    ...Role.IS_SKON_MEMBER,
  ];

  public static getById(id: string) {
    return Role.ALL.filter((role) => role.id === id)[0];
  }

  private constructor(
    public id: string,
    public name: string,
    public defaultRoute: string,
    public routes: string[],
    public actions?: string[],
  ) {
    super();
  }

  public canSimulateMandant(): boolean {
    return Role.CAN_SIMULATE_MANDANT.filter((role) => role.equals(this)).length > 0;
  }

  public isSkonMember(): boolean {
    return Role.IS_SKON_MEMBER.filter((role) => role.equals(this)).length > 0;
  }

  public isMandant(): boolean {
    return this.id.startsWith('ROLE_MANDANT_');
  }
}
