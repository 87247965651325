import { CategoryState } from '@/store/modules/category/state';
import { ListInfo } from '@/types/ListInfo';

const setCategoryListInfo = (
  state: CategoryState,
  info: ListInfo,
): void => {
  state.listInfo = info;
};

export default setCategoryListInfo;
