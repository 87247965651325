import { MutationTree } from 'vuex';
import MandantModule from '@/domains/mandant/MandantModule';
import { AuthState } from './state';


export const setIsUserLoggedIn = (
  state: AuthState,
  isLogged: boolean,
): void => {
  state.isUserLoggedIn = isLogged;
};

export const setRoleIds = (
  state: AuthState,
  roleIds: string[],
): void => {
  state.roleIds = roleIds;
};

export const setAssignableRoleIds = (
  state: AuthState,
  assignableRoleIds: string[],
): void => {
  state.assignableRoleIds = assignableRoleIds;
};

export const setNotifiableRoleIds = (
  state: AuthState,
  notifiableRoleIds: [],
): void => {
  state.notifiableRoleIds = notifiableRoleIds;
};

export const setMandantId = (state: AuthState, mandantId: string): void => {
  state.mandantId = mandantId;
};

export const setMandantName = (state: AuthState, mandantName: string): void => {
  state.mandantName = mandantName;
};

export const setMandantShopHost = (state: AuthState, mandantShopHost: string): void => {
  state.mandantShopHost = mandantShopHost;
};

export const setSimulatedMandantId = (state: AuthState, simulatedMandantId: string): void => {
  state.simulatedMandantId = simulatedMandantId;
};

export const setLogoutTimestamp = (state: AuthState, logoutTimestamp: number): void => {
  state.logoutTimestamp = logoutTimestamp;
};

export const setRefreshToken = (state: AuthState, refreshToken: string): void => {
  state.refreshToken = refreshToken;
};

export const setMandantModules = (
  state: AuthState,
  mandantModules: MandantModule,
): void => {
  state.modules = mandantModules;
};

const mutations: MutationTree<AuthState> = {
  setIsUserLoggedIn,
  setRoleIds,
  setAssignableRoleIds,
  setNotifiableRoleIds,
  setMandantId,
  setMandantName,
  setMandantShopHost,
  setSimulatedMandantId,
  setMandantModules,
  setLogoutTimestamp,
  setRefreshToken,
};

export default mutations;
