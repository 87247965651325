import Domain from '@/types/Domain';

class AddressStreet extends Domain {
  public static createAll(data: any[]): AddressStreet[] {
    return data.map(AddressStreet.create);
  }

  public static create(data: any): AddressStreet {
    return new AddressStreet(
      data.name,
      data.number,
    );
  }

  public name: string;
  public number: string;

  private constructor(
    name: string,
    // tslint:disable-next-line: variable-name
    number: string,
  ) {
    super();
    this.name = name;
    this.number = number;
  }

  public clone(): AddressStreet {
    return AddressStreet.create(JSON.parse(JSON.stringify(this)));
  }

  public constraints(): {} {
    return {
      name: {
        presence: {
          message: '^Straße ist ein Pflichtfeld.',
        },
      },
      number: {
        presence: {
          message: '^Hausnummer ist ein Pflichtfeld.',
        },
      },
    };
  }
}

export default AddressStreet;
