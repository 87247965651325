import Domain from '@/types/Domain';
import CampaignCustomerField from '@/domains/campaign/CampaignCustomerField';
import VerificationDocument from '@/domains/order/VerificationDocument';
import Campaign from '@/domains/campaign/Campaign';
import CampaignAdditionalInfo from '@/domains/campaign/CampaignAdditionalInfo';
import SalesCampaign from '@/domains/campaign/SalesCampaign';
import CampaignAccessProperties from '@/domains/campaign/CampaignAccessProperties';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';


interface Props {
  tokenDistributionType?: string;
  active?: boolean;
  endDate?: Date;
  customerFields?: CampaignCustomerField[];
  fileUploadHint?: string;
  costCenter?: string;
  verificationInstruction?: string;
  verificationDocuments?: File | VerificationDocument;
  groups: string[];
  externalId?: string;
  salesProperties: SalesCampaign;
  accessProperties?: CampaignAccessProperties;
  approvalMail?: string;
  codeProperties?: CampaignCodeProperties;
}

class CampaignEdit extends Domain implements Props {
  public static createAll(campaignEdits: any[]): CampaignEdit[] {
    return campaignEdits.map(CampaignEdit.create);
  }

  public static create(data: any): CampaignEdit {

    if (data.salesProperties) {
      data.salesProperties.customMessagingEnabled = !!data.salesProperties.messagingProperties.email.text.templateText;
    }

    let verificationDocument;
    if (data.verificationDocuments instanceof VerificationDocument) {
      verificationDocument = VerificationDocument.create(data.verificationDocuments);
    } else {
      verificationDocument = data.verificationDocuments;
    }

    let salesProperties;
    if (data.salesProperties instanceof SalesCampaign) {
      salesProperties = SalesCampaign.create(data.salesProperties);
    } else {
      salesProperties = data.salesProperties;
    }

    return new CampaignEdit(
      data.tokenDistributionType,
      data.active,
      data.endDate && new Date(data.endDate),
      CampaignCustomerField.createAll(data.customerFields || []),
      data.fileUploadHint,
      data.costCenter,
      data.verificationInstruction,
      verificationDocument,
      data.additionalInfo,
      data.groups,
      salesProperties,
      data.externalId,
      data.accessProperties,
      data.approvalMail,
      data.verification,
      data.codeProperties,
    );
  }

  public static fromChangedCampaign(
    originalCampaign: Campaign,
    changedCampaign: Campaign,
    verificationExampleDocument?: VerificationDocument,
  ): CampaignEdit {
    let customerFields;
    if (JSON.stringify(changedCampaign.customerFields) !== JSON.stringify(originalCampaign.customerFields)) {
      customerFields = changedCampaign.customerFields;
    }

    let verificationDocuments: File | VerificationDocument = verificationExampleDocument;
    if (!verificationExampleDocument && changedCampaign.verificationDocuments) {
      verificationDocuments = changedCampaign.verificationDocuments;
    }

    if (changedCampaign.additionalInfo.additionalInfoText === null) {
      changedCampaign.additionalInfo = null;
    }

    return this.create({
      tokenDistributionType: changedCampaign.tokenDistributionType,
      active: changedCampaign.active !== originalCampaign.active ? changedCampaign.active : undefined,
      endDate: changedCampaign.endDate.toISOString() !== originalCampaign.endDate.toISOString() ?
        changedCampaign.endDate : undefined,
      customerFields,
      fileUploadHint: changedCampaign.fileUploadHint !== originalCampaign.fileUploadHint ?
        changedCampaign.fileUploadHint : undefined,
      costCenter: changedCampaign.costCenter !== originalCampaign.costCenter ?
        changedCampaign.costCenter : originalCampaign.costCenter,
      verificationInstruction: changedCampaign.verificationInstruction !== originalCampaign.verificationInstruction ?
        changedCampaign.verificationInstruction : undefined,
      verificationDocuments,
      additionalInfo: changedCampaign.additionalInfo,
      groups: changedCampaign.groups,
      externalId: changedCampaign.externalId,
      salesProperties: changedCampaign.salesProperties,
      accessProperties: changedCampaign.accessProperties,
      approvalMail: changedCampaign.approvalMail,
      verification: changedCampaign.verification !== originalCampaign.verification ? changedCampaign.verification :
        undefined,
      codeProperties: changedCampaign.codeProperties,
    });
  }

  private constructor(
    public tokenDistributionType: string,
    public active: boolean,
    public endDate: Date,
    public customerFields: CampaignCustomerField[],
    public fileUploadHint: string,
    public costCenter: string,
    public verificationInstruction: string,
    public verificationDocuments: File | VerificationDocument,
    public additionalInfo: CampaignAdditionalInfo,
    public groups: string[],
    public salesProperties: SalesCampaign,
    public externalId?: string,
    public accessProperties?: CampaignAccessProperties,
    public approvalMail?: string,
    public verification?: string,
    public codeProperties?: CampaignCodeProperties,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): CampaignEdit {
    return CampaignEdit.create(JSON.parse(JSON.stringify(this)));
  }

  public toFormData(): FormData {
    const data = new FormData();
    if (this.accessProperties) {
      if (this.accessProperties.image) {
        if (!Array.isArray(this.accessProperties.image)) {
          data.append('accessProperties[image]', this.accessProperties.image as Blob);
        }
        //accessProperties.customErrorMessage is always mandatory for AccessCampaigns
      } else if (this.accessProperties.image === undefined && this.accessProperties.customErrorMessage) {
        data.append(
          'accessProperties[image]',
          null,
        );
      }
      if (this.accessProperties.displayName) {
        data.append('accessProperties[displayName]', this.accessProperties.displayName);
      }
      if (this.accessProperties.description) {
        data.append('accessProperties[description]', this.accessProperties.description);
      }
      if (this.accessProperties.customErrorMessage) {
        data.append('accessProperties[customErrorMessage]', this.accessProperties.customErrorMessage);
      }
      this.accessProperties.columnInformations?.forEach((columnInformation: any, index) => {
        data.append(`accessProperties[columnInformations][${index}][name]`, columnInformation.name);
        data.append(`accessProperties[columnInformations][${index}][description]`, columnInformation.description);
        data.append(`accessProperties[columnInformations][${index}][regExp]`, columnInformation.regExp);
        data.append(`accessProperties[columnInformations][${index}][caseSensitive]`, columnInformation.caseSensitive);
      });
    }

    if (this.active !== undefined && this.active != null) {
      data.append('active', this.active.toString());
    }

    if (this.endDate) {
      data.append('endDate', this.endDate.toISOString());
    }

    if (this.customerFields) {
      this.customerFields.forEach((customerField: CampaignCustomerField, index) => {
        data.append(`customerFields[${index}][name]`, customerField.name);
        data.append(`customerFields[${index}][description]`, customerField.description);
        data.append(`customerFields[${index}][regExp]`, customerField.regExp);
        data.append(`customerFields[${index}][type]`, customerField.type);
        data.append(`customerFields[${index}][required]`, customerField.required ? '1' : '0');
      });
    }

    if (this.fileUploadHint) {
      data.append('fileUploadHint', this.fileUploadHint);
    }

    if (this.costCenter) {
      data.append('costCenter', this.costCenter);
    }

    if (this.verification) {
      data.append('verification', this.verification);
      if (this.verification === 'APPROVAL_BY_USER') {
        data.append('approvalMail', this.approvalMail);
      }
    }
    if (this.verificationInstruction) {
      data.append('verificationInstruction', this.verificationInstruction);
    }

    if (this.verificationDocuments) {
      if (!Array.isArray(this.verificationDocuments)) {
        data.append('verificationDocuments[0]', this.verificationDocuments as Blob);
      }
    } else if (this.verificationDocuments === undefined) {
      data.append(
        'verificationDocuments[0]',
        null,
      );
    }
    if (this.additionalInfo) {
      data.append('additionalInfo[additionalInfoText]', this.additionalInfo.additionalInfoText);
      data.append('additionalInfo[additionalInfoConfirm]', this.additionalInfo.additionalInfoConfirm.toString());
    }



    if (this.salesProperties && this.tokenDistributionType === 'ON_DEMAND') {

      if (this.groups) {
        this.groups.forEach((id: string, index: number) => data.append(`groups[${index}]`, id));
      }

      data.append('endDate', this.salesProperties.endDate.toISOString());

      if (this.salesProperties.customMessagingEnabled) {
        data.append(
          'salesProperties[messagingProperties][email][senderName]',
          this.salesProperties.messagingProperties.email.senderName,
        );
        data.append('salesProperties[messagingProperties][email][subject]',
          this.salesProperties.messagingProperties.email.subject);
        data.append('salesProperties[messagingProperties][email][text]',
          this.salesProperties.messagingProperties.email.text.templateText);

        data.append('salesProperties[messagingProperties][sms][senderName]',
          this.salesProperties.messagingProperties.sms.senderName);
        data.append('salesProperties[messagingProperties][sms][text]',
          this.salesProperties.messagingProperties.sms.text.templateText);
      }
    }

    if (this.salesProperties.description) {
      data.append('salesProperties[description]', this.salesProperties.description);
    }

    if (this.salesProperties.marketingEndDate) {
      data.append(
        'salesProperties[marketingEndDate]',
        this.salesProperties.marketingEndDate.toISOString().slice(0, 10),
      );
    }

    if (this.salesProperties.redemptionDelay) {
      data.append('salesProperties[redemptionDelay]', this.salesProperties.redemptionDelay.toString());
    }

    if (this.externalId) {
      data.append('externalId', this.externalId);
    }

    if (this.codeProperties.updateQuantity) {
      data.append('codeUpdateQuantity', this.codeProperties.updateQuantity);
    }

    return data;
  }
}

export default CampaignEdit;
