import MinimalOrder from '@/domains/order/MinimalOrder';
import fetchList, { FetchListPayload, FetchListAction } from '@/utils/fetchList';

const fetchOrderList: FetchListAction<MinimalOrder> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let { filters } = fetchListPayload;

  let url;
  if (rootGetters.isMandant) {
    url = `/my/order`;
  } else {
    url = `/order`;

    filters = filters ? filters.filter((f) => f.field !== 'mandantId') : [];
    const mandantIdSimulated = rootGetters.getSimulatedMandantId;
    if (mandantIdSimulated) {
      filters.push({
        field: 'mandantId',
        query: mandantIdSimulated,
      });
    }
  }

  return await fetchList({ commit, dispatch }, {
    ...fetchListPayload,
    filters,
  }, {
    url,
    domainParser: MinimalOrder.create,
    listMutation: 'setMinimalOrders',
    listInfoMutation: 'setOrderListInfo',
  });
};

export default fetchOrderList;
