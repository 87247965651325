import Domain from '@/types/Domain';

interface Props {
  refreshToken: string;
}

class RefreshSession extends Domain implements Props {
  public static createAll(refreshSessions: any[]): RefreshSession[] {
    return refreshSessions.map(RefreshSession.create);
  }

  public static create(data: any): RefreshSession {
    return new RefreshSession(
      data.refreshToken || '',
    );
  }

  private constructor(
    public refreshToken: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      refreshToken: {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
      },
    };
  }

  public toFormData(): FormData {
    const  {
      refreshToken,
    } = this;

    const data = new FormData();

    Object.getOwnPropertyNames(this).forEach((key) => {
      const excludedProperties = [
        'errors',
        'id',
        '__ob__',
        'refreshToken',
      ];

      if (excludedProperties.indexOf(key) === -1) {
        data.append(key, (this as any)[key]);
      }
    });

    if (refreshToken) {
      data.append(`refresh_token`, refreshToken);
    }

    return data;
  }

  public clone(): Domain {
    return RefreshSession.create(JSON.parse(JSON.stringify(this)));
  }
}

export default RefreshSession;
