import { GetterTree } from 'vuex';
import { MandantState } from './state';
import { ListInfo } from '@/types/ListInfo';
import MinimalMandant from '@/domains/mandant/MinimalMandant';
import Mandant from '@/domains/mandant/Mandant';
import NormalizedData from '@/types/NormalizedData';
import SimpleMandant from '@/domains/mandant/SimpleMandant';

type MandantGetter = (id: string) => Mandant;

const getMandant = (state: MandantState): MandantGetter => (id: string): Mandant => {
  return state.mandants[id];
};

export const getMinimalMandants = (state: MandantState): NormalizedData<MinimalMandant> => {
  return state.minimalMandants;
};

export const getSimpleMandants = (state: MandantState): NormalizedData<SimpleMandant> => {
  return state.simpleMandants;
};

export const getMandantListInfo = (state: MandantState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<MandantState, any> = {
  getMandant,
  getMinimalMandants,
  getSimpleMandants,
  getMandantListInfo,
};

export default getters;
