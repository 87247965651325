import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import Tag from '@/domains/tag/Tag';


const deleteTag: PushSingleAction<Tag> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Tag>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'DELETE', pushPayload, {
    url: `/tag/${id}`,
    mutation: 'removeTag',
  });
};

export default deleteTag;
