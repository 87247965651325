import createFakeResponse from '../../createFakeResponse';
import CodeStatus from '@/types/CodeStatus';
import Code from '@/domains/code/Code';

export const itemsFirstPage: Code[] = Code.createAll([
  {
    id: '294e3df1-0088-426a-aa97-asdasd123123121',
    codeStatus: CodeStatus.CANCELED,
    token: 'AAA-BB-1234-5671',
    orderId: '034869b8-0675-4a7f-12312-48507f674179',
    cancellationDate: '30.10.2018',
  },
  {
    id: '294e3df1-0088-426a-aa97-asdasd123123122',
    codeStatus: CodeStatus.NOT_REDEEMED,
    token: 'AAA-BB-1234-5672',
    orderId: '034869b8-0675-4a7f-12312-48507f674179',
  },
  {
    id: '294e3df1-0088-426a-aa97-asdasd123123123',
    codeStatus: CodeStatus.REDEEMED,
    token: 'AAA-BB-1234-5673',
    orderId: '034869b8-0675-4a7f-12312-48507f674179',
    redemptionDate: '30.10.2018',
  },
]);
export const bodyFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 3,
  _embedded: {
    items: itemsFirstPage,
  },
};

export const codeListFirstPage = createFakeResponse(bodyFirstPage);
