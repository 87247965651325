import Message from '@/domains/message/Message';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';
import normalizeData from '@/utils/normalizeData';


const setMessageRead: PushSingleAction<Message> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Message>,
) => {

  const { fetch, domain } = payload;
  const pushPayload = { fetch, domain };

  const request =  await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/notifications/markasread`,
    mutation: undefined,
  });

  domain.markedAsRead = true;
  commit(
    'updateMessageListUser',
    normalizeData([domain]),
  );

  return request;
};

export default setMessageRead;
