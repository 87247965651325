import { ListSort } from '@/types/ListSort';

export default (sorts: ListSort[]): string => {
  let result = '';
  if (sorts && sorts.length > 0) {
    sorts.forEach((sort: ListSort) => {
      result += `&sort[${sort.field}]=${sort.order}`;
    });
  }
  return result;
};
