import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import UserGroup from '@/domains/userGroup/UserGroup';

const createUserGroup: PushSingleAction<UserGroup> = async (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<UserGroup>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/user-group`;
  } else {
    url = `/mandant/${rootGetters.getSimulatedMandantId}/user-group`;
  }

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url,
    mutation: 'addUserGroups',
  });
};

export default createUserGroup;
