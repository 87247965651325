export function getMissingTextVars(text: string = '', vars: string[]): string[] {
  const missingVars = vars.map((mvar) => {
    if (!text.includes(mvar)) {
      return mvar;
    }
  });
  return missingVars.filter((mvar) => {
    return mvar !== undefined;
  });
}
