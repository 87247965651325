import Article from '@/domains/article/Article';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';

const editArticle: PushSingleAction<Article> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Article>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/article/${id}`,
    mutation: 'addArticles',
  });
};

export default editArticle;
