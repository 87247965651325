import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import User from '@/domains/mandant/User';

const editMyUser: PushSingleAction<User> = async (
  { commit, dispatch },
  payload: PushSinglePayload<User>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'PATCH', pushPayload, {
    url: `/my/profile`,
    mutation: 'addUsers',
  });
};

export default editMyUser;
