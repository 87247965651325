import createFakeResponse from '../../../test-helper/createFakeResponse';

export const campaignCreate = createFakeResponse(
  {
    id: '12345678b-0675-asdf-b868-123123123',
    codeLink:
      'http://insight.dev.schoolwires.com/HelpAssets/C2Assets/C2Files/C2ImportFamRelSample.csv',
  },
  201,
);
