import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/api-platform/fetchSingle';
import Message from '@/domains/message/Message';


const fetchMessage: FetchSingleAction<Message> = async (
    { commit, dispatch, rootGetters },
    payload: FetchSinglePayload,
) => {
  const { id } = payload;

  commit('setHandleMessage', Message.createDefault());

  const fetchedMessage = await fetchSingle({ commit, dispatch }, payload, {
    url: `/notifications/${id}`,
    domainParser: Message.create,
    mutation: undefined,
  });

  commit(
    'setHandleMessage',
    fetchedMessage,
  );

  return fetchedMessage;
};

export default fetchMessage;
