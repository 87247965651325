import { MutationTree } from 'vuex';
import { CampaignState } from './state';
import { ListInfo } from '@/types/ListInfo';
import Campaign from '@/domains/campaign/Campaign';
import MinimalCampaign from '@/domains/campaign/MinimalCampaign';
import NormalizedData from '@/types/NormalizedData';
import CampaignArticle from '@/domains/campaign/CampaignArticle';
import SalesCampaign from '@/domains/campaign/SalesCampaign';
import SalesCampaignArticleVariant from '@/domains/campaign/SalesCampaignArticleVariant';


export const addCampaigns = (
  state: CampaignState,
  campaigns: NormalizedData<Campaign>,
): void => {
  state.campaigns = {
    ...state.campaigns,
    ...campaigns,
  };
};

export const setCampaign = (
  state: CampaignState,
  campaign: Campaign,
): void => {
  state.campaigns[campaign.id] = campaign;
};

export const setSalesCampaigns = (
  state: CampaignState,
  salesCampaigns: NormalizedData<SalesCampaign>,
): void => {
  state.salesCampaigns = {
    ...salesCampaigns,
  };
};

export const setMinimalCampaigns = (
  state: CampaignState,
  minimalCampaigns: NormalizedData<MinimalCampaign>,
): void => {
  state.minimalCampaigns = {
    ...minimalCampaigns,
  };
};

export const setCampaignArticles = (
  state: CampaignState,
  campaignArticles: NormalizedData<CampaignArticle>,
): void => {
  state.campaignArticles = {
    ...campaignArticles,
  };
};

export const setSalesCampaignArticleVariants = (
  state: CampaignState,
  salesCampaignArticleVariants: NormalizedData<SalesCampaignArticleVariant>,
): void => {
  state.salesCampaignArticleVariants = {
    ...salesCampaignArticleVariants,
  };
};

export const setCampaignListInfo = (
  state: CampaignState,
  info: ListInfo,
): void => {
  state.listInfo = info;
};

export const setCampaignArticleListInfo = (
  state: CampaignState,
  info: ListInfo,
): void => {
  state.campaignArticleListInfo = info;
};

export const setCurrentEditingCampaign = (
  state: CampaignState,
  campaign: Campaign,
): void => {
  state.currentEditingCampaign = campaign;
};

export const resetCurrentEditingCampaign = (
  state: CampaignState,
): void => {
  state.currentEditingCampaign = Campaign.create({});
};

export const setCurrentSalesCampaign = (
  state: CampaignState,
  campaign: Campaign,
): void => {
  state.currentSalesCampaign = campaign;
};

export const resetCurrentSalesCampaign = (
  state: CampaignState,
): void => {
  state.currentSalesCampaign = undefined;
} ;

const mutations: MutationTree<CampaignState> = {
  addCampaigns,
  setCampaign,
  setSalesCampaigns,
  setMinimalCampaigns,
  setCampaignListInfo,
  setCampaignArticles,
  setCampaignArticleListInfo,
  setSalesCampaignArticleVariants,
  setCurrentEditingCampaign,
  resetCurrentEditingCampaign,
  setCurrentSalesCampaign,
  resetCurrentSalesCampaign,
};

export default mutations;
