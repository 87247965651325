import { MutationTree } from 'vuex';
import { HighlightState } from './state';
import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import HighlightArticle from '@/domains/highlight/HighlightArticle';
import MinimalArticle from '@/domains/article/MinimalArticle';


export const setHighlights = (
  state: HighlightState,
  highlights: NormalizedData<HighlightArticle>,
): void => {
  state.highlights = {
    ...highlights,
  };
};

export const setArticleForHighlights = (
  state: HighlightState,
  articles: NormalizedData<MinimalArticle>,
): void => {
  state.articles = {
    ...articles,
  };
};

export const setArticleForHighlightsListInfo = (state: HighlightState, info: ListInfo): void => {
  state.listInfo = info;
};

const mutations: MutationTree<HighlightState> = {
  setHighlights,
  setArticleForHighlights,
  setArticleForHighlightsListInfo,
};

export default mutations;
