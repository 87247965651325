import TsxComponent from '@/typeDefinitions/vue-tsx';
import { Action } from 'vuex-class';
import Component from 'vue-class-component';
import classNames from 'classnames';
import TabbedPanel from '@/components/TabbedPanel';
import styles from '../NewPopup.module.scss';
import { PopupProps } from '@/types/PopupPayload';


@Component({
  props: {
    items: Array,
    icon: String,
    className: String,
  },
})
class TabbedPanelPopup extends TsxComponent<PopupProps> {
  @Action('closeActivePopup')
  private closeActivePopup!: () => void;

  private onClose(): void {
    this.closeActivePopup();
    this.$emit('close');
  }

  private render() {
    const {
      items,
      icon,
      className,
      color,
    } = this.$props;

    return (
      <div class={classNames(
        styles['popup__wrapper'],
        className,
        color && color === 'info' && styles['popup__wrapper-info'],
        color && color === 'error' && styles['popup__wrapper-error'],
        color && color === 'warning' && styles['popup__wrapper-warning'],
      )}>
        {icon && (
          <div className={classNames(
            styles['popup__wrapper__circle'],
            color && color === 'info' && styles['popup__wrapper__circle-info'],
            color && color === 'error' && styles['popup__wrapper__circle-error'],
            color && color === 'warning' && styles['popup__wrapper__circle-warning'],
          )}>
            <font-awesome-icon
              class="fa-lg"
              icon={icon}
            />
          </div>
        )}
        <div
          class={classNames(
            styles['popup__wrapper__close-icon-wrapper'],
            'mb-4',
          )}
        >
          <font-awesome-icon
            class={styles['popup__wrapper__close-icon-wrapper__close-icon']}
            onClick={this.onClose}
            icon="times"
          />
        </div>
        <div class={classNames(
          styles['popup__wrapper__content'],
          'text-center',
          'mt-4',
        )}>
          <TabbedPanel items={items} />
        </div>
      </div>
    );
  }
}

export default TabbedPanelPopup;
