export default (target: Element, endY: number, smooth: boolean = false, duration: number = 400) => {
  const startTime = new Date().getTime();

  // Easing function
  const easeInOutQuart = (
    time: number,
    from: number,
    distance: number,
    durationTwo: number,
  ) => {
    time = time / (durationTwo / 2);
    if (time < 1) {
      return (distance / 2) * time * time * time * time + from;
    }
    return (-distance / 2) * ((time -= 2) * time * time * time - 2) + from;
  };

  if (smooth) {
    const timer = setInterval(() => {
      const time = new Date().getTime() - startTime;
      const newY = easeInOutQuart(
        time,
        target.scrollTop,
        endY - target.scrollTop,
        duration,
      );
      if (time >= duration) {
        clearInterval(timer);
      }
      target.scrollTop = newY;
    }, 1000 / 60); // 60 fps
  } else {
    target.scrollTop = endY;
  }
};
