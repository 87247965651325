import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { UserGroupState } from '../state';
import createUserGroup from './createUserGroup';
import editUserGroup from './editUserGroup';
import deleteUserGroup from './deleteUserGroup';
import fetchUserGroup from './fetchUserGroup';
import fetchUserGroupList from './fetchUserGroupList';
import fetchUserGroupUsersList from './fetchUserGroupUsersList';
import fetchAssignableOwnersList from './fetchAssignableOwnersList';

const actions: ActionTree<UserGroupState, RootState> = {
  createUserGroup,
  editUserGroup,
  deleteUserGroup,
  fetchUserGroup,
  fetchUserGroupList,
  fetchUserGroupUsersList,
  fetchAssignableOwnersList,
};

export default actions;
