import Maybe from '@/types/Maybe';
import { ListFilter } from '@/types/ListFilter';
import { ListSort } from '@/types/ListSort';
import getUrlParamsForFilters from '@/utils/getUrlParamsForFilters';
import getUrlParamsForSorts from '@/utils/getUrlParamsForSorts';

const getRequestListUri = (
  endpoint: string,
  page: number = 1,
  limit: number = 10,
  filters: ListFilter[] = [],
  sorts: ListSort[] = [],
): string =>
  Maybe.fromValue(endpoint)
    .map((url) => `${url}?page=${page}`)
    .map((url) => `${url}&limit=${limit}`)
    .map((url) => `${url}${getUrlParamsForFilters(filters)}`)
    .map((url) => `${url}${getUrlParamsForSorts(sorts)}`)
    .getOrElse(endpoint);

export default getRequestListUri;
