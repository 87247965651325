import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import {MediaObjectState} from '../state';
import createMediaObject from './createMediaObject';


const actions: ActionTree<MediaObjectState, RootState> = {
  createMediaObject,
};

export default actions;
