import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import UserGroup from '@/domains/userGroup/UserGroup';

const fetchUserGroupList: FetchListAction<UserGroup> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  const filters = fetchListPayload.filters || [];

  let url;
  if (rootGetters.isMandant) {
    url = `/my/user-group`;
  } else {
    url = `/mandant/${rootGetters.getSimulatedMandantId}/user-group`;
  }

  return await fetchList({ commit, dispatch }, {
    ...fetchListPayload,
    filters,
  }, {
    url,
    domainParser: UserGroup.create,
    listMutation: 'setUserGroups',
    listInfoMutation: 'setUserGroupListInfo',
  });
};

export default fetchUserGroupList;
