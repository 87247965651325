import createFakeResponse from '../../../test-helper/createFakeResponse';

const variantOne = {
  active: true,
  id: '294e3df1-0088-426a-aa97-40e76f210cd112',
  ebootisId: 'HW-123123123',
  ean: '1231231231231',
  name: 'iPhone 6s 64gb rot',
  buyPrice: {
    amount: 49999,
    currency: 'EUR',
  },
  articlePrice: {
    amount: 59999,
    currency: 'EUR',
  },
  advertisingCostsSubsidy: {
    amount: 2999,
    currency: 'EUR',
  },
  unitFee: {
    amount: 4999,
    currency: 'EUR',
  },
  endOfLife: '31.12.2020',
  weight: '600',
  weightUnit: 'g',
  bulletPoints: [
    'iOS 10',
    '11,9 cm (4,7“) Retina HD',
    'A9 Prozessor',
    '12 MP Hauptkamera',
    '3D Touch',
  ],
  description:
    'Elegantes, durchgehendes Unibody-Design. 3D Touch, Live Photos, A9 Chip, ' +
    'fortschrittliche Kameras, optische Bildstabilisierung und 5,5" Retina HD Display. Und noch viel ' +
    'mehr. Das iPhone 6s Plus hat ein 5,5" Retina HD Display mit 3D Touch. Einen A9 Chip mit 64‑Bit ' +
    'Desktoparchitektur. Eine 12 Megapixel Kamera mit Live Photos und optischer Bildstabilisierung für ' +
    'Fotos und Videos. Touch ID. LTE und WLAN. iOS 10 und iCloud. Alles in einem eleganten, durchgehenden ' +
    'Unibody-Design.',
  images: [
    {
      url:
        'https://cyberport.scene7.com/is/image/cyberport/170914174935300701900060V?$Zoom_500$',
    },
  ],
};

const variantTwo = {
  active: true,
  id: '294e3df1-0088-426a-aa97-40e76f210ZU112',
  ebootisId: 'HW-9999999',
  ean: '9999999999999',
  name: 'iPhone 6s 64gb blau',
  buyPrice: {
    amount: 49999,
    currency: 'EUR',
  },
  articlePrice: {
    amount: 69999,
    currency: 'EUR',
  },
  advertisingCostsSubsidy: {
    amount: 2999,
    currency: 'EUR',
  },
  unitFee: {
    amount: 4999,
    currency: 'EUR',
  },
  endOfLife: '31.12.2020',
  weight: '600',
  weightUnit: 'g',
  bulletPoints: [
    'iOS 10',
    '11,9 cm (4,7“) Retina HD',
    'A9 Prozessor',
    '12 MP Hauptkamera',
    '3D Touch',
  ],
  description:
    'Elegantes, durchgehendes Unibody-Design. 3D Touch, Live Photos, A9 Chip, ' +
    'fortschrittliche Kameras, optische Bildstabilisierung und 5,5" Retina HD Display. Und noch viel ' +
    'mehr. Das iPhone 6s Plus hat ein 5,5" Retina HD Display mit 3D Touch. Einen A9 Chip mit 64‑Bit ' +
    'Desktoparchitektur. Eine 12 Megapixel Kamera mit Live Photos und optischer Bildstabilisierung für ' +
    'Fotos und Videos. Touch ID. LTE und WLAN. iOS 10 und iCloud. Alles in einem eleganten, durchgehenden ' +
    'Unibody-Design.',
  images: [
    {
      url:
        'https://cyberport.scene7.com/is/image/cyberport/180109173052600701900034C?$Zoom_500$',
    },
  ],
};

const bodyGUFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 0,
  _embedded: {
    items: [] as any[],
  },
};
const bodyHWFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [variantOne],
  },
};
const bodyZUFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [variantTwo],
  },
};
const bodyFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 2,
  _embedded: {
    items: [variantOne, variantTwo],
  },
};

export const campaignVariantListFirstPage = createFakeResponse(bodyFirstPage);
export const campaignVariantListCategoryHWFirstPage = createFakeResponse(
  bodyHWFirstPage,
);
export const campaignVariantListCategoryZUFirstPage = createFakeResponse(
  bodyZUFirstPage,
);
export const campaignVariantListCategoryGUFirstPage = createFakeResponse(
  bodyGUFirstPage,
);
