import { MutationTree } from 'vuex';
import { TagState } from './state';
import NormalizedData from '@/types/NormalizedData';
import Tag from '@/domains/tag/Tag';
import { ListInfo } from '@/types/ListInfo';


export const setTags = (
  state: TagState,
  tags: NormalizedData<Tag>,
): void => {
  state.tags = tags;
};

export const addTags = (
  state: TagState,
  tags: NormalizedData<Tag>,
): void => {
  state.tags = {
    ...state.tags,
    ...tags,
  };
};

export const removeTag = (
  state: TagState,
  tag: Tag,
): void => {
  delete state.tags[tag.id];
};

export const setTagListInfo = (state: TagState, info: ListInfo): void => {
  state.listInfo = info;
};

const mutations: MutationTree<TagState> = {
  setTags,
  addTags,
  removeTag,
  setTagListInfo,
};

export default mutations;
