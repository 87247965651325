import { MutationTree } from 'vuex';
import { CodeDispatchState } from './state';
import NormalizedData from '@/types/NormalizedData';
import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';
import { ListInfo } from '@/types/ListInfo';


export const addCodeDispatches = (
  state: CodeDispatchState,
  codeDispatches: NormalizedData<CodeDispatch>,
): void => {
  state.codeDispatches = {
    ...state.codeDispatches,
    ...codeDispatches,
  };
};

export const setCodeDispatches = (
  state: CodeDispatchState,
  codeDispatches: NormalizedData<CodeDispatch>,
): void => {
  state.codeDispatches = {
    ...codeDispatches,
  };
};

export const setCodeDispatchListInfo = (
  state: CodeDispatchState,
  info: ListInfo,
): void => {
  state.codeDispatchListInfo = info;
};

export const resetCodeDispatches = (
  state: CodeDispatchState,
): void => {
  state.codeDispatches = {};
  state.codeDispatchListInfo = ListInfo.create();
};

const mutations: MutationTree<CodeDispatchState> = {
  addCodeDispatches,
  setCodeDispatches,
  setCodeDispatchListInfo,
  resetCodeDispatches,
};

export default mutations;
