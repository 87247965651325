import Domain from '@/types/Domain';

interface Image {
  order: string;
  url: string;
  filename: string;
  hash: string;
  extension: string;
  size: string;
  description: string;
}

interface CoPaymentGrossC2B {
  amount: string;
  currency: string;
}

interface Props {
  id: string;
  name: string;
  description: string;
  bulletPoints: string[];
  images: Image[];
  coPaymentGrossC2B: CoPaymentGrossC2B;
  active: boolean;
}

class SalesCampaignArticleVariant extends Domain implements Props {
  public static createAll(campaigns: any[]): SalesCampaignArticleVariant[] {
    return campaigns.map(SalesCampaignArticleVariant.create);
  }

  public static create(data: any): SalesCampaignArticleVariant {
    return new SalesCampaignArticleVariant(
      data.id,
      data.name,
      data.description,
      data.bulletPoints,
      data.images,
      data.coPaymentGrossC2B,
      data.active,
    );
  }

  private constructor(
    public id: string,
    public name: string,
    public description: string,
    public bulletPoints: string[],
    public images: Image[],
    public coPaymentGrossC2B: CoPaymentGrossC2B,
    public active: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): SalesCampaignArticleVariant {
    return SalesCampaignArticleVariant.create(JSON.parse(JSON.stringify(this)));
  }
}

export default SalesCampaignArticleVariant;
