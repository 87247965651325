import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import TagArticleCount from '@/domains/tag/TagArticleCount';

const fetchPortfolioTagList: FetchListAction<TagArticleCount> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let url;
  if (rootGetters.isMandant) {
    url = `/my/portfolio/tag`;
  } else {
    const simulatedMandantId = rootGetters.getSimulatedMandantId;
    if (simulatedMandantId) {
      url = `/mandant/${simulatedMandantId}/portfolio/tag`;
    } else {
      return;
    }
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: TagArticleCount.create,
    listMutation: 'setTagArticleCounts',
    listInfoMutation: 'setTagArticleCountListInfo',
  });
};

export default fetchPortfolioTagList;
