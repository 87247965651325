import { MutationTree } from 'vuex';
import { OrderState } from './state';
import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import Order from '@/domains/order/Order';
import MinimalOrder from '@/domains/order/MinimalOrder';
import OrderStatus from '@/types/OrderStatus';


export const addOrders = (
  state: OrderState,
  orders: NormalizedData<Order>,
): void => {
  state.orders = {
    ...state.orders,
    ...orders,
  };
};

export const setOrderListInfo = (state: OrderState, info: ListInfo): void => {
  state.listInfo = ListInfo.create({
    ...state.listInfo,
    ...info,
  });
};

const setMinimalOrders = (
  state: OrderState,
  orders: NormalizedData<MinimalOrder>,
): void => {
  state.minimalOrders = orders;
};

const setListStatusFilter = (
  state: OrderState,
  status: OrderStatus,
): void => {
  state.listStatusFilter = status;
};

const mutations: MutationTree<OrderState> = {
  addOrders,
  setOrderListInfo,
  setMinimalOrders,
  setListStatusFilter,
};

export default mutations;
