import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';
import classNames from 'classnames';
import styles from '../NewPopup.module.scss';
import { PopupProps } from '@/types/PopupPayload';


@Component({
  props: {
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Schließen',
    },
    color: String,
    icon: String,
    type: {
      type: String,
      default: 'hint',
    },
    className: String,
  },
})
class PreviewPopup extends TsxComponent<PopupProps> {
  @Action('closeActivePopup')
  private closeActivePopup!: () => void;

  private onClose(): void {
    this.closeActivePopup();
    this.$emit('close');
  }

  private render() {
    const {
      description,
      className,
      color,
    } = this.$props;

    return (
      <div class={classNames(
        styles['popup__wrapper--preview'],
        className,
        color && color === 'info' && styles['popup__wrapper-info'],
        color && color === 'error' && styles['popup__wrapper-error'],
        color && color === 'warning' && styles['popup__wrapper-warning'],
      )}>
        <h2 class="campaign-form-step__title headline-size--75">
          Einlösebedingungen
        </h2>
        <div
          class={styles['popup__wrapper__content']}
          domPropsInnerHTML={description}>Kein Inhalt bisher...</div>
        <button onClick={this.onClose} class="btn btn-secondary popup__wrapper__action">Zurück</button>
        <button onClick={this.onClose} class="btn btn-primary float-right popup__wrapper__action">Bestätigen</button>
      </div>
    );
  }
}

export default PreviewPopup;
