export default function(formData) {
  const propsToDelete = [];
  for (const pair of formData.entries()) {
    if (
      pair[1] === undefined ||
      pair[1] === null ||
      pair[1] === ''
    ) {
      // don't delete the entry within the loop to prevent strange behavior
      propsToDelete.push(pair[0]);
    }
  }
  propsToDelete.forEach((prop) => {
    formData.delete(prop);
  });
  return formData;
}
