import { CategoryState } from '@/store/modules/category/state';
import NormalizedData from '@/types/NormalizedData';
import Category from '@/domains/category/Category';

const setCategories = (
  state: CategoryState,
  categories: NormalizedData<Category>,
): void => {
  state.categories = categories;
};

export default setCategories;
