import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import HintPopup from './popup/HintPopup';
import ConfirmPopup from './popup/ConfirmPopup';
import PreviewPopup from './popup/PreviewPopup';
import FullSizePopup from './popup/FullSizePopup';
import PopupPayload from '@/types/PopupPayload';
import OrderStatusPopup from './popup/OrderStatusPopup';
import TabbedPanelPopup from '@/components/popup/TabbedPanelPopup';
import FormPopup from '@/components/popup/FormPopup';

export type PopupType = 'hint' | 'confirm' | 'preview' | 'full-size' | 'order-status' | 'tabbed-panel' | 'form';

@Component({
  props: {
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Schließen',
    },
    color: String,
    icon: String,
    type: {
      type: String,
      default: 'hint',
    },
    className: String,
    routeName: String,
    orderStatus: Object,
    orderStatusPayload: Object,
    items: Array,
    form: Object,
  },
})
class NewPopup extends TsxComponent<PopupPayload> {
  private onConfirm() {
    if (this.$props.onConfirm) {
      this.$props.onConfirm();
    }

    this.$emit('confirm');
  }

  private onClose() {
    this.$emit('close');
  }

  private render() {
    const {
      type,
      title,
      description,
      buttonText,
      color,
      icon,
      className,
      routeName,
      orderStatus,
      orderStatusPayload,
      items,
      form,
    } = this.$props;

    return type === 'hint' && (
      <HintPopup
        title={title}
        description={description}
        buttonText={buttonText}
        color={color}
        icon={icon}
        className={className}
        routeName={routeName}
        onClose={this.onClose}
      />
    ) || type === 'confirm' && (
      <ConfirmPopup
        title={title}
        description={description}
        buttonText={buttonText}
        color={color}
        icon={icon}
        className={className}
        onConfirm={this.onConfirm}
        onClose={this.onClose}
      />
    ) || type === 'preview' && (
      <PreviewPopup
        title={title}
        description={description}
        buttonText={buttonText}
        color={color}
        icon={icon}
        className={className}
        onConfirm={this.onConfirm}
        onClose={this.onClose}
      />
    ) || type === 'full-size' && (
      <FullSizePopup
        title={title}
        color={color}
        description={description}
        icon={icon}
        className={className}
      />
    ) || type === 'order-status' && (
      <OrderStatusPopup
        color={color}
        orderStatus={orderStatus}
        orderStatusPayload={orderStatusPayload}
      />
    ) || type === 'tabbed-panel' && (
      <TabbedPanelPopup
        color={color}
        items={items}
        icon={icon}
        className={className}
      />
    ) || type === 'form' && (
      <FormPopup
        title={title}
        description={description}
        buttonText={buttonText}
        color={color}
        icon={icon}
        className={className}
        form={form}
        onConfirm={this.onConfirm}
        onClose={this.onClose}
      />
    );
  }
}

export default NewPopup;
