import Enum from './Enum';

interface Props {
  label: string;
  messages: Messages;
}

interface Messages {
  SENT: string;
  FAILED: string;
  RELOAD_LOCK?: string;
  CARRIER_NOT_AVAILABLE?: string;
  CODE_ALREADY_USED?: string;
}

export default class CodeDispatchMethod extends Enum implements Props {
  public static readonly SMS = new CodeDispatchMethod(
    'SMS',
    'SMS',
    {
      SENT: 'Die SMS wird in Kürze verschickt.',
      FAILED: 'Versand fehlgeschlagen. Bitte versuchen Sie es erneut.',
      // tslint:disable-next-line:max-line-length
      RELOAD_LOCK: 'Die SMS wurde in den letzten 180 Sekunden bereits verschickt. Bitte probieren Sie es später erneut.',
      CARRIER_NOT_AVAILABLE: 'Der SMS-Versand ist aktuell nicht verfügbar. Bitte versuchen Sie es später erneut',
      CODE_ALREADY_USED: 'Der Code wurde vom Kunden bereits eingelöst. Ein erneuter Versand ist daher nicht möglich.',
    },
  );
  public static readonly EMAIL = new CodeDispatchMethod(
    'EMAIL',
    'E-Mail',
    {
      SENT: 'Die E-Mail wird in Kürze verschickt.',
      FAILED: 'Versand fehlgeschlagen. Bitte versuchen Sie es erneut.',
      CARRIER_NOT_AVAILABLE: 'Der E-Mail-Versand ist aktuell nicht verfügbar. Bitte versuchen Sie es später erneut',
      CODE_ALREADY_USED: 'Der Code wurde vom Kunden bereits eingelöst. Ein erneuter Versand ist daher nicht möglich.',
    },
  );

  public static readonly ALL = [
    CodeDispatchMethod.SMS,
    CodeDispatchMethod.EMAIL,
  ];

  public static getById(id: string): CodeDispatchMethod {
    return CodeDispatchMethod.ALL.filter((status) => status.id === id)[0];
  }

  private constructor(
    public id: string,
    public label: string,
    public messages: Messages,
  ) {
    super();
  }
}
