import Manufacturer from '@/domains/manufacturer/Manufacturer';
import fetchList, { FetchListPayload, FetchListAction } from '@/utils/fetchList';

const fetchManufacturerList: FetchListAction<Manufacturer> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload,
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/manufacturer`,
    domainParser: Manufacturer.create,
    listMutation: 'setManufacturers',
    listInfoMutation: 'setManufacturerListInfo',
  });
};

export default fetchManufacturerList;
