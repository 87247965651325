import Domain from '@/types/Domain';

interface Props {
  count: number;
  status: string;
}

class RedemptionRateInfo extends Domain implements Props {
  public static createAll(data: any[] = []): RedemptionRateInfo[] {
    return data.map(RedemptionRateInfo.create);
  }

  public static create(data: any = {}): RedemptionRateInfo {
    return new RedemptionRateInfo(
      data.count,
      data.status,
    );
  }

  private constructor(
    public count: number,
    public status: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): RedemptionRateInfo {
    return RedemptionRateInfo.create(this);
  }
}

export default RedemptionRateInfo;
