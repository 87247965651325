import Vue from 'vue';
import Router from 'vue-router';
import routeGuardReload from '@/utils/routeGuardReload';
import routeGuardReset from '@/utils/routeGuardReset';
import { beforeRouteLeaveConfirm } from '@/utils/beforeRouteLeaveConfirm';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/LoginView.tsx'),
      props: true,
    },
    {
      path: '/login/passwort-vergessen',
      name: 'password-reset',
      component: () => import('@/views/auth/PasswordResetView.tsx'),
    },
    {
      path: '/login/passwort-vergessen/angefordert',
      name: 'password-reset-requested',
      component: () => import('@/views/auth/PasswordResetRequestedView.tsx'),
    },
    {
      path: '/login/passwort-zuruecksetzen/:token',
      name: 'password-reset-new-password',
      component: () => import('@/views/auth/PasswordResetNewPasswordView.tsx'),
    },
    {
      path: '/login/passwort-zurueckgesetzt',
      name: 'password-reset-success',
      component: () => import('@/views/auth/PasswordResetSuccessView.tsx'),
    },
    {
      path: '/imprint',
      component: () => import('@/views/legal/Imprint.tsx'),
      name: 'imprint',
      meta: {
        description: 'Impressum',
      },
    },
    {
      path: '/terms-and-conditions',
      component: () => import('@/views/legal/TermsAndConditions.tsx'),
      name: 'terms-and-conditions',
      meta: {
        description: 'Allgemeine Geschäftsbedingungen',
      },
    },
    {
      path: '/privacy-terms',
      component: () => import('@/views/legal/PrivacyTerms.tsx'),
      name: 'privacy-terms',
      meta: {
        description: 'Datenschutzerklärung',
      },
    },
    {
      path: '/',
      component: () => import('@/views/acp/MainAppLayout.tsx'),
      props: {
        modifier: '--dock',
      },
      name: 'articleRoot',
      children: [
        {
          path: 'kampagnen',
          component: () => import('@/views/campaign/CampaignListView.tsx'),
          name: 'campaign-list',
          meta: {
            description: 'Kampagnenübersicht',
            breadcrumb: 'Kampagnenübersicht',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'artikel',
          component: () => import('@/views/article/ArticleListDockView.tsx'),
          name: 'article-list',
          meta: {
            description: 'Artikelübersicht',
            breadcrumb: 'Artikelübersicht',
            sidebarParent: 'article',
          },
        },
        {
          path: 'auftraege',
          component: () => import('@/views/order/OrderListView.tsx'),
          name: 'order-list',
          meta: {
            description: 'Auftragsübersicht',
            breadcrumb: 'Auftragsübersicht',
            sidebarParent: 'order-list',
          },
        },
        {
          path: 'mandanten',
          component: () => import('@/views/mandant/MandantListView.tsx'),
          name: 'mandant-list',
          meta: {
            description: 'Mandantenübersicht',
            breadcrumb: 'Mandantenübersicht',
            sidebarParent: 'mandant',
          },
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/acp/MainAppLayout.tsx'),
      name: 'root',
      children: [
        {
          path: 'dashboard',
          component: () => import('@/views/dashboard/Dashboard'),
          name: 'dashboard',
          meta: {
            description: 'Overview of dashboard',
            breadcrumb: 'Dashboard',
          },
        },
        {
          path: 'auftraege',
          component: () => import('@/views/order/OrderListView.tsx'),
          name: 'order-list',
          meta: {
            description: 'Auftragsübersicht',
            breadcrumb: 'Auftragsübersicht',
            sidebarParent: 'order-list',
          },
        },
        {
          path: 'auftraege/:orderId',
          component: () => import('@/views/order/OrderView.tsx'),
          name: 'order-edit',
          meta: {
            description: 'Auftrag verwalten',
            breadcrumb: 'Auftrag verwalten',
          },
        },
        {
          path: 'mandant-anlegen',
          component: () => import('@/views/mandant/MandantFormView.tsx'),
          name: 'mandant-create',
          meta: {
            description: 'Mandant anlegen',
            breadcrumb: 'Mandant anlegen',
            sidebarParent: 'mandant',
          },
        },
        {
          path: 'mandant-editieren/:mandantId',
          component: () => import('@/views/mandant/MandantFormView.tsx'),
          name: 'mandant-edit',
          meta: {
            description: 'Mandant editieren',
            breadcrumb: 'Mandant editieren',
            sidebarParent: 'mandant',
          },
        },
        {
          path: 'mein-profil',
          component: () => import('@/views/user/UserFormView.tsx'),
          name: 'myProfile',
          props: {
            isProfile: true,
          },
          meta: {
            description: 'Meine Profilseite',
            breadcrumb: 'Mein Profil',
          },
        },
        {
          path: 'mandant/:mandantId/mitarbeiter',
          component: () => import('@/views/user/UserListView.tsx'),
          name: 'user-list',
          meta: {
            description: 'Mitarbeiterübersicht',
            breadcrumb: 'Mitarbeiterübersicht',
            sidebarParent: 'user',
          },
        },
        {
          path: 'mandant/:mandantId/mitarbeiter-anlegen',
          component: () => import('@/views/user/UserFormView.tsx'),
          name: 'user-create',
          meta: {
            description: 'Mitarbeiter anlegen',
            breadcrumb: 'Mitarbeiter anlegen',
            sidebarParent: 'user',
          },
        },
        {
          path: 'mandant/:mandantId/mitarbeiter-editieren/:userId',
          component: () => import('@/views/user/UserFormView.tsx'),
          name: 'user-edit',
          meta: {
            description: 'Mitarbeiter editieren',
            breadcrumb: 'Mitarbeiter editieren',
            sidebarParent: 'user',
          },
        },
        {
          path: 'artikel-anlegen',
          component: () => import('@/views/article/ArticleFormView.tsx'),
          name: 'article-create',
          meta: {
            description: 'Artikel anlegen',
            breadcrumb: 'Artikel anlegen',
            sidebarParent: 'article',
          },
        },
        {
          path: 'artikel-editieren/:articleId',
          component: () => import('@/views/article/ArticleFormView.tsx'),
          name: 'article-edit',
          meta: {
            description: 'Artikel editieren',
            breadcrumb: 'Artikel editieren',
            sidebarParent: 'article',
          },
        },
        {
          path: 'kampagnen/:campaignId',
          component: () => import('@/views/campaign/editView/CampaignEditView.tsx'),
          name: 'campaign-edit',
          meta: {
            description: 'Kampagnendetails',
            breadcrumb: 'Kampagnendetails',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'kampagnen/:campaignId/datenabfrage', // Better name: datenerfassung?
          component: () => import('@/views/campaign/CampaignVerificationEditView.tsx'),
          name: 'campaign-verification-edit',
          meta: {
            description: 'Kampagnendetails (Datenabfrage)',
            breadcrumb: 'Kampagnendetails (Datenabfrage)',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'kampagnen/:campaignId/sichtpruefung',
          component: () => import('@/views/campaign/CampaignApprovalEditView.tsx'),
          name: 'campaign-inspection-edit',
          meta: {
            description: 'Kampagnendetails (Sichtprüfung)',
            breadcrumb: 'Kampagnendetails (Sichtprüfung)',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'kampagnen/:campaignId/zusatzbedingungen',
          component: () => import('@/views/campaign/CampaignAdditionalInformationEditView.tsx'),
          name: 'campaign-additional-information-edit',
          meta: {
            description: 'Kampagnendetails (Datenabfrage)',
            breadcrumb: 'Kampagnendetails (Datenabfrage)',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'kampagnen/:campaignId/accessmodul',
          component: () => import('@/views/campaign/CampaignAccessPropertiesEditView.tsx'),
          name: 'campaign-access-module-properties-edit',
          meta: {
            description: 'Kampagnendetails (Access Modul)',
            breadcrumb: 'Kampagnendetails (Access Modul)',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'kampagnen/:campaignId/sales',
          component: () => import('@/views/campaign/CampaignSalesModuleEditView.tsx'),
          name: 'campaign-sales-module-edit',
          meta: {
            description: 'Kampagnendetails (Datenabfrage)',
            breadcrumb: 'Kampagnendetails (Datenabfrage)',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'kampagne-anlegen',
          component: () => import('@/views/campaign/CampaignCreateView.tsx'),
          name: 'campaign-create',
          meta: {
            description: 'Kampagne anlegen',
            breadcrumb: 'Kampagne anlegen',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'kampagnen/:campaignId/codes',
          component: () => import('@/views/code/CodeListView.tsx'),
          name: 'code-list',
          meta: {
            description: 'Codeübersicht',
            breadcrumb: 'Codeübersicht',
            sidebarParent: 'marketing',
          },
        },
        {
          path: 'sales/codes-versenden',
          component: () => import('@/views/sales/code-dispatch/SalesCodeDispatchFormListView.tsx'),
          name: 'sales-code-dispatch',
          meta: {
            description: 'Codes versenden',
            breadcrumb: 'Codes versenden',
            sidebarParent: 'sales',
          },
          children: [
            {
              path: ':campaignId',
              name: 'sales-code-dispatch-form-detail',
              component: () =>
                import(
                  '@/views/sales/code-dispatch/SalesCodeDispatchFormDetailView.tsx'
                  ),
              meta: {
                description: 'Codes versenden (Detail)',
                breadcrumb: 'Codes versenden (Detail)',
                sidebarParent: 'sales',
              },
            },
          ]
        },
        {
          path: 'sales/gruppe-anlegen',
          component: () => import('@/views/sales/SalesUserGroupFormView.tsx'),
          name: 'sales-usergroup-create',
          meta: {
            description: 'Gruppe anlegen',
            breadcrumb: 'Gruppe anlegen',
            sidebarParent: 'sales',
          },
        },
        {
          path: 'sales/gruppen',
          component: () => import('@/views/sales/SalesUserGroupListView.tsx'),
          name: 'sales-usergroup-list',
          meta: {
            description: 'Gruppenübersicht',
            breadcrumb: 'Gruppenübersicht',
            sidebarParent: 'sales',
          },
        },
        {
          path: 'sales/gruppen/:userGroupId',
          component: () => import('@/views/sales/SalesUserGroupFormView.tsx'),
          name: 'sales-usergroup-edit',
          meta: {
            description: 'Gruppe editieren',
            breadcrumb: 'Gruppe editieren',
            sidebarParent: 'sales',
          },
        },
        {
          path: 'sales/versendete-codes',
          component: () => import('@/views/sales/SalesCodeDispatchListView.tsx'),
          name: 'sales-code-dispatch-list',
          meta: {
            description: 'Versendete Codes',
            breadcrumb: 'Versendete Codes',
            sidebarParent: 'sales',
          },
        },
        {
          path: 'sales/versendete-codes/:codeDispatchId',
          component: () => import('@/views/sales/SalesCodeDispatchDetailView.tsx'),
          name: 'sales-code-dispatch-detail',
          meta: {
            description: 'Versendeter Code',
            breadcrumb: 'Versendeter Code',
            sidebarParent: 'sales',
          },
        },
        {
          path: 'benachrichtigungen/editor/uebersicht',
          component: () => import('@/views/message/editor/MessageEditorListView'),
          name: 'messages-editor-overview',
          meta: {
            description: 'Benachrichtigungen Übersicht',
            breadcrumb: 'Benachrichtigungen',
            sidebarParent: 'messages',
          },
        },
        {
          path: 'benachrichtigungen/editor/neu',
          component: () => import('@/views/message/editor/MessageEditorFormView'),
          name: 'messages-editor-create',
          meta: {
            description: 'Neue Benachrichtigung erstellen',
            breadcrumb: 'Benachrichtigungen/neu',
            sidebarParent: 'messages',
          },
        },
        {
          path: 'benachrichtigungen/editor/:messageId',
          component: () => import('@/views/message/editor/MessageEditorFormView'),
          name: 'messages-editor',
          meta: {
            description: 'Benachrichtigungen bearbeiten',
            breadcrumb: 'Benachrichtigungen/bearbeiten',
            sidebarParent: 'messages',
          },
        },
        {
          path: 'benachrichtigungen/posteingang/:messageId',
          component: () => import('@/views/message/inbox/MessageReaderView'),
          name: 'messages-reader-overview',
          meta: {
            description: 'Benachrichtigungen lesen',
            breadcrumb: 'Benachrichtigungen/Posteingang',
            sidebarParent: 'messages',
          },
        },
        {
          path: 'benachrichtigungen/posteingang',
          component: () => import('@/views/message/inbox/MessageReaderView'),
          name: 'messages-reader-overview-no-id',
          meta: {
            description: 'Benachrichtigungen lesen',
            breadcrumb: 'Benachrichtigungen/übersicht',
            sidebarParent: 'messages',
          },
        },
        {
          path: 'portfolio/portfolio-anlegen',
          component: () => import('@/views/category/CategoryView.tsx'),
          name: 'portfolio-create',
          meta: {
            description: 'Kategoriespezifische Portfolios',
            breadcrumb: 'Kategoriespezifische Portfolios',
            sidebarParent: 'portfolio',
          },
        },
        {
          path: 'portfolio/highlights',
          component: () => import('@/views/highlights/HighlightEditView.tsx'),
          name: 'highlights-edit',
          meta: {
            description: 'Highlights bearbeiten',
            breadcrumb: 'Highlights bearbeiten',
            sidebarParent: 'portfolio',
          },
        },
        {
          path: 'portfolio/artikelwelten',
          component: () => import('@/views/portfolio/PortfolioView.tsx'),
          name: 'portfolio-overview',
          meta: {
            description: 'Portfolio Artikelwelten',
            breadcrumb: 'Portfolio Artikelwelten',
            sidebarParent: 'portfolio',
          },
        },
        {
          path: 'portfolio/artikelwelten/:tagId',
          component: () => import('@/views/portfolio/PortfolioArticleListView.tsx'),
          name: 'portfolio-article-list',
          meta: {
            description: 'Portfolio Artikelliste',
            breadcrumb: 'Portfolio Artikelliste',
            sidebarParent: 'portfolio',
          },
        },
        {
          path: 'portfolio/artikel/:articleId',
          component: () => import('@/views/portfolio/PortfolioArticleDetailView.tsx'),
          name: 'portfolio-article-detail',
          meta: {
            description: 'Portfolio Artikel',
            breadcrumb: 'Portfolio Artikel',
            sidebarParent: 'portfolio',
          },
        },
        {
          path: 'reporte',
          component: () => import('@/views/acp/Reports.tsx'),
          name: 'reports',
          meta: {
            description: 'Reporte',
            breadcrumb: 'Reporte',
          },
        },
        {
          path: 'hersteller',
          component: () => import('@/views/manufacturer/ManufacturerListView.tsx'),
          name: 'manufacturers',
          meta: {
            description: 'Hersteller',
            breadcrumb: 'Hersteller',
            sidebarParent: 'system',
          },
        },
        {
          path: 'tags',
          component: () => import('@/views/tag/TagListView.tsx'),
          name: 'tags',
          meta: {
            description: 'Tags',
            breadcrumb: 'Tags',
            sidebarParent: 'system',
          },
        },
        {
          path: 'cockpit',
          component: () => import('@/views/cockpit/CockpitView.tsx'),
          name: 'cockpit',
          meta: {
            description: 'Cockpit',
            breadcrumb: 'Cockpit',
            sidebarParent: 'system',
          },
        },
        {
          path: '*',
          component: () => import('@/views/Error404.tsx'),
          name: '404',
        },
      ],
    },
  ],
});

// Add router beforeResolve hook to handle spa versioning
router.beforeResolve(routeGuardReload);
router.beforeEach(routeGuardReset);
router.beforeEach(beforeRouteLeaveConfirm);

export default router;
