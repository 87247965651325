import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/api-platform/fetchSingle';
import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';
import { ListFilter } from '@/types/ListFilter';

interface FetchCodeDispatchPayload extends FetchSinglePayload {
  filters: ListFilter[];
}

const fetchCodeDispatch: FetchSingleAction<CodeDispatch> = async (
  { commit, dispatch, rootGetters },
  payload: FetchCodeDispatchPayload,
) => {
  const {
    id,
    filters,
  } = payload;

  const queryString = filters.map((filter: ListFilter) => {
    return `${encodeURIComponent(filter.field)}=${encodeURIComponent(filter.query)}`;
  }).join('&');

  const url = filters.length > 0 ?
    `/code-dispatches/${id}?${queryString}` :
    `/code-dispatches/${id}`;

  return await fetchSingle({ commit, dispatch }, payload, {
    url,
    domainParser: CodeDispatch.create,
    mutation: 'addCodeDispatches',
  });
};

export default fetchCodeDispatch;
