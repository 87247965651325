import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import PortfolioArticle from '@/domains/portfolio/PortfolioArticle';

const fetchPortfolioArticleList: FetchListAction<PortfolioArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let url;
  if (rootGetters.isMandant) {
    url = `/my/article`;
  } else {
    const simulatedMandantId = rootGetters.getSimulatedMandantId;
    if (simulatedMandantId) {
      url = `/mandant/${simulatedMandantId}/article`;
    } else {
      return;
    }
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: PortfolioArticle.create,
    listMutation: 'setPortfolioArticles',
  });
};

export default fetchPortfolioArticleList;
