import Domain from '@/types/Domain';


interface Props {
  MODULE_ADDITIONAL_CHARGE: boolean;
  MODULE_SALES: boolean;
  MODULE_API_SETTINGS: boolean;
  MODULE_ACCESS: boolean;
}

class MandantModule extends Domain implements Props {
  public static createAll(featureModules: any[]): MandantModule[] {
    return featureModules.map(MandantModule.create);
  }

  public static create(data: any = {}): MandantModule {
    return new MandantModule(
      data.MODULE_ADDITIONAL_CHARGE || false,
      data.MODULE_SALES || false,
      data.MODULE_API_SETTINGS || false,
      data.MODULE_ACCESS || false,
    );
  }

  private constructor(
    public MODULE_ADDITIONAL_CHARGE: boolean,
    public MODULE_SALES: boolean,
    public MODULE_API_SETTINGS: boolean,
    public MODULE_ACCESS: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): Domain {
    return MandantModule.create(JSON.parse(JSON.stringify(this)));
  }
}

export default MandantModule;
