import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';
import NormalizedData from '@/types/NormalizedData';
import { ListInfo } from '@/types/ListInfo';

export interface CodeDispatchState {
  codeDispatches: NormalizedData<CodeDispatch>;
  codeDispatchListInfo: ListInfo;
}

const state: CodeDispatchState = {
  codeDispatches: {},
  codeDispatchListInfo: ListInfo.create(),
};

export default state;
