import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import User from '@/domains/mandant/User';
import MinimalUser from '@/domains/mandant/MinimalUser';

export interface UserState {
  users: NormalizedData<User>;
  minimalUsers: NormalizedData<MinimalUser>;
  listInfo: ListInfo;
}

const state: UserState = {
  users: {},
  minimalUsers: {},
  listInfo: ListInfo.create(),
};

export default state;
