import fetchList, {
  FetchUnpaginatedListAction,
  FetchUnpaginatedListPayload ,
} from '@/utils/api-platform/fetchUnpaginatedList';
import Campaign from '@/domains/campaign/Campaign';

const fetchSalesCampaigns: FetchUnpaginatedListAction<Campaign> = async (
  { commit, dispatch, rootGetters },
  fetchUnpaginatedListPayload: FetchUnpaginatedListPayload,
) => {
  const url = `/sales/campaigns?freeCodeCampaigns=1&limit=100`;

  return await fetchList({ commit, dispatch }, {
    ...fetchUnpaginatedListPayload,
  }, {
    url,
    domainParser: Campaign.create,
    listMutation: 'setSalesCampaigns',
  });
};

export default fetchSalesCampaigns;
