import { GetterTree } from 'vuex';
import { PortfolioState } from './state';
import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import PortfolioArticle from '@/domains/portfolio/PortfolioArticle';
import MinimalPortfolioArticle from '@/domains/portfolio/MinimalPortfolioArticle';
import Tag from '@/domains/tag/Tag';
import TagArticleCount from '@/domains/tag/TagArticleCount';


export const getPortfolioArticles = (state: PortfolioState): NormalizedData<PortfolioArticle> => {
  return state.portfolioArticles;
};

export const getMinimalPortfolioArticles = (state: PortfolioState): NormalizedData<MinimalPortfolioArticle> => {
  return state.minimalPortfolioArticles;
};

export const getPortfolioArticleListInfo = (state: PortfolioState): ListInfo => {
  return state.portfolioArticleListInfo;
};

export const getPortfolioTags = (state: PortfolioState, rootState): Tag[] => {
  return Object.values(state.tagArticleCounts).map((count) => rootState.getTags[count.id]);
};

export const getTagArticleCount = (state: PortfolioState) => (id: string): TagArticleCount => {
  return state.tagArticleCounts[id];
};

export const getTagArticleCounts = (state: PortfolioState): NormalizedData<TagArticleCount> => {
  return state.tagArticleCounts;
};

export const getTagArticleCountListInfo = (state: PortfolioState): ListInfo => {
  return state.tagArticleCountListInfo;
};

const getters: GetterTree<PortfolioState, any> = {
  getPortfolioArticleListInfo,
  getPortfolioArticles,
  getMinimalPortfolioArticles,
  getPortfolioTags,
  getTagArticleCount,
  getTagArticleCounts,
  getTagArticleCountListInfo,
};

export default getters;
