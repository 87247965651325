import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import {Action} from 'vuex-class';
import classNames from 'classnames';
import styles from '../NewPopup.module.scss';
import FormPopupPayload, {FieldType} from '@/components/popup/form/FormPopupPayload';
import NewTextField from '@/components/textField/NewTextField';
import NewSelectField from '@/components/selectField/NewSelectField';
import { PopupProps } from '@/types/PopupPayload';


interface Props extends PopupProps {
  form?: FormPopupPayload;
}

@Component({
  props: {
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Schließen',
    },
    color: String,
    icon: String,
    type: {
      type: String,
      default: 'hint',
    },
    className: String,
    form: Object,
  },
})
class FormPopup extends TsxComponent<Props> {
  private isLoading: boolean = false;

  @Action('closeActivePopup')
  private closeActivePopup!: () => void;

  private onConfirm(): void {
    this.isLoading = true;

    this.$props.form.handleConfirm()
      .then(() => {
        this.closeActivePopup();
        this.$emit('confirm');
        this.$props.form.domain.setErrors({});
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  private onClose(): void {
    this.closeActivePopup();
    this.$emit('close');
  }

  private renderForm() {
    const { domain, fields } = this.$props.form;

    return fields.map((field) => {
      switch (field.type) {
        case FieldType.TextField: {
          const { path, idPrefix, placeholder, label, className } = field;

          return (
            <NewTextField
              domain={domain}
              path={path}
              idPrefix={idPrefix}
              placeholder={placeholder}
              label={label}
              class={classNames('mb-2', className)}
            />
          );
        }
        case FieldType.NumberField: {
          const {
            path,
            idPrefix,
            placeholder,
            label,
            className,
            maxNumber,
            minNumber,
          } = field;

          return (
            <NewTextField
              domain={domain}
              path={path}
              type="number"
              maxNumber={maxNumber}
              minNumber={minNumber}
              idPrefix={idPrefix}
              placeholder={placeholder}
              label={label}
              class={classNames('mb-2', className)}
            />
          );
        }
        case FieldType.SelectField: {
          const { path, idPrefix, label, options } = field;

          return (
            <NewSelectField
              domain={domain}
              path={path}
              idPrefix={idPrefix}
              label={label}
              options={options}
            />
          );
        }

        default: {
          // Triggers if no case is defined for new Field interface defined in FormPopupPayload
          throw new Error('Undefined render behaviour for FieldType');
        }
      }

    });
  }

  private render() {
    const {
      title,
      description,
      buttonText,
      color,
      icon,
      className,
    } = this.$props;

    return (
      <div class={classNames(
        styles['popup__wrapper'],
        className,
        color && color === 'info' && styles['popup__wrapper-info'],
        color && color === 'error' && styles['popup__wrapper-error'],
        color && color === 'warning' && styles['popup__wrapper-warning'],
      )}>
        {icon && (
          <div class={classNames(
            styles['popup__wrapper__circle'],
            color && color === 'info' && styles['popup__wrapper__circle-info'],
            color && color === 'error' && styles['popup__wrapper__circle-error'],
            color && color === 'warning' && styles['popup__wrapper__circle-warning'],
          )}>
            <font-awesome-icon
              class="fa-lg"
              icon={icon}
            />
          </div>
        )}
        {title && (
          <h1
            class={classNames(
              styles['popup__wrapper__heading'],
              'headline-size--100',
            )}
          >
            {title}
          </h1>
        )}
        <div class={classNames(
          styles['popup__wrapper__content'],
          'mt-4',
        )}>
          { description && (
            <p>{ description }</p>
          )}
          {this.renderForm()}
        </div>
        <button
          onClick={this.onConfirm}
          disabled={this.isLoading}
          class={classNames(
            styles['popup__wrapper__action'],
            'btn',
            'btn-dark',
            'btn-block',
            this.isLoading && 'isLoading',
          )}
        >
          { this.isLoading && <font-awesome-icon
            class="fa fa-spin fa-fw"
            icon="circle-notch"
          /> }
          {buttonText}
        </button>
        <button
          onclick={this.onClose}
          disabled={this.isLoading}
          class={classNames(
            styles['popup__wrapper__action'],
            'btn',
            'btn-default',
            'btn-block',
          )}
        >
          Abbrechen
        </button>
      </div>
    );
  }
}

export default FormPopup;
