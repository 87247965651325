import { CategoryState } from '@/store/modules/category/state';
import NormalizedData from '@/types/NormalizedData';
import MinimalArticleVariant from '@/domains/article/MinimalArticleVariant';

const setListCategoryVariants = (
  state: CategoryState,
  variants: NormalizedData<MinimalArticleVariant>,
): void => {
  state.listVariants = variants;
};

export default setListCategoryVariants;
