import MinimalMessage from '@/domains/message/MinimalMessage';
import fetchList, {
  FetchListAction,
  FetchListPayload,
} from '@/utils/api-platform/fetchList';

const fetchUserMessageInbox: FetchListAction<MinimalMessage> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/notification/inbox?sort[startDate]=asc`,
    domainParser: MinimalMessage.create,
    listMutation: 'setMinimalMessageList',
  });
};

export default fetchUserMessageInbox;
