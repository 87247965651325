import { getData, storeData } from './persist';
import store from '../store';


export default (response: Response) => {
  if (process.env.NODE_ENV !== 'development') {
    const storage = window.sessionStorage;

    const version = response.headers.get('application-version');
    const storedVersion = getData('application-version', storage);

    if (!storedVersion || (version && version !== storedVersion)) {
      storeData('application-version', version, storage);
    }
    if (version && version !== storedVersion) {
      store.commit('requestReload');
    }
  }
  return response;
};
