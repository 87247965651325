import { GetterTree } from 'vuex';
import { CodeDispatchState } from './state';
import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import CodeDispatch from '@/domains/codeDispatch/CodeDispatch';

type CodeDispatchGetter = (id: string) => CodeDispatch;

const getCodeDispatch = (state: CodeDispatchState): CodeDispatchGetter => (
  id: string,
): CodeDispatch => state.codeDispatches[id];

export const getCodeDispatches = (state: CodeDispatchState): NormalizedData<CodeDispatch> => {
  return state.codeDispatches;
};

export const getCodeDispatchListInfo = (state: CodeDispatchState): ListInfo => {
  return state.codeDispatchListInfo;
};

const getters: GetterTree<CodeDispatchState, any> = {
  getCodeDispatch,
  getCodeDispatches,
  getCodeDispatchListInfo,
};

export default getters;
