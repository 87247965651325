import { ListInfo } from '@/types/ListInfo';
import Campaign from '@/domains/campaign/Campaign';
import NormalizedData from '@/types/NormalizedData';
import MinimalCampaign from '@/domains/campaign/MinimalCampaign';
import CampaignArticle from '@/domains/campaign/CampaignArticle';
import SalesCampaign from '@/domains/campaign/SalesCampaign';
import SalesCampaignArticleVariant from '@/domains/campaign/SalesCampaignArticleVariant';


export interface CampaignState {
  campaigns: NormalizedData<Campaign>;
  salesCampaigns: NormalizedData<SalesCampaign>;
  minimalCampaigns: NormalizedData<MinimalCampaign>;
  listInfo: ListInfo;
  campaignArticles: NormalizedData<CampaignArticle>;
  campaignArticleListInfo: ListInfo;
  salesCampaignArticleVariants: NormalizedData<SalesCampaignArticleVariant>;
  currentEditingCampaign: Campaign;
  currentSalesCampaign: Campaign;
}

const state: CampaignState = {
  campaigns: {},
  salesCampaigns: {},
  minimalCampaigns: {},
  listInfo: ListInfo.create(),
  campaignArticles: {},
  campaignArticleListInfo: ListInfo.create(),
  salesCampaignArticleVariants: {},
  currentEditingCampaign: Campaign.create({}),
  currentSalesCampaign: undefined,
};

export default state;
