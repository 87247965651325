import { GetterTree } from 'vuex';
import { TagState } from './state';
import NormalizedData from '@/types/NormalizedData';
import Tag from '@/domains/tag/Tag';
import { ListInfo } from '@/types/ListInfo';


type TagGetter = (id: string) => Tag;

export const getTag = (state: TagState): TagGetter => (id: string): Tag => {
  return state.tags[id];
};

export const getTags = (state: TagState): NormalizedData<Tag> => {
  return state.tags;
};

export const getTagListInfo = (state: TagState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<TagState, any> = {
  getTag,
  getTags,
  getTagListInfo,
};

export default getters;
