import { MutationTree } from 'vuex';
import { MediaObjectState } from './state';
import NormalizedData from '@/types/NormalizedData';
import MediaObject from '@/domains/mediaObject/MediaObject';


export const addMediaObject = (
  state: MediaObjectState,
  mediaObject: NormalizedData<MediaObject>,
): void => {
  state.mediaObject = {
    ...state.mediaObject,
    ...mediaObject,
  };
};

export const setMediaObject = (
  state: MediaObjectState,
  mediaObject: NormalizedData<MediaObject>,
): void => {
  state.mediaObject = {
    ...mediaObject,
  };
};

const mutations: MutationTree<MediaObjectState> = {
  addMediaObject,
  setMediaObject,
};

export default mutations;
