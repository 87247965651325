export default (response: any) => {
  if (response.status === 204 || response.status === 202) {
    return;
  }
  return new Promise((resolve) => {
    response.text().then((text: string) => {
      try {
        JSON.parse(text);
        resolve(JSON.parse(text));
      } catch {
        resolve(text);
      }
    });
  });
};
