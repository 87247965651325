import Component from 'vue-class-component';
import TsxComponent from '@/typeDefinitions/vue-tsx';

interface Props {
  value: string;
  id?: string;
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  min?: string;
  max?: string;
  maxlength?: number;
  autofocus?: boolean;
  resettable?: boolean;
  autocomplete?: string;
}

@Component({
  props: {
    value: String,
    id: String,
    type: { type: String, default: 'text' },
    placeholder: String,
    disabled: Boolean,
    min: String,
    max: String,
    maxlength: Number,
    autofocus: Boolean,
    resettable: Boolean,
    autocomplete: String,
    dataQa: String,
  },
})
class Input extends TsxComponent<Props> {
  public render() {
    const {
      value,
      id,
      type,
      placeholder,
      disabled,
      min,
      max,
      maxlength,
      autofocus,
      resettable,
      autocomplete,
      dataQa,
    } = this.$props;

    const htmlType = type === 'search' ? 'text' : type;
    const isDateField = htmlType === 'date';
    const resettableInputSelector =
      resettable && value && value !== ''
        ? 'resettableInput'
        : 'resettableInputPlaceholder';

    return (
      // TODO: replace this with an element that won't get rendered to the DOM
      <div class={`styled-input ${resettableInputSelector}`}>
        <input
          class="form-control"
          id={id}
          type={htmlType}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          min={min}
          max={max ? max : isDateField && '9999-12-31'}
          autofocus={autofocus}
          onInput={(event) => this.$emit('input', event)}
          onFocus={(event) => this.$emit('focus', event)}
          onBlur={(event) => this.$emit('blur', event)}
          onPaste={(event) => this.$emit('paste', event)}
          maxlength={maxlength}
          autocomplete={autocomplete}
          data-qa={dataQa}
        />
        {type === 'date' && (!value || !resettable) && (
          <div class="form-control__icon-wrapper">
            <font-awesome-icon
              icon={['far', 'calendar-alt']}
              data-qa={
                id && id.includes('endOfLife') ? 'btnEolDate' : 'btnCalendar'
              }
            />
          </div>
        )}
        {type === 'search' && (
          <div class="form-control__icon-wrapper">
            <font-awesome-icon icon={['fa', 'search']} />
          </div>
        )}
        {value !== '' && value !== undefined && resettable && (
          <div
            onClick={(event) => this.$emit('reset', event)}
            class="form-control__icon-wrapper pointer-events-all"
          >
            <font-awesome-icon icon={['fa', 'times-circle']} />
          </div>
        )}
      </div>
    );
  }
}

export default Input;
