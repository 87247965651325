import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';
import Mandant from '@/domains/mandant/Mandant';

const createMandant: PushSingleAction<Mandant> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Mandant>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/mandants`,
    mutation: 'addMandants',
  });
};

export default createMandant;
