import Domain from '@/types/Domain';
import CodeType from '@/types/CodeType';


interface Props {
  type: CodeType;
  quantity?: number;
  updateQuantity?: string;
  redemptionQuantity?: number;
  quantityUpdated?: string;
}

class CampaignCodeProperties extends Domain implements Props {
  public static createAll(codeProperties: any[]): CampaignCodeProperties[] {
    return codeProperties.map(CampaignCodeProperties.create);
  }

  public static create(data: any): CampaignCodeProperties {
    return new CampaignCodeProperties(
      typeof data.type === 'string' ? CodeType.getById(data.type) : data.type,
      data.quantity,
      data.updateQuantity,
      data.redemptionQuantity,
      data.quantityUpdated,
    );
  }

  private constructor(
    public type: CodeType = CodeType.createDefault(),
    public quantity: number,
    public updateQuantity: string,
    public redemptionQuantity: number,
    public quantityUpdated: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): CampaignCodeProperties {
    return CampaignCodeProperties.create(JSON.parse(JSON.stringify(this)));
  }

  private formatNumber(value: number = null): string {
    if (value != null && typeof value === 'number') {
      return value.toLocaleString()
    } else {
      return ''
    }
  }

  public toUIString(): string {
    let codeQuantityAsNumber;
    if(typeof this.quantity === 'string') {
      codeQuantityAsNumber = parseInt(this.quantity, 10);
    } else {
      codeQuantityAsNumber = this.quantity;
    }
    const codeQuantityString = this.formatNumber(codeQuantityAsNumber);
    const calculatedQuantity = this.quantity + parseInt(this.updateQuantity);
    const userUpdatedQuantityString = this.formatNumber(calculatedQuantity);

    if (this.type && this.type.id) {
      switch (this.type.id) {
        case CodeType.GENERIC_UNLIMITED.id:
          return `1 Code / ∞ mal einlösbar`;

        case CodeType.GENERIC_LIMITED.id:
          return `1 Code / ${this.updateQuantity ? userUpdatedQuantityString : codeQuantityString} mal einlösbar`;

        case CodeType.SINGLE_USAGE.id:
        default:
          return `${this.updateQuantity ? userUpdatedQuantityString : codeQuantityString} Stk.`;
      }
    } else {
      return '';
    }
  }
}

export default CampaignCodeProperties;
