import { GetterTree } from 'vuex';
import { CampaignReportState } from './state';
import { ListInfo } from '@/types/ListInfo';
import MinimalCampaignReport from '@/domains/report/MinimalCampaignReport';
import NormalizedData from '@/types/NormalizedData';
import CampaignReport from '@/domains/report/CampaignReport';

type CampaignReportGetter = (id: string) => CampaignReport;

const getCampaignReport = (state: CampaignReportState): CampaignReportGetter => {
  return (id: string): CampaignReport => {
    return state.campaignReports[id];
  };
};

export const getCampaignReports = (state: CampaignReportState): NormalizedData<CampaignReport> => {
  return state.campaignReports;
};

export const getMinimalCampaignReports = (state: CampaignReportState): NormalizedData<MinimalCampaignReport> => {
  return state.minimalCampaignReports;
};

export const getCampaignReportListInfo = (state: CampaignReportState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<CampaignReportState, any> = {
  getCampaignReport,
  getCampaignReports,
  getMinimalCampaignReports,
  getCampaignReportListInfo,
};

export default getters;
