import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import state, { MandantState } from './state';
import actions from './actions';

const mandantModule: Module<MandantState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
export default mandantModule;
