import Domain from '@/types/Domain';
import SalesCampaignMessagingProperties from '@/domains/campaign/SalesCampaignMessagingProperties';


interface Props {
  id: string;
  name: string;
  startDate: Date;
  endDate: Date;
  description: string;
  marketingEndDate: Date;
  redemptionDelay?: number;
  externalId?: string;
  messagingProperties?: SalesCampaignMessagingProperties;
  customMessagingEnabled?: boolean;
}

class SalesCampaign extends Domain implements Props {
  public static createAll(campaigns: any[]): SalesCampaign[] {
    return campaigns.map(SalesCampaign.create);
  }

  public static create(data: any): SalesCampaign {
    return new SalesCampaign(
      data.id,
      data.name,
      data.startDate && new Date(data.startDate),
      data.endDate && new Date(data.endDate),
      data.description,
      data.marketingEndDate && new Date(data.marketingEndDate),
      data.redemptionDelay,
      data.externalId,
      SalesCampaignMessagingProperties.create(data.messagingProperties),
      data.customMessagingEnabled || false,
    );
  }

  private constructor(
    public id: string,
    public name: string,
    public startDate: Date,
    public endDate: Date,
    public description: string,
    public marketingEndDate: Date,
    public redemptionDelay: number,
    public externalId: string,
    public messagingProperties: SalesCampaignMessagingProperties,
    public customMessagingEnabled: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): SalesCampaign {
    return SalesCampaign.create(JSON.parse(JSON.stringify(this)));
  }
}

export default SalesCampaign;
