import { MutationTree } from 'vuex';
import { ArticleState } from './state';
import { ListInfo } from '@/types/ListInfo';
import Article from '@/domains/article/Article';
import MinimalArticle from '@/domains/article/MinimalArticle';
import NormalizedData from '@/types/NormalizedData';

export const addArticles = (
  state: ArticleState,
  articles: NormalizedData<Article>,
): void => {
  state.articles = {
    ...state.articles,
    ...articles,
  };
};

export const setMinimalArticles = (
  state: ArticleState,
  minimalArticles: NormalizedData<MinimalArticle>,
): void => {
  state.minimalArticles = {
    ...minimalArticles,
  };
};

export const setArticleListInfo = (
  state: ArticleState,
  info: ListInfo,
): void => {
  state.listInfo = info;
};

export const mutations: MutationTree<ArticleState> = {
  addArticles,
  setMinimalArticles,
  setArticleListInfo,
};

export default mutations;
