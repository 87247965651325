import Domain from '@/types/Domain';
import Campaign from '@/domains/campaign/Campaign';
import { ListInfo } from '@/types/ListInfo';

interface Props {
  id: string;
  name: string;
  description: string;
  type: 'GROUP_SALES';
  owner: string;
  users?: string[];
  usersCount?: number;
  campaigns?: Campaign[];
  userListInfo?: ListInfo;
}

class UserGroup extends Domain implements Props {
  public static createAll(users: any[]): UserGroup[] {
    return users.map(UserGroup.create);
  }

  public static create(data: any): UserGroup {

    return new UserGroup(
      data.id,
      data.name,
      data.description,
      data.type,
      data.owner,
      data.users,
      data.usersCount,
      data.campaigns,
      data.userListInfo,
    );
  }

  private constructor(
    public id: string,
    public name: string,
    public description: string,
    public type: 'GROUP_SALES',
    public owner: string,
    public users?: string[],
    public usersCount?: number,
    public campaigns?: Campaign[],
    public userListInfo?: ListInfo,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      id: {
        presence: false,
      },
      name: {
        presence: {
          message: '^Bitte geben Sie einen Gruppennamen an',
        },
      },
      description: {
        presence: {
          message: '^Bitte geben Sie eine Gruppenbeschreibung an',
        },
        format: {
          pattern: /^(.|\r\n|\r|\n){3,50}$/,
          message: '^Gruppenbeschreibung muss zwischen 3 und 50 Zeichen lang sein',
        },
      },
      owner: {
        presence: {
          message: '^Bitte wählen Sie den Sales-Manager aus',
        },
      },
    };
  }

  public clone(): UserGroup {
    return UserGroup.create(JSON.parse(JSON.stringify(this)));
  }
}

export default UserGroup;
