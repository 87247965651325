import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import {CodeDispatchState} from '../state';
import createCodeDispatch from './createCodeDispatch';
import fetchCodeDispatch from './fetchCodeDispatch';
import fetchCodeDispatchList from './fetchCodeDispatchList';
import updateCodeDispatch from './updateCodeDispatch';


const actions: ActionTree<CodeDispatchState, RootState> = {
  createCodeDispatch,
  fetchCodeDispatch,
  fetchCodeDispatchList,
  updateCodeDispatch,
};

export default actions;
