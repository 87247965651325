import Domain from '@/types/Domain';
import Money from '@/domains/common/Money';
import Color from '@/domains/common/Color';
import ArticleImage from './ArticleImage';
import fillArray from '@/utils/fillArray';
import sortByProp from '@/utils/sortByProp';

type WeightUnit = 'g' | 'kg';

interface Props {
  active: boolean;
  id: string;
  ekEbootisId: string;
  mkEbootisId: string;
  name: string;
  buyPrice: Money;
  articlePrice: Money;
  advertisingCostsSubsidy: Money;
  endOfLife: string; // needs to be date.
  ean: string;
  weight: string;
  weightUnit: WeightUnit;
  color: Color;
  unitFee: Money;
  bulletPoints: string[];
  description: string;
  images: ArticleImage[];
}

class ArticleVariant extends Domain implements Props {
  public static MAX_BULLET_POINTS_AMOUNT: number = 5;

  public static createAll(articles: any[]): ArticleVariant[] {
    return articles.map(ArticleVariant.create);
  }

  public static create(data: any): ArticleVariant {
    return new ArticleVariant(
      data.active,
      data.id,
      data.ekEbootisId,
      data.mkEbootisId,
      data.name,
      data.buyPrice,
      data.articlePrice,
      data.advertisingCostsSubsidy,
      data.endOfLife,
      data.ean,
      data.weight,
      data.weightUnit,
      data.color || Color.create({}),
      data.unitFee,
      fillArray(data.bulletPoints, ArticleVariant.MAX_BULLET_POINTS_AMOUNT, ''),
      data.description,
      ArticleImage.createAll(sortByProp(data.images || [], 'order')),
    );
  }

  private constructor(
    public active: boolean,
    public id: string,
    public ekEbootisId: string,
    public mkEbootisId: string,
    public name: string,
    public buyPrice: Money,
    public articlePrice: Money,
    public advertisingCostsSubsidy: Money,
    public endOfLife: string,
    public ean: string,
    public weight: string,
    public weightUnit: WeightUnit,
    public color: Color,
    public unitFee: Money,
    public bulletPoints: string[],
    public description: string,
    public images: ArticleImage[],
  ) {
    super();
  }

  public constraints(): {} {
    const bulletPointConstraints = {};
    for (let i = 0; i < ArticleVariant.MAX_BULLET_POINTS_AMOUNT; i++) {
      bulletPointConstraints[`bulletPoints.${i}`] = {
        format: {
          pattern: /^$|^.{3,80}$/,
          message: '^Ein Bullet Point muss zwischen 3 und 80 Zeichen lang sein',
        },
      };
    }

    return {
      ...bulletPointConstraints,
      color: {
        presence: true,
      },
      'color.colorCode': {
        presence: {
          message: '^Farbcode ist ein Pflichtfeld',
        },
        format: {
          pattern: /^#(?:[0-9a-fA-F]{3}){1,2}$/,
          message: '^Der Farbcode muss im Hex-Format sein',
        },
      },
      'color.colorName': {
        presence: {
          message: '^Gerätefarbe ist ein Pflichtfeld',
        },
      },
      description: {
        format: {
          pattern: /^$|^(.|\r\n|\r|\n){3,10000}$/,
          message: '^Beschreibung muss zwischen 3 und 10.000 Zeichen lang sein',
        },
      },
      ekEbootisId: {
        presence: {
          message: '^EK-ebootis-ID ist ein Pflichtfeld',
        },
        format: {
          pattern: /^[a-zA-Z0-9\-]{1,12}$/,
          message: '^EK-ebootis-ID muss aus 1 - 12 alphanumerischen Zeichen bestehen',
        },
      },
      mkEbootisId: {
        presence: {
          message: '^MK-ebootis-ID ist ein Pflichtfeld',
        },
        format: {
          pattern: /^[a-zA-Z0-9\-]{1,12}$/,
          message: '^MK-ebootis-ID muss aus 1 - 12 alphanumerischen Zeichen bestehen',
        },
      },
      endOfLife: {
        presence: {
          message: '^EOL ist ein Pflichtfeld',
        },
      },
      images: {
        length: {
          maximum: 3,
          message: '^Es dürfen maximal 3 Bilder hochgeladen werden',
        },
      },
      name: {
        presence: {
          message: '^Artikelbezeichnung ist ein Pflichtfeld',
        },
      },
    };
  }

  public clone(): ArticleVariant {
    const clone = JSON.parse(JSON.stringify(this));
    return ArticleVariant.create({
      ...JSON.parse(JSON.stringify(this)),
      images: clone.images.map((image, index) => ({
        ...image,
        file: this.images[index].file,
      })),
    });
  }
}

export default ArticleVariant;
