import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import MandantCategories from '@/domains/category/MandantCategories';

const attachMandantCategory: PushSingleAction<MandantCategories> = async (
  { commit, dispatch },
  payload: PushSinglePayload<MandantCategories>,
) => {
  const { fetch, domain } = payload;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/mandant/portfolio`,
    mutation: undefined,
  });
};

export default attachMandantCategory;
