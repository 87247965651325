import Domain, { ValidateOptions } from '@/types/Domain';
import { emailAddress, securePassword } from '@/utils/validations';
import Gender from '@/types/Gender';


interface Props {
  active: boolean;
  id: string;
  department: string;
  gender: Gender;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  roles: string[];
  mandantName?: string;
  password?: string;
  passwordConfirm?: string;
  oldPassword?: string;
}

export type UserFormType = 'create' | 'edit' | 'profile';

interface UserValidateOptions extends ValidateOptions {
  type: UserFormType;
  passwordChange: boolean;
}

class User extends Domain implements Props {
  public static createAll(users: any[]): User[] {
    return users.map(User.create);
  }

  public static create(data: any): User {
    const correctedActive: boolean =
      typeof(data.active) === 'string' ?
        data.active === '1' :
        typeof(data.active) === 'undefined' ?
        true :
        data.active;

    return new User(
      correctedActive,
      data.id,
      data.email,
      data.gender,
      data.firstName,
      data.lastName,
      data.password,
      data.passwordConfirm,
      data.oldPassword,
      data.department,
      data.phone,
      data.roles || [],
      data.mandantName,
    );
  }

  private constructor(
    public active: boolean,
    public id: string,
    public email: string,
    public gender: Gender,
    public firstName: string,
    public lastName: string,
    public password: string,
    public passwordConfirm: string,
    public oldPassword: string,
    public department: string,
    public phone: string,
    public roles: string[],
    public mandantName: string,
  ) {
    super();
  }

  public validate(
    customConstraints?: () => {},
    options?: UserValidateOptions,
  ): boolean {
    if (customConstraints) {
      return super.validate(customConstraints, options);
    }

    let constraints;
    if (options && options.type === 'profile') {
      if (options.passwordChange) {
        // case 1: edit profile + change password
        constraints = () => this.constraintsChangePasswordProfile();
      } else {
        // case 2: edit profile without changing password
        constraints = () => this.constraintsEditProfile();
      }
    } else {
      // case 3: edit user
      constraints = () => this.constraints();
    }

    return super.validate(constraints, options);
  }

  public constraintsSetPassword(): {} {
    return {
      ...this.constraints(),
      password: {
        format: {
          pattern: securePassword,
          message: '^Das eingegebene Passwort muss den Sicherheitsrichtlinien entsprechen',
        },
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
      passwordConfirm: {
        equality: {
          attribute: 'password',
          message: '^Die Passwörter müssen übereinstimmen',
        },
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
    };
  }

  public constraintsEditProfile(): {} {
    return {
      ...this.constraints(),
      oldPassword: {
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
    };
  }

  public constraintsChangePasswordProfile(): {} {
    return {
      ...this.constraintsEditProfile(),
      ...this.constraintsSetPassword(),
    };
  }

  public constraints(): {} {
    // Here we return the default constraints for a user. We exclude everything needed for an password reset.
    return {
      active: {
        presence: false,
      },
      id: {
        presence: false,
      },
      email: {
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
        format: {
          pattern: emailAddress,
          message: '^Dieses Feld muss eine E-Mail Adresse enthalten',
        },
      },
      gender: {
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
      firstName: {
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
      lastName: {
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
      department: {
        presence: false,
      },
      phone: {
        format: {
          pattern: /^$|^0[0-9]{5,26}$/,
          message: '^Muss mit 0 beginnen und darf 6 bis 27 Zeichen enthalten.',
        },
      },
      roles: {
        length: {
          minimum: 1,
          message: '^Es muss mindestens eine Rolle ausgewählt werden',
        },
        presence: {
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
    };
  }

  public clone(): User {
    return User.create(JSON.parse(JSON.stringify(this)));
  }
}

export default User;
