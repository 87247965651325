import MinimalMessage from '@/domains/message/MinimalMessage';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';
import Message from '@/domains/message/Message';

const deleteMessage: PushSingleAction<MinimalMessage> = async (
    { commit, dispatch },
    payload: PushSinglePayload<MinimalMessage>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };
  commit('setHandleMessage', Message.createDefault());

  return await pushSingle({ commit, dispatch }, 'DELETE', pushPayload, {
    url: `/notifications/${id}`,
    mutation: undefined,
  });
};

export default deleteMessage;
