import fetchList, { FetchListAction, FetchListPayload, FetchMandantSpecificListPayload } from '@/utils/api-platform/fetchList';
import MinimalUser from '@/domains/mandant/MinimalUser';
import { ListFilter } from '@/types/ListFilter';

export interface FetchAssignableOwnersListPayload extends FetchMandantSpecificListPayload {
  role: string;
}

const fetchAssignableOwnersList: FetchListAction<MinimalUser> = async (
  { commit, dispatch, rootGetters },
  fetchUserListPayload: FetchAssignableOwnersListPayload,
) => {
  const {
    fetch,
    page,
    limit,
    sorts,
    role,
    pagination,
  } = fetchUserListPayload;

  const filters: ListFilter[] = [{ field: 'roles', query: role }];

  const fetchListPayload: FetchListPayload = {
    fetch,
    page,
    limit,
    filters,
    sorts,
    pagination,
  };
  const url = `/users`;

  return await fetchList({ commit, dispatch }, fetchListPayload, {
      url,
      domainParser: MinimalUser.create,
      listMutation: 'setAssignableOwners',
      listInfoMutation: 'setAssignableOwnerListInfo',
    });
};

export default fetchAssignableOwnersList;
