import RemainingCodes from '@/domains/cockpit/RemainingCodes';


export interface CockpitState {
  remainingCodes: RemainingCodes;
}

const state: CockpitState = {
  remainingCodes: RemainingCodes.create(0),
};

export default state;
