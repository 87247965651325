import Enum from './Enum';

interface Props {
  icon: string;
  filterLabel?: string;
  actionLabel?: string;
  actionDescription?: string;
  actionDescriptionPastTense?: string;
  dataQa?: string;
}

export default class OrderStatus extends Enum implements Props {
  public static readonly VERIFY = new OrderStatus(
    'VERIFY',
    'folder-open',
    'Offene Aufträge',
    null,
    null,
    null
  );
  /* tslint:disable-next-line: max-line-length */
  public static readonly PARKED = new OrderStatus(
    'PARKED',
    'inbox',
    'In Wiedervorlage',
    'Wiedervorlage',
    'auf Wiedervorlage setzen',
    'auf Wiedervorlage gesetzt',
    'btnResub'
  );
  public static readonly VERIFIED = new OrderStatus(
    'VERIFIED',
    'check-circle',
    null,
    'Freigeben',
    'freigeben',
    'freigegeben',
    'btnApprove'
  );
  public static readonly CANCELED = new OrderStatus(
    'CANCELED',
    'times-circle',
    null,
    'Ablehnen',
    'ablehnen',
    'abgelehnt',
    'btnDecline'
  );
  public static readonly VERIFY_PAYMENT = new OrderStatus(
    'VERIFY_PAYMENT',
    '',
    null,
    null,
    null
  );
  public static readonly VERIFY_ORDER_AND_PAYMENT = new OrderStatus(
    'VERIFY_ORDER_AND_PAYMENT',
    '',
    null,
    null,
    null
  );

  public static readonly ALL = [
    OrderStatus.VERIFY,
    OrderStatus.PARKED,
    OrderStatus.VERIFIED,
    OrderStatus.CANCELED,
    OrderStatus.VERIFY_PAYMENT,
    OrderStatus.VERIFY_ORDER_AND_PAYMENT,
  ];

  /**
   * All states lists should be filterable by
   */
  public static readonly FILTERS = [OrderStatus.VERIFY, OrderStatus.PARKED];

  /**
   * All states that should be reachable by actions
   */
  public static readonly ACTIONS = [
    OrderStatus.CANCELED,
    OrderStatus.VERIFIED,
    OrderStatus.PARKED,
  ];

  private constructor(
    public id: string,
    public icon: string,
    public filterLabel?: string,
    public actionLabel?: string,
    public actionDescription?: string,
    public actionDescriptionPastTense?: string,
    public dataQa?: string
  ) {
    super();
  }
}
