import Enum from './Enum';

interface Props {
  label: string;
}

export default class ToastNotificationStatus extends Enum implements Props {
  public static readonly SUCCESS = new ToastNotificationStatus('SUCCESS', 'Erfolg');
  public static readonly INFO = new ToastNotificationStatus('INFO', 'Information');
  public static readonly WARNING = new ToastNotificationStatus('WARNING', 'Warnung');
  public static readonly ERROR = new ToastNotificationStatus('ERROR', 'Fehler');

  public static readonly ALL = [
    ToastNotificationStatus.SUCCESS,
    ToastNotificationStatus.INFO,
    ToastNotificationStatus.WARNING,
    ToastNotificationStatus.ERROR,
  ];


  private constructor(
    public id: string,
    public label: string,
  ) {
    super();
  }
}
