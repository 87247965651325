import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { MessageState } from '../state';
import createMessage from './createMessage';
import fetchMessage from './fetchMessage';
import deleteMessage from './deleteMessage';
import editMessage from './editMessage';
import fetchAdminMessageList from './fetchAdminMessageList';
import fetchUserMessageList from './fetchUserMessageList';
import setMessageRead from './setMessageRead';
import fetchUserMessageInbox from './fetchUserMessageInbox';


const actions: ActionTree<MessageState, RootState> = {
  createMessage,
  editMessage,
  deleteMessage,
  fetchMessage,
  setMessageRead,
  fetchAdminMessageList,
  fetchUserMessageList,
  fetchUserMessageInbox,
};

export default actions;
