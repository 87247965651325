import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import classNames from 'classnames';
import styles from './TabbedPanel.module.scss';

export interface TabbedPanelItem {
  title: string;
  // if loadContent prop is set, this will be set asynchronously
  content: object;
  showToolbar: boolean;
}

interface Props {
  items: TabbedPanelItem[];
  // this shall set the content prop of the item param
  loadContent?: (item: TabbedPanelItem, index: number) => Promise<void>;
  infoText?: string;
  hasExampleDocument?: boolean;
}

@Component({
  props: {
    items: Array,
    loadContent: Function,
    infoText: String,
    hasExampleDocument: Boolean,
  },
})
class TabbedPanel extends TsxComponent<Props> {
  // private ZOOM_STEP_FACTOR: number = Math.pow(2, 1 / 3);
  private tabContent: HTMLElement;
  private activeItem: TabbedPanelItem;

  private async setActiveItem(index: number): Promise<void> {
    const { items, loadContent } = this.$props;

    this.activeItem = items[index];

    if (items[index].content === undefined && loadContent !== undefined) {
      await loadContent(items[index], index);
    }
    this.$forceUpdate();

    this.$nextTick(() => {
      // this.resetZoom();
      this.resetRotation();
    });
  }

  // private zoomIn(): void {
  //   if (!this.tabContent) {
  //     return;
  //   }

  //   const currentZoom = this.tabContent.style.zoom || '1';
  //   const newZoom = parseFloat(currentZoom) * this.ZOOM_STEP_FACTOR;

  //   this.setZoom(newZoom);
  // }

  // private zoomOut(): void {
  //   if (!this.tabContent) {
  //     return;
  //   }

  //   const currentZoom = this.tabContent.style.zoom || '1';
  //   const newZoom = parseFloat(currentZoom) / this.ZOOM_STEP_FACTOR;

  //   this.setZoom(newZoom);
  // }

  // private resetZoom(): void {
  //   this.setZoom(1);
  // }

  // private setZoom(zoomLevel: number): void {
  //   if (!this.tabContent) {
  //     return;
  //   }

  //   this.tabContent.style.zoom = zoomLevel  + '';
  // }

  private rotateItem(): void {
    if (!this.tabContent) {
      return;
    }

    const transform = this.tabContent.style.transform || 'rotate(0deg)';
    // get first number in string
    const rotation = parseInt(transform.match(/\d+/)[0]);
    const newRotation = rotation + 90 % 360;

    this.tabContent.style.transform = newRotation ? `rotate(${newRotation}deg)` : '';
  }

  private resetRotation(): void {
    this.tabContent = document.querySelector('#tabbed-panel__content img');
    if (!this.tabContent) {
      return;
    }

    this.tabContent.style.transform = '';
  }

  private created() {
    this.setActiveItem(0);
  }

  private render() {
    const { activeItem } = this;
    const {
      items,
      infoText,
      hasExampleDocument,
    } = this.$props;

    return (
      <div class={styles['tabbed-panel']}>
        <div class={styles['tabbed-panel__tabs']}>
          {items.map((item, index) => (
            <div
              class={classNames(
                styles['tabbed-panel__tabs__item'],
                {
                  [styles['is-active']]: activeItem && activeItem.title === item.title,
                },
              )}
              onClick={() => this.setActiveItem(index)}
            >
              {item.title}
            </div>
          ))}
        </div>

        <div
          id="tabbed-panel__content"
          class={styles['tabbed-panel__content']}
        >
          {activeItem && activeItem.content}
        </div>

        {infoText && (
          <div
            class={classNames(
              styles['tabbed-panel__content'],
              styles['tabbed-panel__content--smaller-padding'],
            )}
          >
            <div class="alert alert--small alert--information" role="alert">
              <span>
                {infoText}
              </span>
            </div>
          </div>
        )}

        {activeItem && (activeItem.showToolbar || hasExampleDocument) && (
          <div class={styles['tabbed-panel__tool-bar']}>
            {/* <div class={classNames(
              styles['tabbed-panel__tool-bar__zoom'],
            )}>
              <font-awesome-icon
                class={classNames(
                  styles['tabbed-panel__tool-bar__tool'],
                  styles['tabbed-panel__tool-bar__tool--zoom-in'],
                )}
                icon="search-plus"
                onClick={this.zoomIn}
              />
              <font-awesome-icon
                class={classNames(
                  styles['tabbed-panel__tool-bar__tool'],
                  styles['tabbed-panel__tool-bar__tool--zoom-out'],
                  'ml-3',
                )}
                icon="search-minus"
                onClick={this.zoomOut}
              />
            </div> */}

            <div class={styles['tabbed-panel__tool-bar__right-col']}>
              {hasExampleDocument && (
                <div
                  class={styles['tabbed-panel__tool-bar__example-document-link']}
                  onClick={() => this.$emit('click')}
                >
                  Beispieldokument
                </div>
              )}

              {activeItem.showToolbar && (
                <font-awesome-icon
                  class={styles['tabbed-panel__tool-bar__tool']}
                  icon="redo-alt"
                  onClick={this.rotateItem}
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TabbedPanel;
