import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import MinimalCampaignReport from '@/domains/report/MinimalCampaignReport';

const fetchMinimalCampaignReportList: FetchListAction<MinimalCampaignReport> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let url;
  if (rootGetters.isMandant) {
    url = `/my/reporting/campaign`;
  } else {
    const simulatedMandantId = rootGetters.getSimulatedMandantId;
    if (simulatedMandantId) {
      url = `/reporting/${simulatedMandantId}/campaign`;
    } else {
      commit('setMinimalCampaignReports', {});

      return;
    }
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: MinimalCampaignReport.create,
    listMutation: 'setMinimalCampaignReports',
    listInfoMutation: 'setMinimalCampaignReportListInfo',
  });
};

export default fetchMinimalCampaignReportList;
