import Message from '@/domains/message/Message';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';


const createMessage: PushSingleAction<Message> = async (
    { commit, dispatch },
    payload: PushSinglePayload<Message>,
) => {
  const { fetch, domain } = payload;
  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/notifications`,
    mutation: 'addMessages',
  }).then((response: any) => {
    commit (
      'setHandleMessage',
      {
        ...payload.domain,
        startDate: new Date(payload.domain.startDate),
        endDate: new Date(payload.domain.endDate),
      },
    );
  });
};

export default createMessage;
