import { CategoryState } from '@/store/modules/category/state';
import { ListInfo } from '@/types/ListInfo';

const setCategoryVariantListInfo = (
  state: CategoryState,
  info: ListInfo,
): void => {
  state.variantListInfo = info;
};

export default setCategoryVariantListInfo;
