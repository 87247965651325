import NormalizedData from '@/types/NormalizedData';

export interface WithId {
  id: string;
}

export type UnnormalizedData<T extends WithId> = T;

const reducer = <T extends WithId>(
  identifier: string,
  acc: NormalizedData<T>,
  entry: UnnormalizedData<T>,
): NormalizedData<T> => ({ ...acc, [entry[identifier]]: entry });


const normalizeData = <T extends WithId>(
  data: T[UnnormalizedData<any>],
  identifier: string = 'id',
): NormalizedData<T> =>
  // TODO: Analyse the hereby suppressed warning: "untyped function-calls may not accept args.."
  // @ts-ignore
  data.reduce<NormalizedData<T>>(reducer.bind(null, identifier), {});

export default normalizeData;
