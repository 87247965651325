import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';
import classNames from 'classnames';
import styles from '../NewPopup.module.scss';
import { PopupProps } from '@/types/PopupPayload';


@Component({
  props: {
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Schließen',
    },
    color: String,
    icon: String,
    type: {
      type: String,
      default: 'hint',
    },
    className: String,
  },
})
class ConfirmPopup extends TsxComponent<PopupProps> {
  @Action('closeActivePopup')
  private closeActivePopup!: () => void;

  private onConfirm(): void {
    this.closeActivePopup();
    this.$emit('confirm');
  }

  private onClose(): void {
    this.closeActivePopup();
    this.$emit('close');
  }

  private render() {
    const {
      title,
      description,
      buttonText,
      color,
      icon,
      className,
    } = this.$props;

    return (
      <div class={classNames(
        styles['popup__wrapper'],
        className,
        color && color === 'info' && styles['popup__wrapper-info'],
        color && color === 'error' && styles['popup__wrapper-error'],
        color && color === 'warning' && styles['popup__wrapper-warning'],
      )}>
        {icon && (
          <div class={classNames(
            styles['popup__wrapper__circle'],
            color && color === 'info' && styles['popup__wrapper__circle-info'],
            color && color === 'error' && styles['popup__wrapper__circle-error'],
            color && color === 'warning' && styles['popup__wrapper__circle-warning'],
          )}>
            <font-awesome-icon
              class="fa-lg"
              icon={icon}
            />
          </div>
        )}
        {title && (
          <h1
            class={classNames(
              styles['popup__wrapper__heading'],
              'headline-size--100',
            )}
          >
            {title}
          </h1>
        )}
        <div
          class={
            classNames(
              styles['popup__wrapper__content'],
              'text-center',
              'mt-4',
              )}
          domPropsInnerHTML={description}
        >
          {this.$slots.default}
        </div>
        <button
          onClick={this.onConfirm}
          class={classNames(
            styles['popup__wrapper__action'],
            'btn',
            'btn-dark',
            'btn-block',
          )}
        >
          {buttonText}
        </button>
        <button
          onclick={this.onClose}
          class={classNames(
            styles['popup__wrapper__action'],
            'btn',
            'btn-default',
            'btn-block',
          )}
        >
          Abbrechen
        </button>
      </div>
    );
  }
}

export default ConfirmPopup;
