import Domain from '@/types/Domain';
import TextTemplate from '@/domains/textTemplate/TextTemplate';

interface Props {
  senderName: string;
  subject: string;
  text: TextTemplate;
}

class SalesCampaignMessage extends Domain implements Props {
  public static createAll(salesCampaignMessages: any[]): SalesCampaignMessage[] {
    return salesCampaignMessages.map(SalesCampaignMessage.create);
  }

  public static create(data: any): SalesCampaignMessage {
    if (typeof data.text === 'object') {
      return new SalesCampaignMessage(
        data.senderName,
        data.subject,
        TextTemplate.create(data.text || {}),
      );
    } else {
      return new SalesCampaignMessage(
        data.senderName,
        data.subject,
        TextTemplate.create({
          listOfVars: [],
          templateText: data.text,
        }),
      );
    }
  }

  private constructor(
    public senderName: string,
    public subject: string,
    public text: TextTemplate,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): SalesCampaignMessage {
    return SalesCampaignMessage.create(JSON.parse(JSON.stringify(this)));
  }
}

export default SalesCampaignMessage;
