export interface ConstraintError {
  attribute: string;
  validator: string;
  globalOptions: {};
  attributes: {};
  options: boolean;
  error: string;
}

interface IndexableObject {
  [key: string]: any;
}

// Converts plain error (address.street.name) to deeply nested structure
// so the same handler as api error handler can consume this
const createNestedError = (
  object: IndexableObject = {},
  initialAttribute: string,
  errorMessage: string,
): {} => {
  const result = { ...object };

  // we consume strings like: address.street.name and need to make them accessible
  const attributes = initialAttribute.split('.');

  // will return first entry (address) and mutates the attributes array to be ['street', 'name]
  const attribute = attributes.shift();

  // for typescripts sake
  if (!attribute) {
    return {};
  }

  // initiate or take attribute object (result['address'] = {})
  result[attribute] = result[attribute] || {};

  if (attributes.length > 0) {
    // will make array ['street', 'name'] to 'street.name' again so the same logic above can handle
    const plainAttributes = attributes.join('.');

    // there is at least one nesting level available.
    // do the work again for each left nesting level.
    result[attribute].children = createNestedError(
      result[attribute].children || {},
      plainAttributes,
      errorMessage,
    );
  } else {
    // reached the end - no more nesting levels left
    result[attribute].errors = [errorMessage];
  }

  return result;
};

const constraintAPIFormatter = (
  errors: ConstraintError[],
): { [key: string]: {} } => {
  let result: { [key: string]: {} } = {};

  errors.forEach(({ attribute, error }) => {
    if (attribute.indexOf('.') > -1) {
      // attribute contains at least one dot so nesting will be necessary
      result = createNestedError(result, attribute, error);
    } else {
      if (result[attribute] && (result[attribute] as any).errors) {
        result[attribute] = {
          errors: [
            ...(result[attribute] as any).errors,
            error,
          ],
        };
      } else {
        result[attribute] = {
          errors: [error],
        };
      }
    }
  });

  return result;
};

export default constraintAPIFormatter;
