import MediaObject from '@/domains/mediaObject/MediaObject';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';

const createMediaObject: PushSingleAction<MediaObject> = async (
  { commit, dispatch },
  payload: PushSinglePayload<MediaObject>,
): Promise<MediaObject> => {
  const { fetch, domain } = payload;
  const pushPayload = {
    fetch,
    domain,
    suppressClientErrorPopups: true,
  };

  return MediaObject.create(await pushSingle({commit, dispatch}, 'POST', pushPayload, {
    url: `/media-objects`,
    mutation: '',
  }));
};

export default createMediaObject;
