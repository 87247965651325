import Domain from '@/types/Domain';


// TODO: maybe refactor this whole model generally
interface Props {
  additionalInfoConfirm: boolean;
  additionalInfoText: string;
  isActive?: boolean;
}

class CampaignAdditionalInfo extends Domain implements Props {

  public static create(data: any): CampaignAdditionalInfo {
    return new CampaignAdditionalInfo(
      data.additionalInfoConfirm || false,
      data.additionalInfoText || null,
      data.isActive || false,
    );
  }

  private constructor(
    public additionalInfoConfirm: boolean,
    public additionalInfoText: string,
    public isActive: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): CampaignAdditionalInfo {
    return CampaignAdditionalInfo.create(JSON.parse(JSON.stringify(this)));
  }
}

export default CampaignAdditionalInfo;
