import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import RegenerateCode from '@/domains/cockpit/RegenerateCode';


const regenerateCodes: PushSingleAction<RegenerateCode> = async (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<RegenerateCode>,
) => {
  return await pushSingle({ commit, dispatch }, 'POST', payload, {
    url: '/code',
    mutation: undefined,
  });
};

export default regenerateCodes;
