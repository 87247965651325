import Domain from '@/types/Domain';


type ErrorEntry = [{ message?: string; propertyPath?: string }];

const setErrors = <T extends Domain>(
  fieldErrors: ErrorEntry,
  domain: T,
) => {
  fieldErrors.forEach((error) => {
    domain.addError(error.propertyPath, [error.message]);
  });
};

export default <T extends Domain>(response: any, domain: T) => {
  const errors = response.violations;

  setErrors<T>(errors, domain);
};
