import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/api-platform/fetchSingle';
import Mandant from '@/domains/mandant/Mandant';

const fetchMandant: FetchSingleAction<Mandant> = async (
  { commit, dispatch },
  payload: FetchSinglePayload,
) => {
  const {
    id,
  } = payload;

  return await fetchSingle({ commit, dispatch }, payload, {
    url: `/mandants/${id}`,
    domainParser: Mandant.create,
    mutation: 'addMandants',
  });
};

export default fetchMandant;
