import { ActionTree } from 'vuex';
import { CodeState } from '../state';
import { RootState } from '@/store';
import fetchCodeList from './fetchCodeList';


const actions: ActionTree<CodeState, RootState> = {
  fetchCodeList,
};
export default actions;
