import PasswordResetConfirm from '@/domains/auth/PasswordResetConfirm';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';

export interface PasswordResetNewPasswordPayload extends PushSinglePayload<PasswordResetConfirm> {
  router: {
    push: (...args: any) => Promise<void>;
  };
}

const passwordResetNewPassword: PushSingleAction<PasswordResetConfirm> = async (
  { commit, dispatch },
  payload: PasswordResetNewPasswordPayload,
) => {
  const { fetch, domain, router } = payload;

  const pushPayload = {
    fetch,
    domain,
    shouldRequestRefreshToken: false,
  };

  const response = await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url: `/password/confirm`,
    mutation: undefined,
  });

  router.push({ name: 'password-reset-success' });

  return response;
};

export default passwordResetNewPassword;
