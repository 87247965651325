import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/fetchSingle';
import Campaign from '@/domains/campaign/Campaign';


const fetchCampaign: FetchSingleAction<Campaign> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload,
) => {
  const {
    id,
  } = payload;

  let url;
  if (rootGetters.isMandant) {
    url = '/my/campaign';
  } else if (rootGetters.getSimulatedMandantId) {
    url = `/mandant/${rootGetters.getSimulatedMandantId}/campaign`;
  } else {
    url = '/campaign';
  }

  return await fetchSingle({ commit, dispatch }, payload, {
    url: `${url}/${id}`,
    domainParser: Campaign.create,
    mutation: 'addCampaigns',
  });
};

export default fetchCampaign;
