import Order from '@/domains/order/Order';
import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/fetchSingle';

const fetchOrder: FetchSingleAction<Order> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload,
) => {
  const { id } = payload;

  let url;
  if (rootGetters.isMandant) {
    url = `/my/order/${id}`;
  } else {
    url = `/order/${id}`;
  }

  return await fetchSingle({ commit, dispatch }, payload, {
    url,
    domainParser: Order.create,
    mutation: 'addOrders',
  });
};

export default fetchOrder;
