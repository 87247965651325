import { match, stub } from 'sinon';
import {
  loginBadCredentials,
  loginGoodCredentials,
} from './auth/login';
import { passwordForgotSuccess } from './auth/passwordForgot';
import {
  mandantList20FirstPage,
  mandantListFiltered12FirstPage,
  mandantListFiltered1FirstPage,
  mandantListFirstPage,
  mandantListSecondPage,
  mandantListSortedCodeASCFirstPage,
  mandantListSortedCodeDESCFirstPage,
} from './mandant/mandantList';
import { myUpdatedUser, myUser } from './user/userProfile';
import {
  mandantCreateFailure,
  mandantCreateSuccess,
} from './mandant/mandantCreate';
import { mandantEdit } from './mandant/mandantEdit';
import {
  userListFiltered1FirstPage,
  userListFiltered2FirstPage,
  userListFirstPage,
  userListSortedDepartmentASCFirstPage,
  userListSortedDepartmentDESCFirstPage,
} from './user/userList';
import { editUser, editUserUpdated } from './user/userEdit';
import { userCreate } from './user/userCreate';
import { articleListFirstPage } from './article/articleList';
import { manufacturerList } from './manufacturer/list';
import { categoryList } from './category/list';
import { articleCreate } from './article/articleCreate';
import {
  articleEdit,
  articleEdited,
} from './article/articleEdit';
import {
  campaignListFirstPage,
  campaignListSortedASCFirstPage,
} from './campaign/campaignList';
import {
  campaignVariantListCategoryGUFirstPage,
  campaignVariantListCategoryHWFirstPage,
  campaignVariantListCategoryZUFirstPage,
  campaignVariantListFirstPage,
} from './campaign/campaignVariantList';
import { campaignCreate } from './campaign/campaignCreate';
import { codeListFirstPage } from './code/codeList';

const url = (slug: string) => `${process.env.VUE_APP_API_URL}${slug}`;
const responseStubs = stub();
responseStubs
  .withArgs(url('/login'))
  .onFirstCall()
  .returns(loginBadCredentials)
  .onSecondCall()
  .returns(loginGoodCredentials)
  .returns(loginGoodCredentials)
  .withArgs(url('/password-forgot'))
  .returns(passwordForgotSuccess)
  .withArgs(url('/mandant?page=1&limit=10000'))
  .returns(mandantListFirstPage)
  .withArgs(url('/mandant?page=1&limit=10'))
  .returns(mandantListFirstPage)
  .withArgs(url('/mandant?page=2&limit=10'))
  .returns(mandantListSecondPage)
  .withArgs(url('/mandant?page=1&limit=20'))
  .returns(mandantList20FirstPage)
  .withArgs(url('/mandant?page=1&limit=10&filter[name]=1'))
  .returns(mandantListFiltered1FirstPage)
  .withArgs(url('/mandant?page=1&limit=10&filter[name]=12'))
  .returns(mandantListFiltered12FirstPage)
  .withArgs(url('/mandant?page=1&limit=10&sort[code]=ASC'))
  .returns(mandantListSortedCodeASCFirstPage)
  .withArgs(url('/mandant?page=1&limit=10&sort[code]=DESC'))
  .returns(mandantListSortedCodeDESCFirstPage)
  .withArgs(url('/my/profile'))
  .returns(myUser)
  .withArgs(url('/my/profile'), match({ method: 'PUT' }))
  .returns(myUpdatedUser)
  .withArgs(url('/mandant'), match({ method: 'POST' }))
  .onFirstCall()
  .returns(mandantCreateFailure)
  .onSecondCall()
  .returns(mandantCreateSuccess)
  .withArgs(url('/mandant/034869b8-0675-4a7f-b868-48507f674179'))
  .returns(mandantEdit)
  .withArgs(
    url('/mandant/034869b8-0675-4a7f-b868-48507f674179/user?page=1&limit=10'),
  )
  .returns(userListFirstPage)
  .withArgs(
    url(
      '/mandant/034869b8-0675-4a7f-b868-48507f674179/user?page=1&limit=10&filter[lastName]=1',
    ),
  )
  .returns(userListFiltered1FirstPage)
  .withArgs(
    url(
      '/mandant/034869b8-0675-4a7f-b868-48507f674179/user?page=1&limit=10&filter[lastName]=2',
    ),
  )
  .returns(userListFiltered2FirstPage)
  .withArgs(
    url(
      '/mandant/034869b8-0675-4a7f-b868-48507f674179/user?page=1&limit=10&sort[department]=ASC',
    ),
  )
  .returns(userListSortedDepartmentASCFirstPage)
  .withArgs(
    url(
      '/mandant/034869b8-0675-4a7f-b868-48507f674179/user?page=1&limit=10&sort[department]=DESC',
    ),
  )
  .returns(userListSortedDepartmentDESCFirstPage)
  .withArgs(url('/user/294e3df1-0088-426a-bb97-40e76f277cd9'))
  .returns(editUser)
  .withArgs(
    url('/user/294e3df1-0088-426a-bb97-40e76f277cd9'),
    match({ method: 'PUT' }),
  )
  .returns(editUserUpdated)
  .withArgs(
    url('/mandant/034869b8-0675-4a7f-b868-48507f674179/user'),
    match({ method: 'POST' }),
  )
  .returns(userCreate)
  .withArgs(url('/article/summary?page=1&limit=10'))
  .returns(articleListFirstPage)
  .withArgs(url('/manufacturer?limit=10000'))
  .returns(manufacturerList)
  .withArgs(url('/category?limit=10000'))
  .returns(categoryList)
  .withArgs(url('/article'), match({ method: 'POST' }))
  .returns(articleCreate)
  .withArgs(url('/article/8ab919c7-b343-47d5-8d12-deb4788e1fe4'))
  .returns(articleEdit)
  .withArgs(
    url('/article/8ab919c7-b343-47d5-8d12-deb4788e1fe4'),
    match({ method: 'POST' }),
  )
  .returns(articleEdited)
  .withArgs(
    url('/campaign/034869b8-0675-4a7f-b868-48507f674179?page=1&limit=10'),
  )
  .returns(campaignListFirstPage)
  .withArgs(
    url(
      '/campaign/034869b8-0675-4a7f-b868-48507f674179?page=1&limit=10&sort[name]=ASC',
    ),
  )
  .returns(campaignListSortedASCFirstPage)
  .withArgs(url('/variant?page=1&limit=10&filter[categoryId]=HW'))
  .returns(campaignVariantListCategoryHWFirstPage)
  .withArgs(url('/variant?page=1&limit=10&filter[categoryId]=ZU'))
  .returns(campaignVariantListCategoryZUFirstPage)
  .withArgs(url('/variant?page=1&limit=10&filter[categoryId]=GU'))
  .returns(campaignVariantListCategoryGUFirstPage)
  .withArgs(
    url(
      '/variant?page=1&limit=10&filter[categoryId]=HW&filter[manufacturerId]=b1fdac14-c7b0-11e8-a8d5-f2801f1b9fd1',
    ),
  )
  .returns(campaignVariantListCategoryGUFirstPage)
  .withArgs(url('/variant?page=1&limit=10'))
  .returns(campaignVariantListFirstPage)
  .withArgs(url('/campaign/034869b8-0675-4a7f-b868-48507f674179'))
  .returns(campaignCreate)
  .withArgs(
    url(
      '/campaign/294e3df1-0088-426a-aa97-40e76f210cd100/code?page=1&limit=10',
    ),
  )
  .returns(codeListFirstPage);

export default responseStubs;
