import Domain from '@/types/Domain';

const notWhitespacesOnlyRegExp = /^(?!\s*$).+/;

interface Props {
  id: string;
  erpOrderId: string;
  statusId: string;
  date: string;
  campaignName: string;
  codeToken: string;
  firstName: string;
  lastName: string;
  articleVariantName: string;
}

class MinimalOrder extends Domain implements Props {
  public static createAll(orders: any[]): MinimalOrder[] {
    return orders.map(MinimalOrder.create);
  }

  public static create(data: any): MinimalOrder {
    return new MinimalOrder(
      data.id,
      data.erpOrderId,
      data.status,
      data.createdAt,
      data.campaignName,
      data.token,
      data.firstName,
      data.lastName,
      data.articleVariantName,
    );
  }

  private constructor(
    public id: string,
    public erpOrderId: string,
    public statusId: string,
    public date: string,
    public campaignName: string,
    public codeToken: string,
    public firstName: string,
    public lastName: string,
    public articleVariantName: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      id: {
        presence: true,
        format: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
      },
      date: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      campaignName: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      codeToken: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      firstName: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      lastName: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
      articleVariantName: {
        presence: true,
        format: notWhitespacesOnlyRegExp,
      },
    };
  }

  public clone(): MinimalOrder {
    return MinimalOrder.create(this);
  }
}

export default MinimalOrder;
