import { MutationTree } from 'vuex';
import { UserGroupState } from './state';
import UserGroup from '@/domains/userGroup/UserGroup';
import NormalizedData from '@/types/NormalizedData';
import { ListInfo } from '@/types/ListInfo';
import MinimalUser from '@/domains/mandant/MinimalUser';


export const addUserGroups = (
  state: UserGroupState,
  userGroups: NormalizedData<UserGroup>,
): void => {
  state.userGroups = {
    ...state.userGroups,
    ...userGroups,
  };
};

const setUserGroups = (
  state: UserGroupState,
  userGroups: NormalizedData<UserGroup>,
): void => {
  state.userGroups = userGroups;
};

export const setUserGroupListInfo = (
  state: UserGroupState,
  info: ListInfo,
): void => {
  state.listInfo = info;
};

export const setUserGroupUsers = (
  state: UserGroupState,
  users: NormalizedData<MinimalUser>,
): void => {
  state.users = users;
};

export const setUserGroupUserListInfo = (
  state: UserGroupState,
  info: ListInfo,
): void => {
  state.userListInfo = info;
};

export const setAssignableOwners = (
  state: UserGroupState,
  users: NormalizedData<MinimalUser>,
): void => {
  state.assignableOwners = users;
};

export const setAssignableOwnerListInfo = (
  state: UserGroupState,
  info: ListInfo,
): void => {
  state.assignableOwnerListInfo = info;
};

const mutations: MutationTree<UserGroupState> = {
  addUserGroups,
  setUserGroups,
  setUserGroupListInfo,
  setUserGroupUsers,
  setUserGroupUserListInfo,
  setAssignableOwners,
  setAssignableOwnerListInfo,
};

export default mutations;
