import fetchList, { FetchListPayload, FetchListAction } from '@/utils/fetchList';
import MinimalArticle from '@/domains/article/MinimalArticle';

const fetchArticleList: FetchListAction<MinimalArticle> = async (
  { commit, dispatch },
  fetchListPayload: FetchListPayload,
) => {
  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url: `/article-summary`,
    domainParser: MinimalArticle.create,
    listMutation: 'setMinimalArticles',
    listInfoMutation: 'setArticleListInfo',
  });
};

export default fetchArticleList;
