import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import classNames from 'classnames';
import styles from '../NewPopup.module.scss';
import { Action } from 'vuex-class';
import { PopupProps } from '@/types/PopupPayload';


@Component({
  props: {
    title: String,
    description: String,
    buttonText: {
      type: String,
      default: 'Schließen',
    },
    color: String,
    icon: String,
    type: {
      type: String,
      default: 'hint',
    },
    className: String,
    routeName: String,
    routeParams: Object,
  },
})
class HintPopup extends TsxComponent<PopupProps> {

  @Action('closeActivePopup')
  private closeActivePopup!: () => void;

  private onButtonClick(): void {
    const {
      routeName,
      routeParams,
    } = this.$props;

    this.closeActivePopup();

    this.$emit('close');

    if (routeName) {
      this.$router.push({
        name: routeName,
        params: routeParams,
      });
    }
  }

  private render() {
    const {
      title,
      description,
      buttonText,
      color,
      icon,
      className,
    } = this.$props;

    return (
      <div class={classNames(
        styles['popup__wrapper'],
        className,
        color && color === 'info' && styles['popup__wrapper-info'],
        color && color === 'error' && styles['popup__wrapper-error'],
        color && color === 'warning' && styles['popup__wrapper-warning'],
      )}>
        {icon && (
          <div class={classNames(
            styles['popup__wrapper__circle'],
            color && color === 'info' && styles['popup__wrapper__circle-info'],
            color && color === 'error' && styles['popup__wrapper__circle-error'],
            color && color === 'warning' && styles['popup__wrapper__circle-warning'],
          )}>
            <font-awesome-icon
              class="fa-lg"
              icon={icon}
            />
          </div>
        )}
        {title && (
          <h1
            class={classNames(
              styles['popup__wrapper__heading'],
              'headline-size--100',
            )}
          >
            {title}
          </h1>
        )}
        <div class={classNames(
          styles['popup__wrapper__content'],
          'text-center',
          'mt-4',
        )}>
          <div domPropsInnerHTML={description}>
            {description}
          </div>
          {this.$slots.default}
        </div>
        <button
          onClick={this.onButtonClick}
          class={classNames(
            styles['popup__wrapper__action'],
            'btn',
            'btn-block',
            'btn-dark',
          )}
        >
          {buttonText}
        </button>
      </div>
    );
  }

  private renderConfirmButton(buttonText: string) {
    return (
      <button
        onClick={() => this.$emit('confirm')}
        class={classNames(
          styles['popup__wrapper__action'],
          'btn',
          'btn-dark',
          'btn-block',
        )}
      >
        {buttonText}
      </button>
    );
  }
}

export default HintPopup;
