import fetchSingle, { FetchSingleAction, FetchSinglePayload } from '@/utils/fetchSingle';
import Article from '@/domains/article/Article';

const fetchArticle: FetchSingleAction<Article> = async (
  { commit, dispatch, rootGetters },
  payload: FetchSinglePayload,
) => {
  const { id } = payload;

  let url;
  if (rootGetters.isMandant) {
    url = `/my/article/${id}`;
  } else {
    url = `/article/${id}`;
  }

  return await fetchSingle({ commit, dispatch }, payload, {
    url,
    domainParser: Article.create,
    mutation: 'addArticles',
  });
};

export default fetchArticle;
