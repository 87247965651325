import { GetterTree } from 'vuex';
import { AuthState } from './state';
import Role from '@/types/Role';


export const isUserLoggedIn = (state: AuthState): boolean => {
  return state.isUserLoggedIn;
};

export const getRoles = (state: AuthState): Role[] => {
  return state.roleIds.map((roleId: string) => Role.getById(roleId));
};

export const getAssignableRoles = (state: AuthState) => {
  return state.assignableRoleIds.map((roleId: string) =>
    Role.getById(roleId)).filter((role) => role !== undefined);
};

export const getNotifiableRoles = (state: AuthState): Role[] => {
  return state.notifiableRoleIds.map((roleId: string): Role | undefined => {
    return Role.getById(roleId);
  }).filter((role) => role !== undefined);
};

export const getMandantId = (state: AuthState): string => {
  return state.simulatedMandantId || state.mandantId;
};

export const getActualMandantId = (state: AuthState): string => {
  return state.mandantId;
};

export const getLogoutTimestamp = (state: AuthState): number | undefined => {
  return state.logoutTimestamp;
};

export const getRefreshToken = (state: AuthState): string | undefined => {
  return state.refreshToken;
};

export const getSimulatedMandantId = (state: AuthState): string => {
  return state.simulatedMandantId;
};

export const getMandantName = (state: AuthState): string => state.mandantName;

export const getMandantShopHost = (state: AuthState): string => state.mandantShopHost;

export const getModuleState = (state: AuthState) => {
  return (moduleName: string): boolean => {
    return state.modules[moduleName];
  };
};

// TODO: These Getters should be replaced by getModuleState (s.a.) that takes a module-name and returns bool
export const getMandantAdditionalCharge = (state: AuthState): boolean => state.modules.MODULE_ADDITIONAL_CHARGE;
export const getMandantSales = (state: AuthState): boolean => state.modules.MODULE_SALES;
export const getMandantApiSettings = (state: AuthState): boolean => state.modules.MODULE_API_SETTINGS;
export const getMandantAccess = (state: AuthState): boolean => state.modules.MODULE_ACCESS;

export const canAccessRoute = (state: AuthState) => {
  return (routeName: string): boolean => {
    return !!getRoles(state).find((role: Role) => role.routes.includes(routeName));
  };
};

export const canAccessAction = (state: AuthState) => {
  return (actionName: string): boolean => {
    return !!getRoles(state).find((role: Role) => role.actions && role.actions.includes(actionName));
  };
};

export const canSimulateMandant = (state: AuthState): boolean => {
  return !!getRoles(state).find((role: Role) => Role.CAN_SIMULATE_MANDANT.includes(role));
};

export const isSkonMember = (state: AuthState): boolean => {
  return !!getRoles(state).find((role: Role) => Role.IS_SKON_MEMBER.includes(role));
};

export const isMandant = (state: AuthState): boolean => {
  return !!getRoles(state).find((role: Role) => role.isMandant());
};

export const isSimulatedMandant = (state: AuthState): boolean => {
  return canSimulateMandant(state) && !!state.simulatedMandantId;
};

const getters: GetterTree<AuthState, any> = {
  getModuleState,
  getRoles,
  getAssignableRoles,
  getNotifiableRoles,
  isUserLoggedIn,
  getMandantId,
  getActualMandantId,
  getLogoutTimestamp,
  getRefreshToken,
  getSimulatedMandantId,
  getMandantName,
  getMandantShopHost,
  getMandantAdditionalCharge,
  getMandantSales,
  getMandantAccess,
  getMandantApiSettings,
  canAccessAction,
  canAccessRoute,
  canSimulateMandant,
  isSkonMember,
  isMandant,
  isSimulatedMandant,
};

export default getters;
