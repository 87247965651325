import pushSingle, { PushSingleAction, PushSingleMandantSpecificPayload } from '@/utils/pushSingle';
import Campaign from '@/domains/campaign/Campaign';


const createCampaign: PushSingleAction<Campaign> = async (
  { commit, dispatch, rootGetters },
  payload: PushSingleMandantSpecificPayload<Campaign>,
) => {
  const { fetch, domain } = payload;
  const pushPayload = { fetch, domain };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/campaign`;
  } else {
    const mandantId = rootGetters.getMandantId;
    url = `/mandant/${mandantId}/campaign`;
  }

  const domainClone = domain.clone();
  // @ts-ignore
  domainClone.codeProperties.type = domainClone.codeProperties.type.id;

  const response = await pushSingle({ commit, dispatch }, 'POST', pushPayload, {
    url,
    mutation: 'addCampaigns',
  });

  commit('setTextFields', []);

  return response;
};

export default createCampaign;
