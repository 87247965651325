import Category from '@/domains/category/Category';
import { CategoryState } from '@/store/modules/category/state';

export type CategoryGetter = (id: string) => Category;

const getCategory = (state: CategoryState): CategoryGetter => (id: string): Category => {
  return state.categories[id];
};

export default getCategory;
