import createFakeResponse from '../../../test-helper/createFakeResponse';

export const mandantCreateSuccess = createFakeResponse({
  id: '12345678b-0675-asdf-b868-48507f674171',
});
export const mandantCreateFailure = createFakeResponse(
  {
    code: 400,
    message: 'Validation Failed',
    errors: {
      errors: [
        'Dieses Formular sollte keine zus\u00e4tzlichen Felder enthalten.',
      ],
      children: {
        code: {
          errors: ['Dieser Wert sollte nicht leer sein.'],
        },
        vatIdNumber: {},
        name: {},
        address: {
          children: {
            country: {},
            state: {},
            city: {},
            zipCode: {},
            street: {
              children: {
                name: {
                  errors: ['WTF?!'],
                },
                number: {},
              },
            },
          },
        },
        manager: {},
        website: {},
        shopHost: {},
        phone: {},
        creditLimit: {},
        operationFee: {},
        ownArticleFee: {},
        modules: {},
        designData: {
          children: {
            backgroundColor: {
              errors: ['Dieser Wert sollte nicht leer sein.'],
            },
            buttonColor: {
              errors: ['Dieser Wert sollte nicht leer sein.'],
            },
            logo: {},
          },
        },
        status: {},
      },
    },
  },
  400,
);
