import Domain from '@/types/Domain';
import CodeStatus from '@/types/CodeStatus';
import CodeDispatchStatus from '@/types/CodeDispatchStatus';
import CodeDispatchMethod from '@/types/CodeDispatchMethod';
import CodeDispatchHistoryItem from '@/domains/codeDispatch/CodeDispatchHistoryItem';
import Gender from '@/types/Gender';
import Campaign from '@/domains/campaign/Campaign';

export interface CustomerData {
  gender: Gender;
  firstname: string;
  lastname: string;
  phone?: string;
  email?: string;
}

export interface Props {
  id: string;
  campaign: Campaign;
  history: CodeDispatchHistoryItem[];
  customerData: CustomerData;
  method: CodeDispatchMethod;
  codeStatus: CodeStatus;
  dispatchStatus: CodeDispatchStatus;
  redemptionDelayDate?: Date;
}

class CodeDispatch extends Domain implements Props {
  public static createAll(dispatches: any[]): CodeDispatch[] {
    return dispatches.map(CodeDispatch.create);
  }

  public static create(data: any = {}): CodeDispatch {
    if (data.hasOwnProperty('history') && data.history) {
      delete data.history['hydra:view'];
    }

    const customerData = data.customerData;
    if (customerData) {
      customerData.gender = Gender.getById(customerData.gender);
    }

    return new CodeDispatch(
      data.id,
      data.campaign && Campaign.create(data.campaign),
      data.history && CodeDispatchHistoryItem.createAll(Object.values(data.history)),
      customerData,
      (
        typeof data.method === 'string' ? CodeDispatchMethod.getById(data.method) : data.method
      ) || (
        data.customerData ? CodeDispatch.deriveMethodFromCustomerData(data.customerData) : data.method
      ),
      data.code ?
        (typeof data.code.codeStatus === 'string' ?
          CodeStatus.getById(data.code.codeStatus) :
          data.code.codeStatus) :
        null,
      typeof data.dispatchStatus === 'string' ? CodeDispatchStatus.getById(data.dispatchStatus) : data.dispatchStatus,
      (
        data.code &&
        typeof data.code.redemptionDelayDate === 'string'
      ) ?
        new Date(data.code.redemptionDelayDate) :
        undefined,
    );
  }

  private static deriveMethodFromCustomerData(customerData: CustomerData): CodeDispatchMethod {
    if (customerData.phone) return CodeDispatchMethod.SMS;
    if (customerData.email) return CodeDispatchMethod.EMAIL;

    return undefined;
  }

  protected constructor(
    public id: string,
    public campaign: Campaign,
    public history: CodeDispatchHistoryItem[],
    public customerData: CustomerData,
    public method: CodeDispatchMethod,
    public codeStatus: CodeStatus,
    public dispatchStatus: CodeDispatchStatus,
    public redemptionDelayDate: Date,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      'customerData.gender': {
        presence: {
          message: '^Anrede ist ein Pflichtfeld.',
        },
      },
      'customerData.firstname': {
        presence: {
          message: '^Vorname ist ein Pflichtfeld.',
        },
        format: {
          pattern: /^.{2,100}$/,
          message: '^Vorname muss zwischen 2 und 100 Zeichen lang sein.',
        },
      },
      'customerData.lastname': {
        presence: {
          message: '^Nachname ist ein Pflichtfeld.',
        },
        format: {
          pattern: /^.{2,100}$/,
          message: '^Nachname muss zwischen 2 und 100 Zeichen lang sein.',
        },
      },
      method: {
        presence: {
          message: '^Es muss eine Option ausgewählt werden.',
        },
      },
      'customerData.phone': {
        format: {
          pattern: /^0[0-9]+$/,
          message: '^Die Nummer muss mit 0 beginnen. Bitte geben Sie nur Zahlen an.',
        },
        length: {
          minimum: 2,
          maximum: 20,
          message: '^Mobilfunknummer muss zwischen 2 und 20 Zeichen lang sein.',
        },
      },
      'customerData.email': {
        email: {
          message: '^Bitte geben Sie eine gültige E-Mail-Adresse an.',
        },
      },
    };
  }

  public clone(): CodeDispatch {
    // workaround to preserve customerData.gender on clone
    const that = JSON.parse(JSON.stringify(this));
    that.customerData.gender = this.customerData.gender.id;

    return CodeDispatch.create(that);
  }

  public getLatestStatusChangeDate(): string | undefined {
    return (this.history && this.history.length > 0) ?
      this.history.slice(-1)[0].createdAt :
      undefined;
  }
}

export default CodeDispatch;
