import MinimalMessage, { Props as MinimalMessageProps } from '@/domains/message/MinimalMessage';


interface Props extends MinimalMessageProps {
  text: string;
  roles?: string[];
}

class Message extends MinimalMessage implements Props {

  public static createAll(messages: Message[]): Message[] {
    return messages.map(Message.create);
  }

  public static create(data: any = {}): Message {
    return new Message(
      data.id || '',
      data.priority || false,
      data.subject || '',
      data.startDate && new Date(data.startDate),
      data.markedAsRead || false,
      data.text || '',
      data.roles || [],
      data.endDate && new Date(data.endDate),
    );
  }

  public static createDefault(): Message {
    return Message.create({
      subject: '',
      text: '',
    });
  }

  private constructor(
    id: string,
    priority: boolean,
    subject: string,
    startDate: Date,
    markedAsRead: boolean,
    public text: string,
    public roles: string[],
    endDate?: Date,
  ) {
    super(id, priority, subject, startDate, markedAsRead, endDate);
  }

  public constraints(): {} {
    return {
      subject: {
        presence: {
          message: '^Betreff ist ein Pflichtfeld',
        },
      },
      text: {
        presence: {
          message: '^Nachrichtentext ist ein Pflichtfeld',
        },
      },
      startDate: {
        presence: {
          message: '^Startdatum ist ein Pflichtfeld',
        },
      },
    };
  }

  public clone(): Message {
    return Message.create(JSON.parse(JSON.stringify(this)));
  }
}

export default Message;
