import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import Order from '@/domains/order/Order';
import MinimalOrder from '@/domains/order/MinimalOrder';
import OrderStatus from '@/types/OrderStatus';

export interface OrderState {
  orders: NormalizedData<Order>;
  minimalOrders: NormalizedData<MinimalOrder>;
  listInfo: ListInfo;
  listStatusFilter: OrderStatus;
}

const state: OrderState = {
  orders: {},
  minimalOrders: {},
  listInfo: ListInfo.create(),
  listStatusFilter: OrderStatus.VERIFY,
};

export default state;
