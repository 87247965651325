import Domain from '@/types/Domain';
import ArticleImage from '@/domains/article/ArticleImage';
import Money from '@/domains/common/Money';
import sortByProp from '@/utils/sortByProp';


interface Props {
  id: string;
  image: ArticleImage;
  name: string;
  articlePrice: Money;
}

class HighlightArticle extends Domain implements Props {
  public static createAll(highlightArticles: any[]): HighlightArticle[] {
    return highlightArticles.map(HighlightArticle.create);
  }

  public static create(data: any): HighlightArticle {
    return new HighlightArticle(
      data.id,
      ArticleImage.create((data.images && data.images.length > 0) ? sortByProp(data.images, 'order')[0] : {}),
      data.name,
      Money.create(data.articlePrice || {}),
    );
  }

  private constructor(
    public id: string,
    public image: ArticleImage,
    public name: string,
    public articlePrice: Money,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): HighlightArticle {
    return HighlightArticle.create(this);
  }
}

export default HighlightArticle;
