import { Module } from 'vuex';
import { RootState } from '@/store';
import actions from './actions';
import state, { CodeDispatchState } from './state';
import mutations from './mutations';
import getters from './getters';

const messageModule: Module<CodeDispatchState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};

export default messageModule;
