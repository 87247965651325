import createFakeResponse from '../../../test-helper/createFakeResponse';
import sortAscending from '@/utils/sortAscending';
import sortDescending from '@/utils/sortDescending';

const itemsFirstPage = [
  {
    id: '294e3df1-0088-426a-aa97-40e76f210cd100',
    active: true,
    mandantId: '034869b8-0675-4a7f-b868-48507f674179',
    mandantName: 'Mandant 8',
    name: 'Strom A',
    startDate: '01.01.2018',
    endDate: '01.01.2019',
    codesQuantity: 200,
  },
];
const bodyFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: itemsFirstPage,
  },
};

const allItems = [...itemsFirstPage];

const bodyFilteredIncorrectFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 0,
  _embedded: {
    items: [] as any[],
  },
};
const bodyFilteredCorrectFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [...allItems.filter((item) => item.name.indexOf('Mac') !== -1)],
  },
};

const bodySortedASCFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [...allItems.sort((a, b) => sortAscending(a.name, b.name))].slice(
      0,
      10,
    ),
  },
};
const bodySortedDESCFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [...allItems.sort((a, b) => sortDescending(a.name, b.name))].slice(
      0,
      10,
    ),
  },
};

export const campaignListFilteredIncorrectFirstPage = createFakeResponse(
  bodyFilteredIncorrectFirstPage,
);
export const campaignListFilteredCorrectFirstPage = createFakeResponse(
  bodyFilteredCorrectFirstPage,
);

export const campaignListSortedASCFirstPage = createFakeResponse(
  bodySortedASCFirstPage,
);
export const campaignListSortedDESCFirstPage = createFakeResponse(
  bodySortedDESCFirstPage,
);

export const campaignListFirstPage = createFakeResponse(bodyFirstPage);
