import AuthAction from './AuthAction';
import { storeData, clearData } from '@/utils/persist';
import Role from '@/types/Role';

export const logout: AuthAction = (
  { commit },
  { router, logoutSuccessMessage, logoutErrorMessage },
): void => {
  clearData();
  if (window.location.pathname !== '/login') {
    storeData('initial-url-path', window.location.pathname, window.sessionStorage);
  }

  commit('setIsUserLoggedIn', false);
  commit('setRoleIds', [ Role.GUEST.id ]);
  commit('setAssignableRoleIds', { skon: [], mandant: [] });
  commit('setNotifiableRoleIds', []);
  commit('setMandantId', null);
  commit('setSimulatedMandantId', null);
  commit('setMandantName', '');
  commit('setMandantModules', {});

  router.push({
    name: 'login',
    params: { logoutSuccessMessage, logoutErrorMessage },
  });
};

export default logout;
