import Domain from '@/types/Domain';

interface Props {
  name: string;
  count: number;
  orderDate: string;
}

class StatusInfo extends Domain implements Props {
  public static createAll(data: any[] = []): StatusInfo[] {
    return data.map(StatusInfo.create);
  }

  public static create(data: any = {}): StatusInfo {
    return new StatusInfo(
      data.name,
      data.count,
      data.orderDate,
    );
  }

  private constructor(
    public name: string,
    public count: number,
    public orderDate: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): StatusInfo {
    return StatusInfo.create(this);
  }
}

export default StatusInfo;
