import Domain from '@/types/Domain';
import CampaignCodeProperties from '@/domains/campaign/CampaignCodeProperties';

interface Props {
  id: string; // campaignId
  type: string; // campaignType
  name: string;
  startDate: Date;
  endDate: Date;
  codeProperties?: CampaignCodeProperties;
  active: boolean;
}

class MinimalCampaignReport extends Domain implements Props {
  public static createAll(campaigns: any[]): MinimalCampaignReport[] {
    return campaigns.map(MinimalCampaignReport.create);
  }

  public static create(data: any): MinimalCampaignReport {
    return new MinimalCampaignReport(
      data.id,
      data.campaignType,
      data.name,
      new Date(data.startDate),
      new Date(data.endDate),
      CampaignCodeProperties.create(data.codeProperties || {}),
      data.active,
    );
  }

  private constructor(
    public id: string,
    public type: string,
    public name: string,
    public startDate: Date,
    public endDate: Date,
    public codeProperties: CampaignCodeProperties,
    public active: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): MinimalCampaignReport {
    return MinimalCampaignReport.create(this);
  }
}

export default MinimalCampaignReport;
