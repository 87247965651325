import PopupPayload from '@/types/PopupPayload';
import ToastNotificationPayload from '@/types/ToastNotificationPayload';

export interface UiState {
  popups: PopupPayload[];
  toastNotifications: ToastNotificationPayload[];
  spoolRequestIds: string[];
  requestedReload: boolean;
  isPageContentLoading: boolean;
  sidebarOpenItem: string;
  isRequestingDataLossConfirm: boolean;
}

const state: UiState = {
  popups: [],
  toastNotifications: [],
  spoolRequestIds: [],
  requestedReload: false,
  isPageContentLoading: false,
  sidebarOpenItem: '',
  isRequestingDataLossConfirm: false,
};

export default state;
