import { ListInfo } from '@/types/ListInfo';
import CampaignReport from '@/domains/report/CampaignReport';
import NormalizedData from '@/types/NormalizedData';
import MinimalCampaignReport from '@/domains/report/MinimalCampaignReport';

export interface CampaignReportState {
  campaignReports: NormalizedData<CampaignReport>;
  minimalCampaignReports: NormalizedData<MinimalCampaignReport>;
  listInfo: ListInfo;
}

const state: CampaignReportState = {
  campaignReports: {},
  minimalCampaignReports: {},
  listInfo: ListInfo.create({ limit: 3 }),
};

export default state;
