import Component from 'vue-class-component';
import TsxComponent from '@/typeDefinitions/vue-tsx';
import CollapsiblePageSection from '@/components/collapsiblePageSection/CollapsiblePageSection';
import NewInfoHint from '@/components/infoHint/NewInfoHint';
import classNames from 'classnames';
import Domain from '@/types/Domain';
import getIdFromPath from '@/components/form/utils/getIdFromPath';
import getFieldError from '@/components/form/utils/getFieldError';
import './FormGroup.scss';


interface Props {
  domain: Domain;
  path: string;
  /**
   * In some cases (e.g. for Money objects) the form field shall handle the whole
   * object, but the validation shall be triggered on a sub prop (e.g. amount)
   */
  validationPath?: string;
  label: string;
  hint?: string | JSX.Element;
  description?: string;
  customConstraints?: () => {};
  leftIcon?: string;
  rightIcon?: string;
  useAlternativeStyling?: boolean;
  charsLeft?: number;
  limit?: number;
  noGroup?: boolean;
}

@Component({
  components: {
    CollapsiblePageSection,
  },
  props: {
    domain: Object,
    path: String,
    validationPath: String,
    label: String,
    hint: [String, Object],
    description: String,
    customConstraints: Function,
    leftIcon: String,
    rightIcon: String,
    useAlternativeStyling: Boolean,
    charsLeft: Number,
    limit: Number,
    noGroup: Boolean,
  },
})
class NewFormGroup extends TsxComponent<Props> {
  private isFieldRequired(
    path: string,
    constraints: () => {},
  ): boolean {
    if (!constraints) {
      return false;
    }

    const constraintsData =  constraints();

    if (!constraintsData[path]) {
      return false;
    }

    return !!constraintsData[path].presence || false;
  }

  private render() {
    const {
      domain,
      path,
      validationPath,
      label,
      hint,
      description,
      customConstraints,
      leftIcon,
      rightIcon,
      useAlternativeStyling,
      limit,
      noGroup,
    } = this.$props;

    const id = getIdFromPath(path);
    const error = getFieldError(domain, validationPath || path);

    const isErroneous = !!error;

    return (
      <div class={classNames(
        !noGroup && 'input-group',
        useAlternativeStyling && 'input-group--alternate',
        isErroneous && 'input-group--warning',
      )}>
        <label
          class={classNames(
            'input-group__label',
          )}
          for={id}
        >
          {label}
          {label && this.isFieldRequired(path, customConstraints || domain.constraints) && '*'}
          {description && <NewInfoHint class="input-group__info-hint" text={description} />}
        </label>
        {limit && (
          <div class={classNames(
            'input-group__hint--limit',
            `${this.$props.charsLeft <= 0 && 'input-group__hint--limit--warning'}`,
          )}>
            {limit - this.$props.charsLeft || limit - limit}/{limit} Zeichen
          </div>
        )}
        {hint && (
          <div class={classNames(
            'input-group__hint',
          )}>
            {hint}
          </div>
        )}
        <div class={classNames(
          'input-group__input',
          leftIcon && 'input-group-icon--left',
          rightIcon && 'input-group-icon--right',
          'align-items-center',
        )}>
          {leftIcon && (
            <font-awesome-icon icon={leftIcon} />
          )}
          {this.$slots.default}
          {rightIcon && (
            <font-awesome-icon icon={rightIcon} />
          )}

          {isErroneous && <span class={classNames(
            'input-group__error-message',
          )}>{error}</span>}
        </div>
      </div>
    );
  }
}

export default NewFormGroup;
