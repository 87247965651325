import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import state, { UiState } from './state';
import actions from './actions';

const uiModule: Module<UiState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};

export default uiModule;
