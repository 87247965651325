import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import { Action, Getter, Mutation } from 'vuex-class';
import './App.scss';
import NewPopup from '@/components/NewPopup';
import Overlay from '@/components/overlay/Overlay';
import PopupPayload from '@/types/PopupPayload';
import { getData } from './utils/persist';
import ToastNotificationPayload from '@/types/ToastNotificationPayload';
import ToastNotification from '@/components/ToastNotification';
import './assets/scss/transitions.scss';
import './assets/scss/globalFlexLayout.scss';

@Component
class App extends TsxComponent<{}> {
  @Action('closeActivePopup')
  private closeActivePopup!: () => void;
  @Action('closeToastNotification')
  private closeToastNotification!: (id: string) => void;

  @Mutation('setIsPageContentLoading')
  private setIsPageContentLoading!: (isLoading: boolean) => void;
  @Getter('getActivePopup')
  private activePopup!: PopupPayload;
  @Getter('getInactivePopups')
  private inactivePopups!: PopupPayload[];
  @Getter('getToastNotifications')
  private toastNotifications!: ToastNotificationPayload[];

  private handleBrowserBackButton() {
    this.closeActivePopup();
    this.setIsPageContentLoading(false);
  }

  private created() {
    console.log(`application-version: ${getData('application-version', window.sessionStorage)}`);

    // Only browser buttons will trigger the popstate event.
    // A call to history.pushState() or history.replaceState() won't trigger this event
    window.addEventListener('popstate', this.handleBrowserBackButton);
  }

  /* tslint:disable-next-line:no-unused-variable */
  private beforeDestroy() {
    window.removeEventListener('popstate', this.handleBrowserBackButton);
  }

  private render() {
    return (
      <div id="app">
        <router-view key={this.$route.fullPath}></router-view>
        {this.activePopup && (
          <Overlay background="dark" fullscreen={true}>
            {this.inactivePopups.map((popup) => (
              <div class="popups__inactive-popups__item">
                {this.renderPopup(popup)}
              </div>
            ))}
            <template slot="content">
              {this.renderPopup(this.activePopup)}
            </template>
          </Overlay>
        )}
        { this.renderToastNotifications() }
    </div>
    );
  }

  private renderToastNotifications() {
    return (
      <transition-group
        name="fade-slide-in-out-bottom"
        class="toast-notifications"
        tag="div"
        appear
      >
        {this.toastNotifications.map((notification) => {
          const { id, message, status } = notification;
          return (
              <ToastNotification
                key={id}
                id={id}
                message={message}
                status={status}
                closeHandler={this.closeToastNotification}
              />
          );
        })}
      </transition-group>
    );
  }

  private renderPopup(payload: PopupPayload) {
    const {
      type,
      title,
      description,
      buttonText,
      color,
      icon,
      className,
      items,
      routeName,
      onClose,
      onConfirm,
      orderId,
      orderStatus,
      orderStatusPayload,
      campaign,
      form,
    } = payload;

    return (
      <NewPopup
        type={type}
        title={title}
        description={description}
        buttonText={buttonText}
        color={color}
        icon={icon}
        className={className}
        items={items}
        routeName={routeName}
        onClose={onClose || (() => null)}
        onConfirm={onConfirm || (() => null)}
        orderId={orderId}
        orderStatus={orderStatus}
        orderStatusPayload={orderStatusPayload}
        campaign={campaign}
        form={form}
      />
    );
  }
}

export default App;
