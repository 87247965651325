import createFakeResponse from '../../../test-helper/createFakeResponse';

export const myUser = createFakeResponse({
  id: '6cfd155e-8fdf-47a2-9b2e-15286d47cf2b',
  email: 'test+14@skon.de',
  firstName: 'First-14',
  lastName: 'Last-14',
});

export const myUpdatedUser = createFakeResponse({
  id: '6cfd155e-8fdf-47a2-9b2e-15286d47cf2b',
  email: 'test+14@skon.de',
  firstName: 'First-updated-14',
  lastName: 'Last-updated-14',
});
