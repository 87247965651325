import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import state, { CampaignWizardState } from './state';


const campaignWizardModule: Module<CampaignWizardState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
export default campaignWizardModule;
