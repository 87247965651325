import { GetterTree } from 'vuex';
import { CockpitState } from './state';


export const getRemainingCodes = (state: CockpitState) => state.remainingCodes;

const getters: GetterTree<CockpitState, any> = {
  getRemainingCodes,
};

export default getters;
