import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import classNames from 'classnames';
import styles from './CollapsiblePageSection.module.scss';
import {Watch} from 'vue-property-decorator';

interface Props {
  key?: string;
  title?: string;
  closed?: boolean;
  active?: boolean;
  modifier?: string;
  className?: string;
}

@Component({
  props: {
    title: String,
    closed: Boolean,
    active: {
      type: Boolean,
      default: undefined,
    },
    modifier: String,
    className: String,
  },
})
class CollapsiblePageSection extends TsxComponent<Props> {
  private isOpen: boolean = true;

  private toggle() {
    this.isOpen = !this.isOpen;
    this.$emit('toggle', this.isOpen);
  }

  @Watch('closed', { immediate: true })
  // tslint:disable-next-line
  private onClosedChange(closed: boolean) {
    if (closed) {
      this.isOpen = false;
    }
  }

  private themeSwitch() {
    const modifier = this.$props.modifier;
    let pageSectionClass = `page-section`;

    if (modifier) {
      pageSectionClass = `page-section--${modifier}`;
    }

    return pageSectionClass;
  }

  private render() {
    const { title, active, className } = this.$props;
    const { isOpen } = this;

    return (
      <div
        class={classNames(
          className,
          'container-fluid',
          styles[this.themeSwitch()],
          styles['page-section--collapsible'],
          isOpen && styles['page-section--collapsible--open'],
          active === true && styles['collapsible-page-section--active'],
          active === false && styles['collapsible-page-section--inactive'],
        )}
      >
        <div class={classNames(
          styles['collapsible-page-section__header'],
        )}>
          { title && <h2 class={classNames(
            styles['collapsible-page-section__header__title'],
            'headline-size--75',
          )}>
            {title}
            {this.$slots['title-extension']}
          </h2> }
          {this.$slots['post-headline']}
          <font-awesome-icon
            onClick={() => this.toggle()}
            class={classNames(
              styles['page-section--collapsible__icon'],
            )}
            icon={isOpen ? 'angle-up' : 'angle-down'}
          />
        </div>
        {this.$slots['header']}
        {isOpen && this.$slots.default}
      </div>
    );
  }
}

export default CollapsiblePageSection;
