import { ActionTree } from 'vuex';
import { ManufacturerState } from '../state';
import { RootState } from '@/store';
import fetchManufacturerList from './fetchManufacturerList';
import createManufacturer from './createManufacturer';
import editManufacturer from './editManufacturer';

const actions: ActionTree<ManufacturerState, RootState> = {
  fetchManufacturerList,
  createManufacturer,
  editManufacturer,
};
export default actions;
