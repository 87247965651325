const restify = (obj: object) => {
  if (obj) {
    Object.keys(obj).forEach((key: string) => {
      switch (typeof (obj[key])) {
        case 'boolean':
          obj[key] = obj[key].toString();
          break;

        case 'object':
          restify(obj[key]);
          break;
      }
    });
  }
};

export default restify;
