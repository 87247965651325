import { GetterTree } from 'vuex';
import { HighlightState } from './state';
import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import HighlightArticle from '@/domains/highlight/HighlightArticle';
import MinimalArticle from '@/domains/article/MinimalArticle';


export const getHighlights = (state: HighlightState): NormalizedData<HighlightArticle> => {
  return state.highlights;
};

export const getArticleForHighlights = (state: HighlightState): NormalizedData<MinimalArticle> => {
  return state.articles;
};

export const getArticleForHighlightsListInfo = (state: HighlightState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<HighlightState, any> = {
  getHighlights,
  getArticleForHighlights,
  getArticleForHighlightsListInfo,
};

export default getters;
