import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import OrderStatusCanceled from '@/domains/order/OrderStatusCanceled';

const cancelOrder: PushSingleAction<OrderStatusCanceled> = async (
  { commit, dispatch, rootGetters },
  payload: PushSinglePayload<OrderStatusCanceled>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  let url;
  if (rootGetters.isMandant) {
    url = `/my/order/${id}/status/canceled`;
  } else {
    url = `/order/${id}/status/canceled`;
  }

  return await pushSingle({ commit, dispatch }, 'PATCH', pushPayload, {
    url,
    mutation: undefined,
  });
};

export default cancelOrder;
