import { MutationTree } from 'vuex';
import { MandantState } from './state';
import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import MinimalMandant from '@/domains/mandant/MinimalMandant';
import Mandant from '@/domains/mandant/Mandant';
import SimpleMandant from '@/domains/mandant/SimpleMandant';

export const addMandants = (
  state: MandantState,
  mandants: NormalizedData<Mandant>,
): void => {
  state.mandants = {
    ...state.mandants,
    ...mandants,
  };
};

export const setMandantListInfo = (state: MandantState, info: ListInfo): void => {
  state.listInfo = info;
};

const setMinimalMandants = (
  state: MandantState,
  mandants: NormalizedData<MinimalMandant>,
): void => {
  state.minimalMandants = mandants;
};

const setSimpleMandants = (
  state: MandantState,
  simpleMandants: NormalizedData<SimpleMandant>,
): void => {
  state.simpleMandants = simpleMandants;
};

const mutations: MutationTree<MandantState> = {
  addMandants,
  setMandantListInfo,
  setMinimalMandants,
  setSimpleMandants,
};

export default mutations;
