import { GetterTree } from 'vuex';
import { RootState } from '@/store';
import { CategoryState } from '../state';
import getListCategoryVariants from '@/store/modules/category/getters/getListCategoryVariants';
import getCategory from '@/store/modules/category/getters/getCategory';
import getCategoryListInfo from '@/store/modules/category/getters/getCategoryListInfo';
import getCategories from '@/store/modules/category/getters/getCategories';
import getCategoryVariantListInfo from '@/store/modules/category/getters/getCategoryVariantListInfo';
import getAttachedCategories from '@/store/modules/category/getters/getAttachedCategories';

const getters: GetterTree<CategoryState, RootState> = {
  getListCategoryVariants,
  getCategory,
  getCategoryListInfo,
  getCategories,
  getCategoryVariantListInfo,
  getAttachedCategories,
};

export default getters;
