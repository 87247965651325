import { GetterTree } from 'vuex';
import { UiState } from './state';
import PopupPayload from '@/types/PopupPayload';
import ToastNotificationPayload from '@/types/ToastNotificationPayload';

export const getPopups = (state: UiState): PopupPayload[] => {
  return state.popups;
};

const getActivePopup = (state: UiState): PopupPayload => {
  return state.popups.slice(-1)[0];
};

const getInactivePopups = (state: UiState): PopupPayload[] => {
  return state.popups.slice(0, -1);
};

export const getToastNotifications = (
  state: UiState
): ToastNotificationPayload[] => {
  return state.toastNotifications;
};

const isReloadRequestSpoolActive = (state: UiState): boolean =>
  state.spoolRequestIds.length > 0;
const isReloadRequested = (state: UiState): boolean => state.requestedReload;

const isPageContentLoading = (state: UiState): boolean =>
  state.isPageContentLoading;

const getSidebarOpenItem = (state: UiState): string => state.sidebarOpenItem;

const isRequestingDataLossConfirm = (state: UiState): boolean =>
  state.isRequestingDataLossConfirm;

const getters: GetterTree<UiState, any> = {
  getPopups,
  getActivePopup,
  getInactivePopups,
  isReloadRequestSpoolActive,
  isReloadRequested,
  isPageContentLoading,
  getSidebarOpenItem,
  getToastNotifications,
  isRequestingDataLossConfirm,
};

export default getters;
