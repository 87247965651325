import Domain from '@/types/Domain';

class Manufacturer extends Domain {
  public static createAll(data: any[]): Manufacturer[] {
    return data.map(Manufacturer.create);
  }

  public static create(data: any): Manufacturer {
    return new Manufacturer(
      data.id,
      data.name,
    );
  }

  public static createDefault(): Manufacturer {
    return new Manufacturer('', '');
  }

  private constructor(
    public id: string,
    public name: string,
  ) {
    super();
    this.id = id || null;
    this.name = name || '';
  }

  public clone(): Manufacturer {
    const manufacturer = Manufacturer.create(this);
    manufacturer.errors = this.errors;

    return manufacturer;
  }

  public constraints(): {} {
    return {
      name: {
        presence: {
          message: '^Name darf nicht leer sein',
        },
        format: {
          pattern: /^[^#"'&$,;|=]{2,255}$/,
          message: '^Dieses Feld muss zwischen 2 und 255 Zeichen lang sein. Folgende Zeichen sind NICHT zulässig: #"\'&$,;|=',
        },
      },
    };
  }
}

export default Manufacturer;
