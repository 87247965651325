import { Module } from 'vuex';
import { RootState } from '@/store';
import actions from './actions';
import state, { MediaObjectState } from './state';
import mutations from './mutations';

const mediaObjectModule: Module<MediaObjectState, RootState> = {
  state,
  mutations,
  actions,
};

export default mediaObjectModule;
