import Domain from '@/types/Domain';

interface IdLabel {
  id: string;
  label: string;
}

interface Props {
  textLengthEnabled: boolean;
  textLengthMin: string;
  textLengthMax: string;
  charactersEnabled: boolean;
  charactersLowerEnabled: boolean;
  charactersUpperEnabled: boolean;
  numbersEnabled: boolean;
  specialCharactersEnabled: boolean;
  specialCharacters: IdLabel[];
}

class CustomValidation extends Domain implements Props {
  public static readonly SpecialCharacters: IdLabel[] = [
    { id: ',', label: 'Komma' },
    { id: ' ', label: 'Leerzeichen' },
    { id: '\\/', label: '/' },
    { id: '\\\\', label: '\\' },
    { id: '\\-', label: '-' },
    { id: '#', label: '#' },
    { id: '\\*', label: '*' },
    { id: '\\+', label: '+' },
    { id: '=', label: '=' },
    { id: '\\.', label: '.' },
    { id: '\\(', label: '(' },
    { id: '\\)', label: ')' },
    { id: '&', label: '&' },
    { id: '%', label: '%' },
    { id: '\\$', label: '$' },
    { id: '"', label: '"' },
    { id: '!', label: '!' },
    { id: '@', label: '@' },
    { id: '€', label: '€' },
  ];

  public static createAll(customValidations: any[]): CustomValidation[] {
    return customValidations.map(CustomValidation.create);
  }

  public static create(data: any = {}): CustomValidation {
    return new CustomValidation(
      data.textLengthEnabled || false,
      data.textLengthMin || '',
      data.textLengthMax || '',
      data.charactersEnabled || false,
      data.charactersLowerEnabled || false,
      data.charactersUpperEnabled || false,
      data.numbersEnabled || false,
      data.specialCharactersEnabled || false,
      data.specialCharacters || [],
    );
  }

  public static fromRegExp(regExp: string): CustomValidation {
    let textLengthMin = '';
    let textLengthMax = '';
    let matches = regExp.match('\{([0-9]*),([0-9]*)\}');
    if (matches !== null) {
      if (matches[1] !== '') {
        textLengthMin = matches[1];
      }
      textLengthMax = matches[2];
    } else {
      matches = regExp.match('\{([0-9]*)\}');
      if (matches !== null) {
        textLengthMin = matches[1];
      }
    }

    const lowerCharacters = regExp.includes('a-zäöü');
    const upperCharacters = regExp.includes('A-ZÄÖÜ');
    const numbers = regExp.includes('0-9');
    let preparedRegExpForSpecialCharacters = '';
    const checkForMatches = regExp.match(/\[(.*)\]/);
    if (checkForMatches && checkForMatches.length > 0) {
      preparedRegExpForSpecialCharacters = regExp.match(/\[(.*)\]/)![1];
    }

    const specialCharacters = this.SpecialCharacters.filter((specialCharacter) =>
      preparedRegExpForSpecialCharacters.includes(specialCharacter.id));

    return this.create({
      textLengthEnabled: (textLengthMin !== '' || textLengthMax !== ''),
      textLengthMin,
      textLengthMax,
      charactersEnabled: (lowerCharacters || upperCharacters),
      charactersLowerEnabled: lowerCharacters,
      charactersUpperEnabled: upperCharacters,
      numbersEnabled: numbers,
      specialCharactersEnabled: specialCharacters.length > 0,
      specialCharacters,
    });
  }

  private constructor(
    public textLengthEnabled: boolean,
    public textLengthMin: string,
    public textLengthMax: string,
    public charactersEnabled: boolean,
    public charactersLowerEnabled: boolean,
    public charactersUpperEnabled: boolean,
    public numbersEnabled: boolean,
    public specialCharactersEnabled: boolean,
    public specialCharacters: IdLabel[],
  ) {
    super();
  }

  /* Getters/Setters: Not working with Vue....
  public set charactersEnabled(checked: boolean) {
    this.innerCharactersEnabled = checked;
    this.charactersLowerEnabled = checked;
    this.charactersUpperEnabled = checked;
  }

  public get charactersEnabled(): boolean {
    return this.innerCharactersEnabled;
  }

  public set charactersLowerEnabled(checked: boolean) {
    this.innerCharactersLowerEnabled = checked;
    if (!checked && !this.innerCharactersUpperEnabled) {
      this.innerCharactersEnabled = false;
    }
  }

  public get charactersLowerEnabled(): boolean {
    return this.innerCharactersLowerEnabled;
  }

  public set charactersUpperEnabled(checked: boolean) {
    this.innerCharactersUpperEnabled = checked;
    if (!checked && !this.innerCharactersLowerEnabled) {
      this.innerCharactersEnabled = false;
    }
  }

  public get charactersUpperEnabled(): boolean {
    return this.innerCharactersUpperEnabled;
  }
   */

  public constraints(): {} {
    return {
      textLengthMin: {
        numericalityOther: {
          message: 'Dieses Feld muss kleiner gleich dem "Bis"-Feld sein.',
          lessThanOrEqualTo: 'textLengthMax',
        },
      },
      textLengthMax: {
        numericalityOther: {
          message: 'Dieses Feld muss größer gleich dem "Von"-Feld sein.',
          greaterThanOrEqualTo: 'textLengthMin',
        },
      },
    };
  }

  public clone(): Domain {
    return CustomValidation.create(JSON.parse(JSON.stringify(this)));
  }

  public toRegExp(): string {
    let regExpString = '^(custom)?';

    let hasOpenBracket = false;
    const openBracket = (): void => {
      if (!hasOpenBracket) {
        regExpString += '[';
        hasOpenBracket = true;
      }
    };

    if (this.charactersEnabled) {
      if (this.charactersLowerEnabled) {
        openBracket();
        regExpString += 'a-zäöü';
      }
      if (this.charactersUpperEnabled) {
        openBracket();
        regExpString += 'A-ZÄÖÜ';
      }
    }

    if (this.numbersEnabled) {
      openBracket();
      regExpString += '0-9';
    }

    if (this.specialCharactersEnabled && this.specialCharacters.length > 0) {
      openBracket();
      regExpString += this.specialCharacters.map((specialCharacter) => specialCharacter.id).join('');
    }

    if (hasOpenBracket) {
      regExpString += ']';
    } else {
      regExpString += '.';
    }

    if (this.textLengthEnabled && this.textLengthMin === '' && this.textLengthMax === '') {
      regExpString += '*';
    } else if (this.textLengthEnabled) {
      regExpString += '{';
      if (this.textLengthMin !== '') {
        regExpString += this.textLengthMin;
      }
      if (this.textLengthMax !== '') {
        if (this.textLengthMin !== '') {
          regExpString += ',';
        } else {
          regExpString += '0,';
        }
        regExpString += this.textLengthMax;
      }
      regExpString += '}';
    } else {
      regExpString += '*';
    }

    regExpString += '$';
    return regExpString;
  }

  public toConstraintMessage(fieldName: string): string {
    let message = `^Bitte geben Sie die ${fieldName} an.`;
    if (this.textLengthEnabled) {
      if (this.textLengthMin !== '' && this.textLengthMax !== '') {
        message += ` Sie hat ${this.textLengthMin} bis ${this.textLengthMax} Zeichen`;
      } else if (this.textLengthMin !== '') {
        message += ` Sie muss hat mindestens ${this.textLengthMin} Zeichen`;
      } else if (this.textLengthMax !== '') {
        message += ` Sie kann bis zu ${this.textLengthMax} Zeichen haben`;
      }
    }

    if (
      (this.charactersEnabled && (this.charactersLowerEnabled || this.charactersUpperEnabled)) ||
      this.numbersEnabled ||
      this.specialCharactersEnabled
    ) {
      if (
        this.textLengthEnabled &&
        (this.textLengthMin !== '' || this.textLengthMax !== '')
      ) {
        message += ' und enthält ';
      } else {
        message += ' Sie enthält ';
      }

      if (this.charactersEnabled && this.charactersLowerEnabled) {
        message += 'Kleinbuchstaben/';
      }
      if (this.charactersEnabled && this.charactersUpperEnabled) {
        message += 'Großbuchstaben/';
      }
      if (this.numbersEnabled) {
        message += 'Zahlen/';
      }
      if (this.specialCharactersEnabled && this.specialCharacters.length > 0) {
        message += 'Sonderzeichen (';
        message += this.specialCharacters
          .map((specialCharacter) => specialCharacter.label)
          .join(', ');
        message += ')';
      }

      if (message.slice(-1) === '/') {
        message = message.slice(0, message.length - 1);
      }
      message += '.';
    } else {
      if (this.textLengthEnabled && (this.textLengthMin !== '' || this.textLengthMax !== '')) {
        message += '.';
      }
    }

    return message;
  }
}

export default CustomValidation;
