const knownStorages: Storage[] = [
  window.localStorage,
  window.sessionStorage,
];

const defaultStorage = window.localStorage;

export const storeData = (key: string, value: any, storage: Storage = defaultStorage): void => {
  if (!key) {
    console.error('undefined key');
    return;
  }
  if (!knownStorages.includes(storage)) {
    console.error('unknown storage');
    return;
  }

  // undefined values can't get JSON.parsed
  if (value !== undefined) {
    storage.setItem(key, JSON.stringify(value));
  }
};

export const getData = (key: string, storage: Storage = defaultStorage): any => {
  if (!key) {
    console.error('undefined key');
    return;
  }
  if (!knownStorages.includes(storage)) {
    console.error('unknown storage');
    return;
  }

  return JSON.parse(storage.getItem(key));
};

export const removeData = (key: string, storage: Storage = defaultStorage): any => {
  if (!key) {
    console.error('undefined key');
    return;
  }
  if (!knownStorages.includes(storage)) {
    console.error('unknown storage');
    return;
  }

  storage.removeItem(key);
};

export const clearData = (storage: Storage = defaultStorage): void => {
  if (!knownStorages.includes(storage)) {
    console.error('unknown storage');
    return;
  }

  storage.clear();
};
