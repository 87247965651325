import { CampaignReportState } from '../state';
import { RootState } from '@/store';
import { ActionTree } from 'vuex';
import fetchCampaignReport from './fetchCampaignReport';
import fetchMinimalCampaignReportList from './fetchMinimalCampaignReportList';
import downloadCampaignReport from './downloadCampaignReport';

const actions: ActionTree<CampaignReportState, RootState> = {
  fetchCampaignReport,
  fetchMinimalCampaignReportList,
  downloadCampaignReport,
};

export default actions;
