import Domain from '@/types/Domain';

interface Props {
  id: string;
  url: string;
  filename: string;
  size: number;
  extension: string;
  description: string;
  order: number;
  hash: string;
  isNew: boolean;
  file: File;
  width?: number;
  height?: number;
  hasAlpha?: boolean;
}

class ArticleImage extends Domain implements Props {
  public static createAll(articles: any[]): ArticleImage[] {
    return articles.map(ArticleImage.create);
  }

  public static create(data: any): ArticleImage {
    return new ArticleImage(
      data.id,
      data.url,
      data.filename,
      data.size,
      data.extension,
      data.description,
      data.order,
      data.hash,
      data.isNew || false,
      data.file,
      data.width || 0,
      data.height || 0,
      data.hasAlpha || false,
    );
  }

  private constructor(
    public id: string,
    public url: string,
    public filename: string,
    public size: number,
    public extension: string,
    public description: string,
    public order: number,
    public hash: string,
    public isNew: boolean,
    public file: File,
    public width: number,
    public height: number,
    public hasAlpha: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      description: {
        format: {
          pattern: /^$|^.{2,100}$/,
          message: '^Beschreibung muss zwischen 2 und 100 Zeichen lang sein',
        },
      },
    };
  }

  public clone(): ArticleImage {
    const clone = JSON.parse(JSON.stringify(this));
    return ArticleImage.create({
      ...clone,
      file: this.file,
    });
  }
}

export default ArticleImage;
