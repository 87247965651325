import { ActionTree } from 'vuex';
import { RootState } from '@/store';
import { CockpitState } from '@/store/modules/cockpit/state';
import fetchRemainingCodes from '@/store/modules/cockpit/actions/fetchRemainingCodes';
import regenerateCodes from '@/store/modules/cockpit/actions/regenerateCodes';


const actions: ActionTree<CockpitState, RootState> = {
  fetchRemainingCodes,
  regenerateCodes,
};

export default actions;
