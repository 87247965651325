import Domain from '@/types/Domain';

const notWhitespacesOnlyRegExp = /^(?!\s*$).+/;

interface Props {
  order: number; // called 'number' in API
  extension: string;
  mimeType: string;
  data?: string;
}

class VerificationDocument extends Domain implements Props {

  public static createAll(orders: any[]): VerificationDocument[] {
    return orders.map(VerificationDocument.create);
  }

  public static create(data: any): VerificationDocument {
    return new VerificationDocument(
      data.order || data.number,
      data.extension,
      data.mimeType,
      data.data,
    );
  }

  public constructor(
    public order: number,
    public extension: string,
    public mimeType: string,
    public data: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      data: {
        format: {
          pattern: notWhitespacesOnlyRegExp,
          message: '^Dieses Feld darf nicht leer sein',
        },
      },
    };
  }

  public clone(): VerificationDocument {
    return VerificationDocument.create(this);
  }
}

export default VerificationDocument;
