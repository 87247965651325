import Domain from '@/types/Domain';

const mutateDomainProperty = (
  domain: Domain,
  path: string,
  value: any,
): void => {
  let object = domain;
  const pList = path.split('.');
  const len = pList.length;
  for (let i = 0; i < len - 1; i++) {
    const elem = pList[i];
    if (!object[elem]) {
      object[elem] = {};
    }
    object = object[elem];
  }

  object[pList[len - 1]] = value;
};

export default mutateDomainProperty;
