import Enum from './Enum';
import CodeDispatchMethod from '@/types/CodeDispatchMethod';

interface Props {
  label: string;
}

export default class CodeDispatchStatus extends Enum implements Props {
  // TODO: remove old statuses if they are not used by BE anymore (wait for SP-2465)
  public static readonly DISPATCHED = new CodeDispatchStatus('DISPATCHED', 'Versendet');
  public static readonly DELIVERED = new CodeDispatchStatus('DELIVERED', 'Zugestellt');
  public static readonly NOT_DELIVERED = new CodeDispatchStatus('NOT_DELIVERED', 'Nicht zugestellt');
  public static readonly SENT = new CodeDispatchStatus('SENT', 'Erfolgreich verschickt');
  public static readonly FAILED = new CodeDispatchStatus('FAILED', 'Versand fehlgeschlagen');
  public static readonly RELOAD_LOCK = new CodeDispatchStatus('RELOAD_LOCK', 'SMS-Reloadsperre');
  public static readonly CARRIER_NOT_AVAILABLE = new CodeDispatchStatus('CARRIER_NOT_AVAILABLE', 'Carrier temporär nicht verfügbar');
  public static readonly CODE_ALREADY_USED = new CodeDispatchStatus('CODE_ALREADY_USED', 'Der Code wurde vom Kunden bereits eingelöst. Ein erneuter Versand ist daher nicht möglich.');

  public static readonly ALL = [
    CodeDispatchStatus.DISPATCHED,
    CodeDispatchStatus.DELIVERED,
    CodeDispatchStatus.SENT,
    CodeDispatchStatus.NOT_DELIVERED,
    CodeDispatchStatus.RELOAD_LOCK,
    CodeDispatchStatus.CARRIER_NOT_AVAILABLE,
    CodeDispatchStatus.CODE_ALREADY_USED,
  ];

  public static readonly SUCCESSFUL = [
    CodeDispatchStatus.DISPATCHED,
    CodeDispatchStatus.DELIVERED,
    CodeDispatchStatus.SENT,
  ];

  public static getById(id: string): CodeDispatchStatus {
    return CodeDispatchStatus.ALL.filter((status) => status.id === id)[0];
  }
  private constructor(
    public id: string,
    public label: string,
  ) {
    super();
  }

  public isSuccessful(): boolean {
    return CodeDispatchStatus.SUCCESSFUL.includes(this);
  }

  public getMessage(method: CodeDispatchMethod): string {
    return method.messages.hasOwnProperty(this.id) ? method.messages[this.id] : method.messages.FAILED;
  }

}
