import fetchList, { FetchListPayload, FetchListAction } from '@/utils/fetchList';
import MinimalArticle from '@/domains/article/MinimalArticle';

export interface FetchArticleForHighlightsListPayload extends FetchListPayload {
  mandantId?: string;
}

const fetchArticleForHighlightsList: FetchListAction<MinimalArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchArticleForHighlightsListPayload,
) => {
  const { mandantId } = fetchListPayload;

  let url;
  if (rootGetters.isSimulatedMandant) {
    url = `/mandant/${mandantId}/article`;
  } else {
    url = '/article-summary';
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: MinimalArticle.create,
    listMutation: 'setArticleForHighlights',
    listInfoMutation: 'setArticleForHighlightsListInfo',
  });
};

export default fetchArticleForHighlightsList;
