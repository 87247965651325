import { download } from '@/utils/doRequest';
import FetchFn from '@/types/FetchFn';
import getDateStringForFileName from '@/utils/getDateStringForFileName';

export interface DownloadArticleListPayload {
  fetch: FetchFn;
}

const downloadArticleList = async (
  { commit, dispatch, rootGetters },
  payload: DownloadArticleListPayload
) => {
  return new Promise<any>((resolve) => {
    const { fetch } = payload;

    const downloadRoute = `/api/download/articles`;

    // Generate file name
    const fileNameSlug = 'article-overview';
    const fileName = `${fileNameSlug}-${getDateStringForFileName(
      new Date()
    )}.csv`;

    download({ commit, dispatch }, fetch, downloadRoute, fileName, true).then(
      resolve
    );
  });
};

export default downloadArticleList;
