import { download } from '@/utils/doRequest';
import FetchFn from '@/types/FetchFn';
import getDateStringForFileName from '@/utils/getDateStringForFileName';

export interface DownloadCampaignReportPayload {
  fetch: FetchFn;
  campaignId: string;
  campaignName: string;
}

const downloadCampaignReport = async (
  { commit, dispatch, rootGetters },
  payload: DownloadCampaignReportPayload,
) => {
  return new Promise<any>((resolve) => {
    const { fetch, campaignId, campaignName } = payload;

    let reportLink;
    if (rootGetters.isMandant) {
      reportLink = `/my/reporting/campaign/${campaignId}/file`;
    } else {
      const simulatedMandantId = rootGetters.getSimulatedMandantId;
      if (simulatedMandantId) {
        reportLink = `/reporting/${simulatedMandantId}/campaign/${campaignId}/file`;
      } else {
        return;
      }
    }

    // Generate file name
    const fileNameSlug = 'report-' + campaignName.toLowerCase().replace(/ /g, '-');
    const fileName = `${fileNameSlug}-${getDateStringForFileName(new Date())}.csv`;

    download({ commit, dispatch }, fetch, reportLink, fileName, true)
      .then(resolve);
  });
};

export default downloadCampaignReport;
