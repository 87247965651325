import createFakeResponse from '../../../test-helper/createFakeResponse';
import sortAscending from '@/utils/sortAscending';
import sortDescending from '@/utils/sortDescending';

export const itemsFirstPage = [
  {
    id: '034869b8-0675-4a7f-b868-48507f674179',
    code: 'AAI',
    createdAt: '2018-09-01',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: '     ',
  },
  {
    id: '080a1f7a-cd82-4ab3-a71e-2c21337a36b3',
    code: 'AAS',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 18',
    active: true,
  },
  {
    id: '30a0f227-c683-4002-8162-f03e540387cc',
    code: 'AAT',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 19',
  },
  {
    id: '3dd45786-1d47-49de-b2d8-da4949591f74',
    code: 'AAB',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 1',
  },
  {
    id: '3e3845f5-a4ec-41bf-9943-f12a43a63410',
    code: 'AAK',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 10',
  },
  {
    id: '473a3bc6-6b95-4e86-8052-cadf0d0d655c',
    code: 'AAQ',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 16',
  },
  {
    id: '5a29b7cb-c9c9-4062-96c9-a5909e0d59e4',
    code: 'AAP',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 15',
  },
  {
    id: '619cd308-8d91-48d8-90f4-5e3deab1dcd8',
    code: 'AAF',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 5',
  },
  {
    id: '7e1ecdb2-adaf-406f-b9b3-107bc12d61e5',
    code: 'AAC',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 2',
  },
  {
    id: '8060975f-8879-4912-89bf-095c4fb6c549',
    code: 'AAN',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 13',
  },
];
export const itemsSecondPage = [
  {
    id: '034869b8-0675-4a7f-b868-48507f674171',
    code: 'BAI',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 18',
  },
  {
    id: '080a1f7a-cd82-4ab3-a71e-2c21337a36b2',
    code: 'BAS',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 118',
  },
  {
    id: '30a0f227-c683-4002-8162-f03e540387c3',
    code: 'BAT',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 119',
  },
  {
    id: '3dd45786-1d47-49de-b2d8-da4949591f20',
    code: 'BAB',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 11',
  },
  {
    id: '3e3845f5-a4ec-41bf-9943-f12a43a63415',
    code: 'BAK',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 110',
  },
  {
    id: '473a3bc6-6b95-4e86-8052-cadf0d0d6556',
    code: 'BAQ',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 116',
  },
  {
    id: '5a29b7cb-c9c9-4062-96c9-a5909e0d59e7',
    code: 'BAP',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 115',
  },
  {
    id: '619cd308-8d91-48d8-90f4-5e3deab1dcd9',
    code: 'BAF',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 15',
  },
  {
    id: '7e1ecdb2-adaf-406f-b9b3-107bc12d61e8',
    code: 'BAC',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 12',
  },
  {
    id: '8060975f-8879-4912-89bf-095c4fb6c541',
    code: 'BAN',
    address: {
      country: 'Deutschland',
      state: 'Hamburg',
      city: 'Hamburg',
      zipCode: '22145',
      street: {
        name: 'Friedensallee',
        number: '71',
      },
    },
    name: 'Mandant 113',
  },
];
export const bodyFirstPage = {
  page: 1,
  limit: 10,
  pages: 2,
  total: 20,
  _embedded: {
    items: itemsFirstPage,
  },
};
export const bodySecondPage = {
  page: 2,
  limit: 10,
  pages: 2,
  total: 20,
  _embedded: {
    items: itemsSecondPage,
  },
};
export const body20FirstPage = {
  page: 1,
  limit: 20,
  pages: 1,
  total: 20,
  _embedded: {
    items: [...itemsFirstPage, ...itemsSecondPage],
  },
};

export const allItems = [...itemsFirstPage, ...itemsSecondPage];

export const bodyFiltered1FirstPage = {
  page: 1,
  limit: 10,
  pages: 2,
  total: 17,
  _embedded: {
    items: [...allItems.filter((item) => item.name.indexOf('1') !== -1)],
  },
};
export const bodyFiltered12FirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 1,
  _embedded: {
    items: [...allItems.filter((item) => item.name.indexOf('12') !== -1)],
  },
};

export const bodySortedCodeASCFirstPage = {
  page: 1,
  limit: 10,
  pages: 2,
  total: 20,
  _embedded: {
    items: [...allItems.sort((a, b) => sortAscending(a.code, b.code))].slice(
      0,
      10,
    ),
  },
};
export const bodySortedCodeDESCFirstPage = {
  page: 1,
  limit: 10,
  pages: 2,
  total: 20,
  _embedded: {
    items: [...allItems.sort((a, b) => sortDescending(a.code, b.code))].slice(
      0,
      10,
    ),
  },
};

export const mandantListFiltered1FirstPage = createFakeResponse(
  bodyFiltered1FirstPage,
);
export const mandantListFiltered12FirstPage = createFakeResponse(
  bodyFiltered12FirstPage,
);

export const mandantListSortedCodeASCFirstPage = createFakeResponse(
  bodySortedCodeASCFirstPage,
);
export const mandantListSortedCodeDESCFirstPage = createFakeResponse(
  bodySortedCodeDESCFirstPage,
);

export const mandantListFirstPage = createFakeResponse(bodyFirstPage);
export const mandantListSecondPage = createFakeResponse(bodySecondPage);
export const mandantList20FirstPage = createFakeResponse(body20FirstPage);
