import Domain from '@/types/Domain';
import CodeDispatchStatus from '@/types/CodeDispatchStatus';
import { CustomerData } from '@/domains/codeDispatch/CodeDispatch';
import Gender from '@/types/Gender';

export interface Props {
  createdAt: string;
  customerData: CustomerData;
  dispatchStatus: CodeDispatchStatus;
  userEmail?: string;
}

class CodeDispatchHistoryItem extends Domain implements Props {
  public static createAll(items: any[]): CodeDispatchHistoryItem[] {
    return items.map(CodeDispatchHistoryItem.create);
  }

  public static create(data: any = {}): CodeDispatchHistoryItem {
    const customerData = data.customerData;
    if (customerData) {
      customerData.gender = Gender.getById(customerData.gender);
    }

    return new CodeDispatchHistoryItem(
      data.createdAt,
      customerData,
      typeof data.dispatchStatus === 'string' ? CodeDispatchStatus.getById(data.dispatchStatus) : data.dispatchStatus,
      data.userEmail,
    );
  }

  protected constructor(
    public createdAt: string,
    public customerData: CustomerData,
    public dispatchStatus: CodeDispatchStatus,
    public userEmail?: string,
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): CodeDispatchHistoryItem {
    return CodeDispatchHistoryItem.create(JSON.parse(JSON.stringify(this)));
  }
}

export default CodeDispatchHistoryItem;
