import { GetterTree } from 'vuex';
import { UserState } from './state';
import { ListInfo } from '@/types/ListInfo';
import User from '@/domains/mandant/User';
import MinimalUser from '@/domains/mandant/MinimalUser';
import NormalizedData from '@/types/NormalizedData';

type UserGetter = (id: string) => User;

export const getUser = (state: UserState): UserGetter => (id: string): User =>
  state.users[id];

export const getMinimalUsers = (state: UserState): NormalizedData<MinimalUser> => {
  return state.minimalUsers;
};

export const getUserListInfo = (state: UserState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<UserState, any> = {
  getUser,
  getMinimalUsers,
  getUserListInfo,
};

export default getters;
