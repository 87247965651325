import Mandant from '@/domains/mandant/Mandant';
import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/api-platform/pushSingle';

const editMandant: PushSingleAction<Mandant> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Mandant>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'PUT', pushPayload, {
    url: `/mandants/${id}`,
    mutation: 'addMandants',
  });
};

export default editMandant;
