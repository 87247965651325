import UiAction from '../UiAction';
import PopupPayload from '@/types/PopupPayload';

const displayPopup: UiAction = (
  { commit },
  payload: PopupPayload,
): void => {
  commit('addPopup', payload);
};

export default displayPopup;
