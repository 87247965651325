import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import CampaignArticle from '@/domains/campaign/CampaignArticle';

// TODO: should this be renamed?
// it is not really fetching PortfolioArticles but
// differs from fetchCampaignArticleList by the mandant based route
const fetchCampaignPortfolioArticleList: FetchListAction<CampaignArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchListPayload,
) => {
  let url;
  if (rootGetters.isMandant) {
    url = `/my/article`;
  } else {
    const simulatedMandantId = rootGetters.getSimulatedMandantId;
    if (simulatedMandantId) {
      url = `/mandant/${simulatedMandantId}/article`;
    } else {
      return;
    }
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: CampaignArticle.create,
    listMutation: 'setCampaignArticles',
    listInfoMutation: 'setCampaignArticleListInfo',
  });
};

export default fetchCampaignPortfolioArticleList;
