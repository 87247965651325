import Domain from '@/types/Domain';
import CustomValidation from '@/domains/freeTextField/CustomValidation';
import RegExpValidation from '@/domains/freeTextField/RegExpValidation';
import CampaignCustomerField from '@/domains/campaign/CampaignCustomerField';


interface Props {
  name: string;
  description: string;
  selectedValidation?: string;
  customValidation?: CustomValidation;
  regExpValidation?: RegExpValidation;
  required?: boolean;
  type?: string;
  caseSensitive?: boolean;
}

class FreeTextField extends Domain implements Props {
  public static readonly ValidationOptions = [
    { value: 'not required', label: 'Keine Validierung' },
    { value: 'email', label: 'E-Mail' },
    { value: 'phone', label: 'Telefonnummer' },
    { value: 'numbers', label: 'nur Zahlen' },
    { value: 'characters', label: 'nur Buchstaben' },
    { value: 'custom', label: 'Benutzerdefiniert' },
    { value: 'regexp', label: 'RegExp - Entwicklermodus' },
  ];

  public static readonly ValidationRegExp = {
    'not required': '^(optional)?.*$',
    /* tslint:disable-next-line:max-line-length */
    email: '^(([^<>()[\\].,;:\\s@"]{1,}(\\.[^<>()[\\].,;:\\s@"]{1,})*)|(".{1,}"))@(([^<>()[\\].,;:\\s@"]{1,}\\.){1,}[^<>()[\\].,;:\\s@"]{2,})$',
    phone: '^[\\+]*[\\(]{0,1}[0-9]{1,4}[\\)]{0,1}[\\-\\s\\.\\/0-9]*$',
    numbers: '^[0-9]*$',
    characters: '^[a-zäöüA-ZÄÖÜ]*$',
  };

  public static readonly CustomValidation = {
    email: CustomValidation.create({
      numbers: true, charactersEnabled: true, charactersLowerEnabled: true, charactersUpperEnabled: true,
      specialCharactersEnabled: true,
    }),
    phone: CustomValidation.create({ numbersEnabled: true, specialCharactersEnabled: true }),
    numbers: CustomValidation.create({ numbersEnabled: true }),
    characters: CustomValidation.create({
      charactersEnabled: true, charactersLowerEnabled: true, charactersUpperEnabled: true,
    }),
  };

  public static createAll(data: Props[]): FreeTextField[] {
    return data.map(FreeTextField.create);
  }

  public static create(data: Props): FreeTextField {
    return new FreeTextField(
      data.name,
      data.description,
      data.selectedValidation || 'not required',
      CustomValidation.create(data.customValidation || {}),
      RegExpValidation.create(data.regExpValidation || {}),
      data.required === undefined ?
        true :
        typeof data.required === 'boolean' ? data.required : !!parseInt(data.required),
      data.type || 'TEXT',
      data.caseSensitive || true,
    );
  }

  public static fromCampaignCustomerField(customerField: CampaignCustomerField): FreeTextField {
    // Determine selected validation based on
    let selectedValidation = '';
    if ((customerField.regExp === '' && customerField.required) || customerField.regExp === '^(optional)?.*$') {
      selectedValidation = 'not required';
    } else {
      switch (customerField.regExp) {
        case this.ValidationRegExp.email:
          selectedValidation = 'email';
          break;

        case this.ValidationRegExp.phone:
          selectedValidation = 'phone';
          break;

        case this.ValidationRegExp.numbers:
          selectedValidation = 'numbers';
          break;

        case this.ValidationRegExp.characters:
          selectedValidation = 'characters';
          break;
      }

      if (selectedValidation === '') {
        if (customerField.regExp?.includes('(custom)?')) {
          selectedValidation = 'custom';
        } else {
          selectedValidation = 'regexp';
        }
      }
    }

    let customValidation = CustomValidation.create();
    switch (selectedValidation) {
      case 'not required':
      case 'email':
      case 'phone':
      case 'numbers':
      case 'characters':
      case 'caseSensitive':
        customValidation = FreeTextField.CustomValidation[selectedValidation];
        break;

      case 'custom':
        customValidation = CustomValidation.fromRegExp(customerField.regExp);
        break;
    }

    const regExpValidation = selectedValidation === 'regexp' ?
      RegExpValidation.fromRegExp(customerField.regExp) : RegExpValidation.create();

    return this.create({
      name: customerField.name,
      description: customerField.description,
      selectedValidation,
      customValidation,
      regExpValidation,
      required: customerField.required,
      type: customerField.type,
      caseSensitive: customerField.caseSensitive,
    });
  }

  private constructor(
    public name: string,
    public description: string,
    public selectedValidation: string,
    public customValidation: CustomValidation,
    public regExpValidation: RegExpValidation,
    public required: boolean,
    public type: string,
    public caseSensitive: boolean,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      name: {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
        format: {
          pattern: /^.{3,250}$/,
          message: '^Dieses Feld muss zwischen 3 und 250 Zeichen lang sein',
        },
      },
      description: {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
        format: {
          pattern: /^.{3,1000}$/,
          message: '^Dieses Feld muss zwischen 3 und 1000 Zeichen lang sein',
        },
      },
      required: {
        presence: true,
      },
      type: {
        presence: false,
        format: {
          pattern: /^(TEXT)$/,
          message: '^Dieses Feld muss einen der folgenden Werte enthalten: TEXT',
        },
      },
    };
  }

  public clone(): FreeTextField {
    return FreeTextField.create(JSON.parse(JSON.stringify(this)));
  }

  public toRegExp(): string {
    const regExp = FreeTextField.ValidationRegExp[this.selectedValidation];
    if (regExp) {
      return regExp;
    } else {
      switch (this.selectedValidation) {
        case 'custom':
          return this.customValidation.toRegExp();

        case 'regexp':
          return this.regExpValidation.toRegExp();

        default:
          return '';
      }
    }
  }

  public toConstraints(): {} {
    if (this.required && this.selectedValidation === 'not required') {
      return {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
      };
    }

    if (this.selectedValidation === 'not required') {
      return {};
    }

    let message = '';
    switch (this.selectedValidation) {
      case 'phone':
        message = '^Dieses Feld muss eine gültige Telefonnummer enthalten.';
        break;

      case 'numbers':
        message = '^Dieses Feld darf nur Zahlen enthalten.';
        break;

      case 'characters':
        message = '^Dieses Feld darf nur Buchstaben enthalten.';
        break;

      case 'custom':
        message = this.customValidation.toConstraintMessage(this.name);
        break;

      case 'regexp':
        message = '^Dies ist keine gültige Eingabe.';
        break;

      case 'email':
      default:
        message = '^Dieses Feld muss eine gültige E-Mail Adresse enthalten.';
        break;
    }

    let presence = {};
    if (this.required) {
      presence = {
        presence: {
          message: '^Dieses Feld ist ein Pflichtfeld.',
        },
      };
    }


    return Object.assign({
      format: {
        pattern: new RegExp(this.toRegExp()),
        message,
      },
    }, presence);
  }
}

export default FreeTextField;
