import UiAction from '../UiAction';
import ToastNotificationPayload from '@/types/ToastNotificationPayload';

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const displayToastNotification: UiAction = async (
  { commit, dispatch },
  payload: ToastNotificationPayload,
): Promise<void> => {
  let duration = 8000;
  if (payload.hasOwnProperty('duration')) {
    duration = payload.duration;
    delete payload.duration;
  }
  const id: string = await dispatch('addToastNotification', payload);

  timeout(duration)
    .then(() => commit('removeToastNotification', id));
};

export default displayToastNotification;
