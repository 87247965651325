import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import ToastNotificationPayload from '@/types/ToastNotificationPayload';
import styles from './ToastNotification.module.scss';
import classNames from 'classnames';
import ToastNotificationStatus from '@/types/ToastNotificationStatus';

interface Props extends ToastNotificationPayload {
  key?: string;
  closeHandler: (id: string) => void;
}

@Component({
  props: {
    id: String,
    message: String,
    status: Object,
    closeHandler: Function,
  },
})
class ToastNotification extends TsxComponent<Props> {
  private render() {
    const { id, message, status, closeHandler } = this.$props;

    return (
      <div class={styles['toast-notification']}>
        <div class={classNames(
          styles['toast-notification__status-indicator'],
          status === ToastNotificationStatus.SUCCESS && styles['toast-notification__status-indicator__success'],
          status === ToastNotificationStatus.INFO && styles['toast-notification__status-indicator__info'],
          status === ToastNotificationStatus.ERROR && styles['toast-notification__status-indicator__error'],
          status === ToastNotificationStatus.WARNING && styles['toast-notification__status-indicator__warning'],
        )}/>
        <p>{ message }</p>
        <font-awesome-icon
          icon="times"
          class={styles['toast-notification__close-icon']}
          onClick={() => closeHandler(id)}
        />
      </div>
    );
  }
}

export default ToastNotification;
