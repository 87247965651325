import Tag from '@/domains/tag/Tag';
import NormalizedData from '@/types/NormalizedData';
import { ListInfo } from '@/types/ListInfo';


export interface TagState {
  tags: NormalizedData<Tag>;
  listInfo: ListInfo;
}

const state: TagState = {
  tags: {},
  listInfo: ListInfo.create(),
};

export default state;
