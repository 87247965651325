import { MutationTree } from 'vuex';
import { CodeState } from './state';
import { ListInfo } from '@/types/ListInfo';
import Code from '@/domains/code/Code';
import NormalizedData from '@/types/NormalizedData';

export const setCodes = (
  state: CodeState,
  codes: NormalizedData<Code>,
): void => {
  state.codes = {
    ...codes,
  };
};

export const setCodeListInfo = (state: CodeState, info: ListInfo): void => {
  state.listInfo = info;
};

const mutations: MutationTree<CodeState> = {
  setCodes,
  setCodeListInfo,
};

export default mutations;
