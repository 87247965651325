import createFakeResponse from '../../../test-helper/createFakeResponse';

export const itemsFirstPage = [
  {
    id: '8ab919c7-b343-47d5-8d12-deb4788e1fe4',
    active: true,
    name: 'Article 10',
    categoryShortcut: 'ZU',
    variantCount: 1,
  },
  {
    id: '0221117a-3485-4ad6-8722-07d9a32c418b',
    active: true,
    name: 'Article 19',
    categoryShortcut: 'ZU',
    variantCount: 2,
  },
  {
    id: '3ca094eb-dee6-43dd-847d-0d44669876f9',
    active: true,
    name: 'Article 18',
    categoryShortcut: 'HW',
    variantCount: 1,
  },
  {
    id: '9107038b-8635-4f1f-9dc5-5fbba29be3c5',
    active: true,
    name: 'Article 14',
    categoryShortcut: 'MD',
    variantCount: 3,
  },
];

export const bodyFirstPage = {
  page: 1,
  limit: 10,
  pages: 1,
  total: 4,
  _embedded: {
    items: itemsFirstPage,
  },
};

export const articleListFirstPage = createFakeResponse(bodyFirstPage);
