import fetchList, { FetchListAction, FetchListPayload } from '@/utils/fetchList';
import HighlightArticle from '@/domains/highlight/HighlightArticle';


export interface FetchHighlightsListPayload extends FetchListPayload {
  mandantId?: string;
  dontSave?: boolean;
}

const fetchHighlightsList: FetchListAction<HighlightArticle> = async (
  { commit, dispatch, rootGetters },
  fetchListPayload: FetchHighlightsListPayload,
) => {
  const {
    mandantId,
    dontSave,
  } = fetchListPayload;

  let url;
  if (rootGetters.isMandant) {
    url = '/my/highlights';
  } else {
    if (mandantId) {
      url = `/mandant/${mandantId}/highlights`;
    } else {
      url = '/highlights';
    }
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
    url,
    domainParser: HighlightArticle.create,
    listMutation: !dontSave && 'setHighlights',
  }, true);
};

export default fetchHighlightsList;
