import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import UserGroup from '@/domains/userGroup/UserGroup';

const deleteUserGroup: PushSingleAction<UserGroup> = async (
  { commit, dispatch },
  payload: PushSinglePayload<UserGroup>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  const url = `/user-groups/${id}`;

  return await pushSingle({ commit, dispatch }, 'DELETE', pushPayload, {
    url,
    mutation: undefined,
  });
};

export default deleteUserGroup;
