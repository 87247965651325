import { MutationTree } from 'vuex';
import { UserState } from './state';
import { ListInfo } from '@/types/ListInfo';
import User from '@/domains/mandant/User';
import NormalizedData from '@/types/NormalizedData';
import MinimalUser from '@/domains/mandant/MinimalUser';

export const addUsers = (
  state: UserState,
  users: NormalizedData<User>,
): void => {
  state.users = {
    ...state.users,
    ...users,
  };
};

export const setMinimalUsers = (
  state: UserState,
  minimalUsers: NormalizedData<MinimalUser>,
): void => {
  state.minimalUsers = {
    ...minimalUsers,
  };
};

export const setUserListInfo = (state: UserState, info: ListInfo): void => {
  state.listInfo = info;
};

const mutations: MutationTree<UserState> = {
  addUsers,
  setMinimalUsers,
  setUserListInfo,
};

export default mutations;
