import Domain from '@/types/Domain';

interface Props {
  skonContactEmail: string;
  skonContactFirstName?: string;
  skonContactLastName?: string;
  skonContactPhone: string;
}

class MandantContactDataForSkon extends Domain implements Props {
  public static createAll(mandants: any[]): MandantContactDataForSkon[] {
    return mandants.map(MandantContactDataForSkon.create);
  }

  public static create(data: any): MandantContactDataForSkon {
    return new MandantContactDataForSkon(
      data.skonContactEmail,
      data.skonContactFirstName,
      data.skonContactLastName,
      data.skonContactPhone,
    );
  }

  private constructor(
    public skonContactEmail: string,
    public skonContactFirstName: string,
    public skonContactLastName: string,
    public skonContactPhone: string,
  ) {
    super();
  }

  public clone(): MandantContactDataForSkon {
    return JSON.parse(JSON.stringify(this));
  }

  public constraints(): {} {
    return {
      skonContactEmail: {
        presence: {
          message: '^Dieses Feld wird benötigt.',
        },
        email: {
          message: '^Dieses Feld muss eine Email Adresse enthalten',
        },
      },
      skonContactPhone: {
        presence: {
          message: '^Dieses Feld wird benötigt.',
        },
        format: {
          pattern: /^0[0-9]{5,26}$/,
          message: '^Muss mit 0 beginnen und darf 6 bis 27 Zeichen enthalten.',
        },
      },
    };
  }
}

export default MandantContactDataForSkon;
