import createFakeResponse from '../../../test-helper/createFakeResponse';

export const mandantEdit = createFakeResponse({
  id: '034869b8-0675-4a7f-b868-48507f674179',
  code: 'AAI',
  address: {
    street: { name: 'Jungfernstieg', number: '1' },
    zipCode: '22222',
    city: 'Hamburg',
    state: 'Hamburg',
  },
  name: 'Mandant 8',
  vatIdNumber: 'DE123456789',
  shopHost: 'mandant-8',
  creditLimit: { amount: 10000000, currency: 'EUR' },
  operationFee: { amount: 2350, currency: 'EUR' },
  ownArticleFee: { amount: 1399, currency: 'EUR' },
  // modules: {
  //   payment: true,
  // },
  designData: {
    buttonColor: '#FF9900',
    backgroundColor: '#FF9900',
    logo: 'https://image.freepik.com/freie-ikonen/apple-logo_318-40184.jpg',
  },
});
