import FetchFn from '@/types/FetchFn';
import { ListFilter } from '@/types/ListFilter';
import { ListSort } from '@/types/ListSort';
import { ListInfo } from '@/types/ListInfo';
import getRequestListUri from '@/utils/api-platform/getRequestListUri';
import { get } from '@/utils/api-platform/doRequest';
import ListResponse from '@/types/ListResponse';
import getListResponseEntries from '@/utils/api-platform/getListResponseEntries';
import normalizeData from '@/utils/normalizeData';
import NormalizedData from '@/types/NormalizedData';
import Domain from '@/types/Domain';
import CampaignArticle from '@/domains/campaign/CampaignArticle';
import PortfolioArticle from '@/domains/portfolio/PortfolioArticle';

export interface FetchListPayload {
  fetch: FetchFn;
  page?: number;
  limit?: number;
  filters?: ListFilter[];
  sorts?: ListSort[];
  pagination?: boolean;
}

export interface FetchMandantSpecificListPayload extends FetchListPayload {
  mandantId: string;
}

export interface ExtendedFetchListPayload<T extends Domain> {
  url: string;
  domainParser: (data: any) => T;
  listMutation?: string;
  listInfoMutation?: string;
}

export type FetchListAction<T extends Domain> = (
  {
    commit,
    dispatch,
    rootGetters,
  },
  payload: FetchListPayload,
) => Promise<NormalizedData<T>>;

const fetchList = async<T extends Domain> (
  {
    commit,
    dispatch,
  },
  {
    fetch,
    page,
    limit,
    filters,
    sorts,
    pagination,
  }: FetchListPayload,
  {
    url,
    domainParser,
    listMutation,
    listInfoMutation,
  }: ExtendedFetchListPayload<T>,
  disableFilters?: boolean,
): Promise<NormalizedData<T>> => {
  const uri = !disableFilters ? getRequestListUri(url, page, limit, filters, sorts, pagination) : url;
  let response: ListResponse | any[];

  try {
    response = await get<ListResponse | any[]>({ commit, dispatch }, fetch, uri);
  } catch (error) {
    console.warn(error);
    throw error;
  }

  const list: T[] = getListResponseEntries(response)
    .map((data) => domainParser(data))
    .filter((domain) => {
      if (!domain.validate()) {
        // TODO: REMOVE after CampaignArticle refactoring!!!
        if (domain instanceof CampaignArticle || domain instanceof PortfolioArticle) {
          return true;
        }
        console.error('Domain is erroneous and won\'t be persisted', domain);
        return false;
      } else {
        return true;
      }
    })
    .map((data) => {
      data.cleanse();
      return data;
    })
  ;

  const normalizedList = normalizeData(list);
  if (listMutation) {
    commit(listMutation, normalizedList);
  }

  if (listInfoMutation) {
    let listInfo: ListInfo;
    if (response instanceof Array) {
      // reset list info when response is simple array
      listInfo = ListInfo.create({sorts, filters});
    } else {
      const total: number = response['hydra:totalItems'];
      const pages: number = Math.floor(total / (limit || 10)) + (total % (limit || 10) > 0 ? 1 : 0);

      listInfo = ListInfo.create({
        page,
        pages,
        limit,
        total,
        sorts,
        filters,
      });
    }
    commit(listInfoMutation, listInfo);
  }

  // @ts-ignore
  return normalizedList;
};

export default fetchList;
