import FetchFn from '@/types/FetchFn';
import { get } from '@/utils/api-platform/doRequest';
import Domain from '@/types/Domain';
import normalizeData from '@/utils/normalizeData';

export interface FetchSinglePayload {
  fetch: FetchFn;
  id: string;
}

export interface ExtendedFetchSinglePayload<T extends Domain> {
  url: string;
  domainParser: (data: any) => T;
  mutation: string;
}

export type FetchSingleAction<T extends Domain> = (
  {
    commit,
    dispatch,
    rootGetters,
  },
  payload: FetchSinglePayload,
) => Promise<T>;

const fetchSingle = async<T extends Domain> (
  {
    commit,
    dispatch,
  },
  {
    fetch,
  }: FetchSinglePayload,
  {
    url,
    domainParser,
    mutation,
  }: ExtendedFetchSinglePayload<T>,
): Promise<T> => {
  let response: Domain;

  try {
    response = await get<Domain>({ commit, dispatch }, fetch, url);
  } catch (error) {
    console.warn(error);
    throw error;
  }

  const domain: T = domainParser(response);
  if (!domain.validate()) {
    console.error('Domain is erroneous and won\'t be persisted', domain);
    return;
  }

  domain.cleanse();

  if (mutation) {
    commit(mutation, normalizeData([domain]));
  }

  return domain;
};

export default fetchSingle;
