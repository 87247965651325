import Component from 'vue-class-component';
import classNames from 'classnames';
import TsxComponent from '@/typeDefinitions/vue-tsx';
import styles from './NewInfoHint.module.scss';


interface Props {
  text: string;
  class?: string;
  popoverTextLeft?: boolean;
}

@Component({
  props: {
    text: String,
    popoverTextLeft: {
      type: Boolean,
      default: true,
    },
  },
})
class NewInfoHint extends TsxComponent<Props> {
  private render() {
    const {
      text,
      popoverTextLeft,
    } = this.$props;
    const additionalClasses = this.$props.class;

    const popoverConfig = {
      customClass: (popoverTextLeft && 'popover-no-center'),
      content: text,
      placement: 'top',
      trigger: 'hover',
    };

    return (
      <span
        class={classNames(
          styles['info-hint'],
          additionalClasses,
        )}
        v-b-popover={popoverConfig}
      >
        i
      </span>
    );
  }
}

export default NewInfoHint;
