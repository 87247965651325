import Domain from '@/types/Domain';

interface Props {
  name: string;
  count: number;
}

class ArticleInfo extends Domain implements Props {
  public static createAll(data: any[] = []): ArticleInfo[] {
    return data.map(ArticleInfo.create);
  }

  public static create(data: any = {}): ArticleInfo {
    return new ArticleInfo(
      data.name,
      data.count,
    );
  }

  private constructor(
    public name: string,
    public count: number,
  ) {
    super();
  }

  public constraints(): {} {
    return {
      // TODO: add constraints
    };
  }

  public clone(): ArticleInfo {
    return ArticleInfo.create(this);
  }
}

export default ArticleInfo;
