import { MutationTree } from 'vuex';
import RemainingCodes from '@/domains/cockpit/RemainingCodes';
import { CockpitState } from './state';


export const setRemainingCodes = (
  state: CockpitState,
  remainingCodes: RemainingCodes,
): void => {
  state.remainingCodes = remainingCodes;
};

const mutations: MutationTree<CockpitState> = {
  setRemainingCodes,
};

export default mutations;
