import Vue from 'vue';
import App from './App.tsx';
import router from './router';
import store from './store';
import VueBreadcrumbs from 'vue-breadcrumbs';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAngleDown,
  faAngleUp,
  faArrowsAlt,
  faBell,
  faBookOpen,
  faBullhorn,
  faCarSide,
  faChartLine,
  faCheck,
  faCheckCircle,
  faCircle,
  faCircleNotch,
  faCloudUploadAlt,
  faCog,
  faCopy,
  faDownload,
  faEdit,
  faEquals,
  faExclamation,
  faFileDownload,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFolderOpen,
  faHeart,
  faImage,
  faInbox,
  faIndustry,
  faInfo,
  faInfoCircle,
  faLightbulb,
  faLink,
  faListAlt,
  faLock,
  faMedal,
  faMinus,
  faPaperPlane,
  faPlus,
  faPlusCircle,
  faPowerOff,
  faQuestion,
  faRedoAlt,
  faSearch,
  faSearchMinus,
  faSearchPlus,
  faSlidersH,
  faTachometerAlt,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUniversalAccess,
  faUser,
  faUserCircle,
  faUserEdit,
  faUserFriends,
  faUserPlus,
  faUsers,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import {
  faCalendarAlt,
  faEnvelope,
  faEye,
  faFolder,
  faTimesCircle as farTimesCircle,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import responseStubs from '../tests/test-helper/responseStubs';
import { VBPopoverPlugin } from 'bootstrap-vue';
import validate from 'validate.js';
import constraintAPIFormatter from '@/utils/constraintAPIFormatter';
import { Chart } from 'chart.js';
import frag from 'vue-frag';
// TODO: usage is outdated
import { getMissingTextVars } from '@/views/campaign/utils/checkMessageTextVars';

library.add(faTachometerAlt);
library.add(faUserTie);
library.add(faFileInvoiceDollar);
library.add(faChartLine);
library.add(faUserFriends);
library.add(faBookOpen);
library.add(faFolderOpen);
library.add(faCarSide);
library.add(faBullhorn);
library.add(faListAlt);
library.add(faCog);
library.add(faCopy);
library.add(faUniversalAccess);
library.add(faBell);
library.add(faUserCircle);
library.add(faPowerOff);
library.add(faSlidersH);
library.add(faUser);
library.add(faPaperPlane);
library.add(faLock);
library.add(faSearch);
library.add(faSearchPlus);
library.add(faSearchMinus);
library.add(faRedoAlt);
library.add(faTimesCircle);
library.add(faUserEdit);
library.add(faEdit);
library.add(faTrashAlt);
library.add(faUserPlus);
library.add(faMedal);
library.add(faEnvelope);
library.add(faFolder);
library.add(faLink);
library.add(faPlusCircle);
library.add(faFileImage);
library.add(faCloudUploadAlt);
library.add(faCheck);
library.add(faCheckCircle);
library.add(faAngleUp);
library.add(faAngleDown);
library.add(faPlus);
library.add(faFileInvoice);
library.add(faCircle);
library.add(faCircleNotch);
library.add(faInfo);
library.add(faTimes);
library.add(faIndustry);
library.add(faInbox);
library.add(faInfoCircle);
library.add(faEye);
library.add(faCalendarAlt);
library.add(faFileDownload);
library.add(faDownload);
library.add(faHeart);
library.add(faImage);
library.add(faFilePdf);
library.add(faArrowsAlt);
library.add(faMinus);
library.add(faEquals);
library.add(faQuestion);
library.add(farTimesCircle);
library.add(faExclamation);
library.add(faUsers);
library.add(faLightbulb);

// validate.js configs
validate.formatters.api = constraintAPIFormatter;
validate.validators.presence.options = {
  allowEmpty: false,
};

validate.validators.isValidMessageTemplate = (value: string, options) => {
  const missingVars: string[] = getMissingTextVars(
    value,
    options.mandatoryVars
  );

  if (missingVars.length) {
    const relatedVarsMissing = missingVars.filter((name) => {
      if (name === '$ShopUrl' || name === '$Code') {
        return name;
      }
    });

    if (relatedVarsMissing.length) {
      const missingRelatedVarsWithoutOthers = missingVars.filter((item) => {
        return item === '$ShopUrl' || item === '$Code';
      });

      if (relatedVarsMissing.length > 1) {
        const missingRelatedVars =
          missingRelatedVarsWithoutOthers.join(' oder ');
        return `^Eine der folgenden Variablen muss im Text enthalten sein: ${missingRelatedVars}`;
      }
    }

    const missingVarsWithoutRelatedVars = missingVars.filter((item) => {
      return item !== '$ShopUrl' && item !== '$Code';
    });

    if (missingVarsWithoutRelatedVars.length) {
      missingVarsWithoutRelatedVars.join(', ');
      return `^Folgende Variablen müssen im Text enthalten sein: ${missingVarsWithoutRelatedVars}`;
    }
  }
  return;
};

validate.validators.isValidRegExp = (value, options) => {
  /*
  Options:
  - message = Not valid Regular Expression
   */
  try {
    // tslint:disable-next-line no-unused-expression
    new RegExp(value);
    return;
  } catch {
    return options.message || `is not a valid Regular Expression.`;
  }
};

validate.validators.file = (
  value,
  options,
  attribute,
  attributes,
  globalOptions
) => {
  return new Promise((resolve: any) => {
    if (!value || value.constructor !== File) {
      resolve(
        options.message ||
          options.notFile ||
          '^Bitte laden Sie eine Datei hoch.'
      );
    }
    if (
      options.hasOwnProperty('acceptedFileTypes') &&
      !options.acceptedFileTypes.includes(value.type)
    ) {
      resolve(
        options.message ||
          options.fileTypeNotPermitted ||
          '^Die Datei hat keinen der hier erlaubten Typen. Folgende sind erlaubt: ' +
            options.acceptedFileTypes.concat(', ')
      );
    }
    if (
      options.hasOwnProperty('maxFileSize') &&
      value.size > options.maxFileSize
    ) {
      resolve(
        options.message ||
          options.fileTooLarge ||
          '^Die Datei ist zu groß. Maximal erlaubt sind ' +
            options.maxFileSize +
            ' Bytes.'
      );
    }
    if (
      !options.hasOwnProperty('size') &&
      !options.hasOwnProperty('hasTransparency')
    ) {
      resolve();
    } else {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const testImage = new Image();
        testImage.src = reader.result as string;
        testImage.onload = () => {
          const dimensions = {
            width: testImage.width,
            height: testImage.height,
          };
          if (
            (options.size?.hasOwnProperty('maxImageWidth') &&
              dimensions.width > options.size?.maxImageWidth) ||
            (options.size?.hasOwnProperty('maxImageHeight') &&
              dimensions.height > options.size?.maxImageHeight)
          ) {
            resolve(
              options.size.message ||
                options.size.imageTooLarge ||
                '^Das Bild ist zu groß.' +
                  (options.size.maxImageWidth
                    ? 'Die maximal erlaubte Breite beträgt ' +
                      options.size.maxImageWidth +
                      'px. '
                    : '') +
                  (options.size.maxImageHeight
                    ? 'Die maximal erlaubte Höhe beträgt ' +
                      options.size.maxImageHeight +
                      'px.'
                    : '')
            );
          } else if (
            (options.size?.hasOwnProperty('exactImageWidth') &&
              dimensions.width !== options.size?.exactImageWidth) ||
            (options.size?.hasOwnProperty('exactImageHeight') &&
              dimensions.height !== options.size?.exactImageHeight)
          ) {
            resolve(
              options.size.message ||
                options.size.imageWrongSize ||
                '^Das Bild hat nicht die richtige Größe.' +
                  (options.size.exactImageWidth
                    ? ' Die geforderte Breite beträgt ' +
                      options.size.exactImageWidth +
                      'px.'
                    : '') +
                  (options.size.exactImageHeight
                    ? ' Die geforderte Höhe beträgt ' +
                      options.size.exactImageHeight +
                      'px.'
                    : '')
            );
          } else if (
            options.hasOwnProperty('hasTransparency') &&
            options.hasTransparency === true
          ) {
            const canvas = document.createElement('canvas');
            canvas.width = dimensions.width;
            canvas.height = dimensions.height;

            const ctx = canvas.getContext('2d');
            ctx.drawImage(testImage, 0, 0, dimensions.width, dimensions.height);

            const data = ctx.getImageData(
              0,
              0,
              canvas.width,
              canvas.height
            ).data;

            let hasAlphaPixels = false;

            for (let i = 3, n = data.length; i < n; i += 4) {
              if (data[i] < 255) {
                hasAlphaPixels = true;
                break;
              }
            }
            if (hasAlphaPixels) {
              resolve();
            } else {
              resolve(
                options.message ||
                  options.imageHasNoTransparency ||
                  '^Dieses Bild hat keinen transparenten Hintergrund.'
              );
            }
          } else {
            resolve();
          }
        };
      });
      reader.readAsDataURL(value);
    }
  });
};

validate.validators.numericalityOther = (
  value,
  options,
  attribute,
  attributes,
  globalOptions
) => {
  const errors = [];
  let name;
  let count;
  const checks = {
    greaterThan: (v, c) => v > c,
    greaterThanOrEqualTo: (v, c) => v >= c,
    equalTo: (v, c) => v === c,
    lessThan: (v, c) => v < c,
    lessThanOrEqualTo: (v, c) => v <= c,
    divisibleBy: (v, c) => v % c === 0,
  };

  // Strict will check that it is a valid looking number
  if (typeof value === 'string' && options.strict) {
    let pattern = '^-?(0|[1-9]\\d*)';
    if (!options.onlyInteger) {
      pattern += '(\\.\\d+)?';
    }
    pattern += '$';

    if (!new RegExp(pattern).test(value)) {
      return (
        options.message ||
        options.notValid ||
        // @ts-ignore
        this.notValid ||
        // @ts-ignore
        this.message ||
        'must be a valid number'
      );
    }
  }

  // Coerce the value to a number unless we're being strict.
  if (options.noStrings !== true && typeof value === 'string' && value !== '') {
    value = +value;
  }

  // If it's not a number we shouldn't continue since it will compare it.
  if (isNaN(value)) {
    return (
      options.message ||
      options.notValid ||
      // @ts-ignore
      this.notValid ||
      // @ts-ignore
      this.message ||
      'is not a number'
    );
  }

  // Same logic as above, sort of. Don't bother with comparisons if this
  // doesn't pass.
  if (options.onlyInteger && isNaN(value)) {
    return (
      options.message ||
      options.notInteger ||
      // @ts-ignore
      this.notInteger ||
      // @ts-ignore
      this.message ||
      'must be an integer'
    );
  }

  for (name in checks) {
    // ... Use if to soothe tslint
    if (attributes.hasOwnProperty(name)) {
      count = attributes[name];
      if (!isNaN(count) && !checks[name](value, count)) {
        // This picks the default message if specified
        // For example the greaterThan check uses the message from
        // this.notGreaterThan so we capitalize the name and prepend "not"
        const key = 'not' + name.charAt(0).toUpperCase() + name.slice(1);
        const msg =
          options[key] ||
          // @ts-ignore
          this[key] ||
          // @ts-ignore
          this.message;

        errors.push(msg);
      }
    }
  }

  if (errors.length) {
    return options.message || errors;
  }
};

// chart.js configs
Chart.defaults.global.defaultFontColor = '#21333a';
Chart.defaults.global.animation.easing = 'easeInOutQuad';
Chart.defaults.global.animation.easing = 'easeInOutQuad';
Chart.defaults.global.legend.align = 'start';
Chart.defaults.global.legend.labels.boxWidth = 12.5;
Chart.defaults.global.legend.labels.fontColor = 'rgba(33, 51, 58, 0.6)';
Chart.defaults.global.legend.position = 'bottom';
Chart.defaults.global.tooltips.backgroundColor = '#21333a';
Chart.defaults.global.tooltips.cornerRadius = 2;
Chart.defaults.global.tooltips.displayColors = false;
Chart.defaults.global.tooltips.titleAlign = 'center';
Chart.defaults.global.tooltips.bodyAlign = 'center';


Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueBreadcrumbs);
Vue.use(VBPopoverPlugin);
Vue.directive('frag', frag);

const useStubs = process.env.VUE_APP_USE_STUB === 'true';
Vue.prototype.$fetch = useStubs ? responseStubs : fetch;

new Vue({
  router,
  store,
  render: (h: any) => h(App),
}).$mount('#app');
