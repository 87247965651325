import { MutationTree } from 'vuex';
import { CategoryState } from '../state';
import addCategories from './addCategories';
import setCategories from './setCategories';
import setCategoryListInfo from '@/store/modules/category/mutations/setCategoryListInfo';
import setListCategoryVariants from '@/store/modules/category/mutations/setListCategoryVariants';
import setCategoryVariantListInfo from '@/store/modules/category/mutations/setCategoryVariantListInfo';

const mutations: MutationTree<CategoryState> = {
  addCategories,
  setCategoryListInfo,
  setCategories,
  setCategoryVariantListInfo,
  setListCategoryVariants,
};

export default mutations;
