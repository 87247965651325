import { GetterTree } from 'vuex';
import { OrderState } from './state';
import NormalizedData from '@/types/NormalizedData';
import Order from '@/domains/order/Order';
import MinimalOrder from '@/domains/order/MinimalOrder';
import { ListInfo } from '@/types/ListInfo';
import OrderStatus from '@/types/OrderStatus';

type OrderGetter = (id: string) => Order;

const getOrder = (state: OrderState): OrderGetter => {
  return (id: string): Order => {
    return state.orders[id];
  };
};

export const getMinimalOrders = (state: OrderState): NormalizedData<MinimalOrder> => {
  return state.minimalOrders;
};

export const getOrderListInfo = (state: OrderState): ListInfo => {
  return state.listInfo;
};

const getListStatusFilter = (state: OrderState): OrderStatus => {
  return state.listStatusFilter;
};

const getters: GetterTree<OrderState, any> = {
  getOrder,
  getMinimalOrders,
  getOrderListInfo,
  getListStatusFilter,
};

export default getters;
