import Vue from 'vue';

export interface CustomEvents {
  onSort?: (...args: any[]) => void;
  onFilter?: (...args: any[]) => void;
  onLimit?: (...args: any[]) => void;
  onPaginate?: (...args: any[]) => void;
  onChange?: (...args: any[]) => void;
  onClick?: (...args: any[]) => void;
  onSave?: (...args: any[]) => void;
  onAdd?: (...args: any[]) => void;
  onSubmit?: (...args: any[]) => void;
  onReset?: (...args: any[]) => void;
  onOpen?: (...args: any[]) => void;
  onClose?: (...args: any[]) => void;
  onConfirm?: (...args: any[]) => void;
  onToggle?: (...args: any[]) => void;
  onFocus?: (...args: any[]) => void;
  onBlur?: (...args: any[]) => void;
  onInput?: (...args: any[]) => void;
  onRemove?: (...args: any[]) => void;
  onCancel?: (...args: any[]) => void;
  onSelect?: (...args: any[]) => void;
  onEdit?: (...args: any[]) => void;
  onGoToStep?: (...args: any[]) => void;
  onDispatch?: (...args: any[]) => void;
  onPaste?: (...args: any[]) => void;
}

export default abstract class TsxComponent<P> extends Vue {
  public $props: Readonly<CustomEvents> &
    Readonly<{ dataQa?: string }> &
    Readonly<P>;
  /* tslint:disable:next-line no-unused-variable */
  private vueTsxProps: Readonly<CustomEvents> &
    Readonly<{ dataQa?: string }> &
    Readonly<P>;
}

declare global {
  namespace JSX {
    interface ElementAttributesProperty {
      vueTsxProps: {};
    }
  }
}
