import { ListInfo } from '@/types/ListInfo';
import NormalizedData from '@/types/NormalizedData';
import MinimalMandant from '@/domains/mandant/MinimalMandant';
import Mandant from '@/domains/mandant/Mandant';
import SimpleMandant from '@/domains/mandant/SimpleMandant';

export interface MandantState {
  mandants: NormalizedData<Mandant>;
  minimalMandants: NormalizedData<MinimalMandant>;
  simpleMandants: NormalizedData<SimpleMandant>;
  listInfo: ListInfo;
}

const state: MandantState = {
  mandants: {},
  minimalMandants: {},
  simpleMandants: {},
  listInfo: ListInfo.create(),
};

export default state;
