import Domain from '@/types/Domain';

interface Props {
  contactEmail: string;
  contactPhoneNumber: string;
  hoursOfBusiness: string;
}

class MandantContactDataForCustomer extends Domain implements Props {
  public static createAll(mandants: any[]): MandantContactDataForCustomer[] {
    return mandants.map(MandantContactDataForCustomer.create);
  }

  public static create(data: any): MandantContactDataForCustomer {
    return new MandantContactDataForCustomer(
      data.contactEmail,
      data.contactPhoneNumber,
      data.hoursOfBusiness,
    );
  }

  private constructor(
    public contactEmail: string,
    public contactPhoneNumber: string,
    public hoursOfBusiness: string,
  ) {
    super();
  }

  public clone(): MandantContactDataForCustomer {
    return JSON.parse(JSON.stringify(this));
  }

  public constraints(): {} {
    return {};
    // Disabled this until every Mandant got contact data
    /*return {
      contactEmail: {
        presence: {
          message: '^Dieses Feld wird benötigt.',
        },
        email: {
          message: '^Dieses Feld muss eine Email Adresse enthalten',
        },
      },
      contactPhoneNumber: {
        presence: {
          message: '^Dieses Feld wird benötigt.',
        },
        format: {
          pattern: '',
          message: '^Dieses Feld muss eine Telefonnummer enthalten',
        },
      },
      hoursOfBusiness: {
        presence: {
          message: '^Dieses Feld wird benötigt.',
        },
      },
    };*/
  }
}

export default MandantContactDataForCustomer;
