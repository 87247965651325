import Domain from '@/types/Domain';
import CampaignCustomerField from '@/domains/campaign/CampaignCustomerField';


interface Props {
  image: File;
  description: string;
  displayName: string;
  customErrorMessage: string;
  columnInformations: CampaignCustomerField[];
}

class CampaignAccessProperties extends Domain implements Props {

  public static create(data: any): CampaignAccessProperties {
    return new CampaignAccessProperties(
      data.image || undefined,
      data.description || undefined,
      data.displayName || undefined,
      data.customErrorMessage || null,
      data.columnInformations || [],
    );
  }

  private constructor(
    public image: File,
    public description: string,
    public displayName: string,
    public customErrorMessage: any,
    public columnInformations: CampaignCustomerField[],
  ) {
    super();
  }

  public constraints(): {} {
    return {};
  }

  public clone(): CampaignAccessProperties {
    return CampaignAccessProperties.create(JSON.parse(JSON.stringify(this)));
  }
}

export default CampaignAccessProperties;
