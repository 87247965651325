import TsxComponent from '@/typeDefinitions/vue-tsx';
import Component from 'vue-class-component';
import { Action } from 'vuex-class';
import OrderStatus from '@/types/OrderStatus';
import OrderStatusCanceled from '@/domains/order/OrderStatusCanceled';
import OrderStatusParked from '@/domains/order/OrderStatusParked';
import ConfirmPopup from '../popup/ConfirmPopup';
import { PushSinglePayload } from '@/utils/pushSingle';
import Domain from '@/types/Domain';
import OrderStatusVerified from '@/domains/order/OrderStatusVerified';
import ToastNotificationStatus from '@/types/ToastNotificationStatus';
import { PopupProps } from '@/types/PopupPayload';

interface Props extends PopupProps {
  orderStatus: OrderStatus;
  orderStatusPayload: OrderStatusCanceled | OrderStatusVerified | OrderStatusParked;
}

@Component({
  props: {
    orderStatus: Object,
    orderStatusPayload: Object,
    color: String,
  },
})
class OrderStatusPopup extends TsxComponent<Props> {
  @Action('closeActivePopup')
  private closeActivePopup!: () => void;
  @Action('cancelOrder')
  private cancelOrder!: (payload: PushSinglePayload<OrderStatusCanceled>) => Promise<any>;
  @Action('verifyOrder')
  private verifyOrder!: (payload: PushSinglePayload<Domain>) => Promise<any>;
  @Action('parkOrder')
  private parkOrder!: (payload: PushSinglePayload<OrderStatusParked>) => Promise<any>;
  @Action('displayToastNotification')
  private displayToastNotification!: (options: {}) => Promise<any>;

  private closeOrderPopup(): void {
    this.closeActivePopup();
    this.$router.push({ name: 'order-list' });
  }

  private async handleConfirm(): Promise<void> {
    const { $fetch } = this;
    const { orderStatus, orderStatusPayload } = this.$props;

    // close order status popup
    await this.closeActivePopup();

    let request;
    if (orderStatus.equals(OrderStatus.CANCELED)) {
      request = this.cancelOrder({
        fetch: $fetch,
        domain: orderStatusPayload as OrderStatusCanceled,
      });
    } else if (OrderStatus.VERIFIED.equals(orderStatus)) {
      request = this.verifyOrder({
        fetch: $fetch,
        domain: orderStatusPayload as OrderStatusVerified,
      });
    } else if (OrderStatus.PARKED.equals(orderStatus)) {
      request = this.parkOrder({
        fetch: $fetch,
        domain: orderStatusPayload as OrderStatusParked,
      });
    }

    try {
      request
        .then(() => {
          this.displayToastNotification({
            status: ToastNotificationStatus.SUCCESS,
            message: `Auftrag erfolgreich ${orderStatus.actionDescriptionPastTense}.`,
          });
        })
        .catch((error) => {
          this.displayToastNotification({
            status: ToastNotificationStatus.ERROR,
            message: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
          });
          console.error(error);
        })
        .finally(() => {
          this.closeOrderPopup();
        });
    } catch (e) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('Accepting and declining orders is not possible in your environment');
      }
    }
  }

  private render() {
    const { orderStatus, color } = this.$props;

    let title: string;
    if (OrderStatus.CANCELED.equals(orderStatus)) {
      title = 'Ablehnung bestätigen';
    } else if (OrderStatus.VERIFIED.equals(orderStatus)) {
      title = 'Freigabe bestätigen';
    } else if (OrderStatus.PARKED.equals(orderStatus)) {
      title = 'Wiedervorlage bestätigen';
    }

    return (
      <ConfirmPopup
        title={title}
        buttonText="OK"
        color={color}
        description={`Möchten Sie den ausgewählten Auftrag wirklich ${orderStatus.actionDescription}?`}
        icon={orderStatus.icon}
        onConfirm={this.handleConfirm}
        onClose={this.closeActivePopup}
      />
    );
  }
}

export default OrderStatusPopup;
