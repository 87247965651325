import { ActionTree } from 'vuex';
import { UiState } from '../state';
import { RootState } from '@/store';
import displayPopup from './displayPopup';
import closeActivePopup from './closeActivePopup';
import addToastNotification from './addToastNotification';
import closeToastNotification from './closeToastNotification';
import displayToastNotification from './displayToastNotification';

const actions: ActionTree<UiState, RootState> = {
  displayPopup,
  closeActivePopup,
  addToastNotification,
  closeToastNotification,
  displayToastNotification,
};

export default actions;
