/**
 * convert this:
 * {
 *  foo: {
 *    subFoo: [
 *      'fooValue0',
 *      '',
 *      2,
 *    ],
 *  },
 *  bar: 'barValue'
 * }
 *
 * to this:
 * {
 *  ['foo[subFoo][0]']: 'fooValue0',
 *  ['foo[subFoo][1]']: 2,
 *  ['bar']: 'barValue',
 * }
 */

// tslint:disable-next-line: ban-types
const flattenProps = (object: Object, keyBase: string[] = []): {[key: string]: any} => {
  if (!(object instanceof Object)) {
    return {};
  }

  const data: { [key: string]: any } = {};
  Object.keys(object).forEach((key) => {
    let prop = object[key];
    const newKeyBase = keyBase.concat(key);
    if (
      typeof prop === 'string' ||
      typeof prop === 'number' ||
      typeof prop === 'boolean' ||
      prop instanceof Blob
    ) {
      data[generateKey(newKeyBase)] = prop;
    } else if (
      prop instanceof Object ||
      prop instanceof Array
    ) {
      if (prop instanceof Array) {
        // remove empty array elements
        prop = prop.filter((subProp) => {
          return (
            subProp !== undefined &&
            subProp !== null &&
            subProp !== ''
          );
        });
      }
      const subProps = flattenProps(prop, newKeyBase);
      Object.keys(subProps).forEach((subKey) => {
        const subProp = subProps[subKey];
        data[subKey] = subProp;
      });
    }
  });

  return data;
};

const generateKey = (keys: string[]): string => {
  let ret: string = '';
  keys.forEach((key, index) => {
    const showBrackets = index !== 0;
    ret = `${ret}${showBrackets ? `[` : ''}${key}${showBrackets ? `]` : ''}`;
  });

  return ret;
};

export default flattenProps;
