const getNestedProp = (object: {}, path: string, delimiter: string = '.'): any => {
  const splitPath = path.split(delimiter);

  if (splitPath.length === 0) {
    return null;
  } else if (splitPath.length === 1) {
    return object[splitPath[0]];
  } else {
    const currentPath = splitPath.shift();

    if (!object[currentPath]) {
      return null;
    }

    return getNestedProp(object[currentPath], splitPath.join(delimiter));
  }
};

export default getNestedProp;
