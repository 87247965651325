import createFakeResponse from '../../../test-helper/createFakeResponse';

export const loginBadCredentials = createFakeResponse(
  {
    code: 401,
    message: 'Bad credentials',
  },
  401,
);

export const loginGoodResponseData = {
  mandant: {
    id: '034869b8-0675-4a7f-b868-48507f674179',
    name: 'S-KON eKontor 24',
    shopHost: 'test.skon.de',
    modules: {
      MODULE_ADDITIONAL_CHARGE: true,
    },
  },
  /* tslint:disable-next-line:max-line-length */
  refresh_token: 'c151a74a6c1a6e4182312eac5632492698fa6f9953116d0bc3e288e2f8ccc07a7ded2402a542f3e7ca30b5ca507b964bb5267a3d521e6e228655e6ec536285b7',
  roles: ['ROLE_SKON_ADMIN'],
  rolesToAssign: {
    mandant: ['ROLE_MANDANT_CAMPAIGN_READ', 'ROLE_MANDANT_CAMPAIGN_WRITE', 'ROLE_MANDANT_ORDER_READ'],
    skon: ['ROLE_SKON_ADMIN', 'ROLE_SKON_PRODUCT_MANAGER', 'ROLE_SKON_AFTER_SALES'],
  },
  notifiableRoles: [
    'ROLE_SKON_AFTER_SALES', 'ROLE_MANDANT_ORDER_READ',
  ],
  /* tslint:disable-next-line:max-line-length */
  token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1NzU5NzE1ODIsImV4cCI6MTU3NTk3ODc4Miwicm9sZXMiOlsiUk9MRV9TS09OX0FETUlOIl0sInVzZXJuYW1lIjoiTHVjYVJlaW5pZ2VyK1NBQGVpbnJvdC5jb20ifQ.nl6jRYylZ36BGGXrjreOuY7Rui_JVhdyGkTQMqMSdk--xLzeVeuOhIKaI9K637AZTZBLvgnHe_iNAn92kFt_aCF7t-cm1yoxgDSbIqGEkiSN8McPcJyt5QP5MYtN22yXr2MTTdNHxO7u1NPYaoEFGWtWtFClGUEoDusXCuy8zZ5HscFpfWyFquZRJxNtDeqW7dOMOVCtNdPhmpzoSXO2nz5aaovHN61wZtWu_s6s4vAJ0jgTiTXj9Hl-GCkM5C1aQNUJnq9kdGRKuwYPQhJJbVbNOv1BGoLS9qa8C83UdK_J5ECZUXSN-go5HOkx_r-SDAzrsii3TwHp0pO11n7onvl6CwajP9BzhbCPPU0haczE96-I56FBEfhZfORSvBFcAUP92ijOujAp-97qPK6qSJO4ndnlpCVNvFU-Z0XOSd90rgKhgj-7JI3Gj93SIjCEdUB6BMhGQ4ZXM1NgwdZu2G01lbVgNABiXx_OhUx7cI-uIvFYrTt8Nxd_Y0SBuWpSVhcHnxn3Sr44iUTxmbdWfVTR_mwaWl51_pE5DYJVh0k1WnzYChBEOFKRRBFcDvNgo4oJMbxN10PjPcd3tJlT4py94oVDjJyJQqOQ7rNoTG29jBXOZusARCl94IdyagjH3NBH2cSksbP1v2RLNeilNDcxmJ66XtBZc_XqEwPw1Hc',
  logout_timestamp: '2021-08-14T12:50:10+00:00',
};
export const loginGoodCredentials = createFakeResponse(loginGoodResponseData);
