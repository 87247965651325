import { Module } from 'vuex';
import { RootState } from '@/store';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';
import state, { ArticleState } from './state';

const articleModule: Module<ArticleState, RootState> = {
  state,
  getters,
  mutations,
  actions,
};
export default articleModule;
