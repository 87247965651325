import fetchList, { FetchListAction, FetchListPayload, FetchMandantSpecificListPayload } from '@/utils/fetchList';
import MinimalUser from '@/domains/mandant/MinimalUser';

const fetchUserList: FetchListAction<MinimalUser> = async (
  { commit, dispatch, rootGetters },
  fetchUserListPayload: FetchMandantSpecificListPayload,
) => {
  const {
    fetch,
    page,
    limit,
    filters,
    sorts,
  } = fetchUserListPayload;
  const fetchListPayload: FetchListPayload = {
    fetch,
    page,
    limit,
    filters,
    sorts,
  };
  let url;
  if (rootGetters.isMandant) {
    url = `/my/user`;
  } else {
    const mandantId = rootGetters.getSimulatedMandantId || fetchUserListPayload.mandantId;
    url = `/mandant/${mandantId}/user`;
  }

  return await fetchList({ commit, dispatch }, fetchListPayload, {
      url,
      domainParser: MinimalUser.create,
      listMutation: 'setMinimalUsers',
      listInfoMutation: 'setUserListInfo',
    });
};

export default fetchUserList;
