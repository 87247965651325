import pushSingle, { PushSingleAction, PushSinglePayload } from '@/utils/pushSingle';
import Manufacturer from '@/domains/manufacturer/Manufacturer';

const editManufacturer: PushSingleAction<Manufacturer> = async (
  { commit, dispatch },
  payload: PushSinglePayload<Manufacturer>,
) => {
  const { fetch, domain } = payload;
  const { id } = domain;

  const pushPayload = { fetch, domain };

  return await pushSingle({ commit, dispatch }, 'PUT', pushPayload, {
    url: `/manufacturer/${id}`,
    mutation: 'addManufacturers',
  });
};

export default editManufacturer;
