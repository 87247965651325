import ListResponse from '@/types/ListResponse';
import { ListInfo } from '@/types/ListInfo';

const getListInfoFromListResponse = (
  response: ListResponse,
): ListInfo => {
  return ListInfo.create({
    page: response.page,
    pages: response.pages,
    limit: response.limit,
    total: response.total,
  });
};

export default getListInfoFromListResponse;
