import { GetterTree } from 'vuex';
import { ManufacturerState } from './state';
import NormalizedData from '@/types/NormalizedData';
import Manufacturer from '@/domains/manufacturer/Manufacturer';
import { ListInfo } from '@/types/ListInfo';


type ManufacturerGetter = (id: string) => Manufacturer;

export const getManufacturer = (state: ManufacturerState): ManufacturerGetter => (id: string): Manufacturer => {
  return state.manufacturers[id];
};

export const getManufacturers = (state: ManufacturerState): NormalizedData<Manufacturer> => {
  return state.manufacturers;
};

export const getManufacturerListInfo = (state: ManufacturerState): ListInfo => {
  return state.listInfo;
};

const getters: GetterTree<ManufacturerState, any> = {
  getManufacturer,
  getManufacturers,
  getManufacturerListInfo,
};

export default getters;
